import { CalendarType } from "./types";
import {
  formatDay as defaultFormatDay,
  formatLongDate as defaultFormatLongDate,
} from "./helpers/dateFormatters";
import Tile from "./Tile";
import { getDayEnd, getDayStart, isWeekend } from "./helpers/dates";
import styles from "../Calendar.module.scss";
import { useCalendarContext } from "./hooks/useCalendarContext";

type DayProps = {
  /**
   * Type of calendar that should be used. Can be `'gregory`, `'hebrew'`, `'islamic'`, `'iso8601'`. Setting to `"gregory"` or `"hebrew"` will change the first day of the week to Sunday. Setting to `"islamic"` will change the first day of the week to Saturday. Setting to `"islamic"` or `"hebrew"` will make weekends appear on Friday to Saturday.
   *
   * @example 'iso8601'
   */
  calendarType: CalendarType | undefined;
  classes?: string[];
  currentMonthIndex: number;
  /**
   * Function called to override default formatting of day tile labels. Can be used to use your own formatting function.
   *
   * @example (locale, date) => formatDate(date, 'd')
   */
  formatDay?: typeof defaultFormatDay;
  /**
   * Function called to override default formatting of day tile `abbr` labels. Can be used to use your own formatting function.
   *
   * @example (locale, date) => formatDate(date, 'dd MMM YYYY')
   */
  formatLongDate?: typeof defaultFormatLongDate;
  disableGestureSelection?: boolean;
} & Omit<
  React.ComponentProps<typeof Tile>,
  "children" | "formatAbbr" | "maxDateTransform" | "minDateTransform" | "view"
>;

export default function Day({
  calendarType,
  classes = [],
  currentMonthIndex,
  formatDay = defaultFormatDay,
  formatLongDate = defaultFormatLongDate,
  ...otherProps
}: DayProps): React.ReactElement {
  const { date, locale } = otherProps;

  const { selectedDatesMap, setSelectedDatesMap } = useCalendarContext();

  const isNeighbouringMonthDay = date.getMonth() !== currentMonthIndex;

  const dateString = date.toDateString();

  const classesProps: string[] = [];

  if (classes) {
    classesProps.push(...classes.filter(Boolean));
  }

  if (isWeekend(date, calendarType)) {
    classesProps.push(styles.weekend);
  }

  if (isNeighbouringMonthDay) {
    classesProps.push(styles["neighboring-month"]);
  }
  if (selectedDatesMap.has(date.toDateString())) {
    classesProps.push(styles.selected);
  }

  return (
    <Tile
      {...otherProps}
      classes={classesProps}
      formatAbbr={formatLongDate}
      maxDateTransform={getDayEnd}
      minDateTransform={getDayStart}
      view="month"
      onClick={() => {
        if (isNeighbouringMonthDay) {
          return;
        }
        const newSelectedDatesMap = new Map(selectedDatesMap);
        if (selectedDatesMap.has(dateString)) {
          newSelectedDatesMap.delete(dateString);
        } else {
          newSelectedDatesMap.set(dateString, [
            {
              start: "00:00",
              end: "24:00",
            },
          ]);
        }
        setSelectedDatesMap(newSelectedDatesMap);
      }}
    >
      {formatDay(locale, date)}
    </Tile>
  );
}
