import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  Input,
  Menu,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  SelectChangeEvent,
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import styled from "styled-components";
import { Form, useParams } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import { DatePicker } from "@mui/x-date-pickers";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { formatIsoToCustomDateStringWithEEEE } from "../utils/utils";
import { AvailableDate, HealthieAppointmentType, HealthieProvider } from "../types";
import { PrimaryButton, SecondaryButton } from "../subcomponents/CustomButton";
import { HelpTooltip } from "../subcomponents/HelpTooltip";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { theme } from "../providers/themeProvider";
import CircleIcon from "@mui/icons-material/Circle";
import Healthie from "../assets/HealthieWhiteLogo.png";
import CloseIcon from "@mui/icons-material/Close";
import { Colors } from "../Colors";
import CheckIcon from "@mui/icons-material/Check";
import { debounce } from "lodash";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import CustomDatePicker from "../components/date-picker/DatePicker";
import PhoneNumberField from "../subcomponents/PhoneNumberField";
import CelebrationIcon from "@mui/icons-material/Celebration";

export const GiantCheckIcon = styled(CheckIcon)`
  font-size: 100px !important;
  color: #53c43b;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  transition: transform 0.3s;
  border-radius: 20px;
  padding: 5px;

  &:hover {
    transform: scale(1.1);
  }
`;

export const GiantCelebrationIcon = styled(CelebrationIcon)`
  font-size: 100px !important;
  color: #ffd700;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  transition: transform 0.3s;
  border-radius: 20px;
  padding: 5px;

  &:hover {
    transform: scale(1.1);
  }
`;

const ModalBox = styled(Box)`
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // width: 100%;
  // margin: 10% auto;
  // background-color: #fff;
  // padding: 2%;
  // border-radius: 15px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  // @media (max-width: 768px) {
  //   width: 70%;
  //   padding: 5%;
  // }
`;

const ModalScrollBox = styled(Box)`
  // display: flex;
  // flex-direction: column;
  // width: 30%;
  // margin: 10% auto;
  // background-color: #fff;
  // padding: 2%;
  // border-radius: 15px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  // max-height: 70%;
  // overflow-y: auto;

  // @media (max-width: 768px) {
  //   width: 70%;
  //   padding: 5%;
  // }
`;

//const timezones = moment.tz.names();
const timezones = ["America/New_York", "America/Chicago", "America/Denver", "America/Los_Angeles"];

const timezoneOptions = timezones.map((tz) => {
  const dt = DateTime.now().setZone(tz);
  const offsetString = dt.toFormat("ZZ");
  const text = `${tz} (UTC${offsetString})`;

  return { value: tz, label: text, offset: dt.offset };
});

timezoneOptions.sort((a, b) => a.offset - b.offset);

const SelfAddForm: React.FC = () => {
  const [availableDates, setAvailableDates] = useState<AvailableDate[]>([]);
  const [selectedProviders, setSelectedProviders] = React.useState<string[]>([]);
  const [selectedAppointmentTypes, setSelectedAppointmentTypes] = React.useState<string[]>([]);
  const [selectedContactTypes, setSelectedContactTypes] = React.useState<string[]>([]);
  const [textEnabled, setTextEnabled] = React.useState<boolean>(false);
  const [textConsentChecked, setTextConsentChecked] = React.useState<boolean>(false);
  const [callEnabled, setCallEnabled] = React.useState<boolean>(false);
  const [callConsentChecked, setCallConsentChecked] = React.useState<boolean>(false);
  const [textAndCallConsentChecked, setTextAndCallConsentChecked] = useState<boolean>(false);
  const { orgId } = useParams<{ orgId: string }>();
  const [providers, setProviders] = useState<HealthieProvider[]>([]);
  const [appointments, setAppointments] = useState<HealthieAppointmentType[]>([]);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("+1");
  const [dob, setDob] = useState<DateTime>(DateTime.now());
  const [firstDate, setFirstDate] = useState<DateTime | null>(null);
  const [calendarOpen, setCalendarOpen] = useState(false);
  const [value, setValue] = useState<[DateTime | null, DateTime | null]>([null, null]);
  const [addedPatient, setAddedPatient] = useState<boolean>(false);
  const [uploadingPatient, setUploadingPatient] = useState<boolean>(false);
  const [callConsentMessage, setCallConsentMessage] = useState<string>("");
  const [textConsentMessage, setTextConsentMessage] = useState<string>("");
  const [textAndCallConsentMessage, setTextAndCallConsentMessage] = useState<string>("");
  const [fetchingInfo, setFetchingInfo] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [orgLogoUrl, setOrgLogoUrl] = useState<string>("");
  const [penciledLogoUrl, setPenciledLogoUrl] = useState<string>("");

  const handleContactModeChange = (mode: "text" | "call" | "both") => {
    setContactMode(mode);

    if (mode === "call") {
      setCallEnabled(true);
      setTextEnabled(false);
    } else if (mode === "text") {
      setCallEnabled(false);
      setTextEnabled(true);
    } else if (mode === "both") {
      setCallEnabled(true);
      setTextEnabled(true);
    }
  };

  useEffect(() => {
    if (!phoneNumber) {
      setPhoneNumber("+1");
    }
  }, [phoneNumber]);

  useEffect(() => {
    const fetchOrgInfo = async () => {
      try {
        setFetchingInfo(true);
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/public/healthie/self-add-patient/${orgId}`);

        if (response.status !== 200) {
          console.error("Error fetching organization info");
          return;
        }

        setProviders(response.data.providers);
        setAppointments(response.data.appointments);
        setCallConsentMessage(response.data.callConsentMessage);
        setTextConsentMessage(response.data.textConsentMessage);
        setTextAndCallConsentMessage(response.data.textAndCallConsentMessage);
        setOrgLogoUrl(response.data.organizationLogoUrl);
        setPenciledLogoUrl(response.data.penciledLogoUrl);
      } catch (error) {
        console.error("Error fetching organization info:", error);
        setErrorMsg("There was a network error. Please try again later.");
      } finally {
        setFetchingInfo(false);
      }
    };

    fetchOrgInfo();
  }, [orgId]);

  const contactTypes = ["In Person", "Secure Videochat", "Phone Call"];

  const [contactMode, setContactMode] = React.useState<"text" | "call" | "both" | "">("");

  const handleDobChange = (newValue: DateTime | null) => {
    if (newValue) {
      setDob(newValue);
    }
  };

  const createPatient = async () => {
    try {
      setUploadingPatient(true);
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/public/healthie/self-add-patient/${orgId}`, {
        patientFirstName: firstName,
        patientLastName: lastName,
        patientPhoneNumber: phoneNumber,
        availableDates: availableDates,
        dob: dob.startOf("day").setZone("UTC")?.toISO()!,
        callEnabled: callEnabled,
        textEnabled: textEnabled,
        selectedProviders: selectedProviders,
        selectedAppointmentTypes: selectedAppointmentTypes,
        selectedContactTypes: selectedContactTypes,
        textConsentChecked: textConsentChecked,
        callConsentChecked: callConsentChecked,
        textAndCallConsentChecked: textAndCallConsentChecked,
      });

      if (response.status !== 200) {
        console.error("Error creating patient");
        return;
      }

      setFirstName("");
      setLastName("");
      setDob(DateTime.now());
      setAvailableDates([]);
      setAddedPatient(true);
    } catch (error) {
      if ((error as any).response && (error as any).response.status === 400) {
        const errorMessage = (error as any).response.data; // Capture the error message returned from the server
        setErrorMsg(errorMessage || "Some fields are missing, or something went wrong. Please try again later.");
      }
      console.error("Error creating patient:", error);
    } finally {
      setUploadingPatient(false);
    }
  };

  const handleRemoveDate = (index: number) => {
    setAvailableDates((prevDates) => prevDates.filter((_, idx) => idx !== index));
  };

  const providerOptions = providers.map((provider: HealthieProvider) => ({
    value: provider.id,
    label: provider.firstName + " " + provider.lastName,
  }));

  const appointmentOptions = appointments.map((appointment: HealthieAppointmentType) => ({
    value: appointment.id,
    label: appointment.name,
  }));

  const contactTypeOptions = contactTypes.map((contactType) => ({
    value: contactType,
    label: contactType,
  }));

  const handleSelectAllProviders = () => {
    setSelectedProviders(providers.map((provider: HealthieProvider) => provider.id));
  };

  const handleDeselectAllProviders = () => {
    setSelectedProviders([]);
  };

  const handleSelectAllAppointmentTypes = () => {
    setSelectedAppointmentTypes(appointments.map((appointment) => appointment.id));
  };

  const handleDeselectAllAppointmentTypes = () => {
    setSelectedAppointmentTypes([]);
  };

  const handleSelectAllContactTypes = () => {
    setSelectedContactTypes(contactTypes);
  };

  const handleDeselectAllContactTypes = () => {
    setSelectedContactTypes([]);
  };

  const debouncedOnChange = (func: (value: string) => void, delay: number = 500) => {
    return debounce((value: string) => {
      func(value);
    }, delay);
  };

  const debouncedSetFirstName = debouncedOnChange(setFirstName);
  const debouncedSetLastName = debouncedOnChange(setLastName);
  const debouncedSetPhoneNumber = debouncedOnChange(setPhoneNumber);

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#0D0F23",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center", // Change to center the content vertically
        overflowY: "auto", // Keep the outer box scrollable
      }}
    >
      <Box
        sx={{
          overflowY: "auto",
          padding: "20px",
          backgroundColor: "#1A1E36",
          borderRadius: "30px",
          width: "100%",
          maxWidth: "800px",
          marginBottom: "20px", // This can be adjusted or removed based on the design needs
        }}
      >
        {addedPatient && (
          <>
            <Box
              sx={{
                backgroundColor: "#1C1F3E",
                padding: "40px 20px",
                borderRadius: "10px",
                border: "1px solid rgba(255, 255, 255, 0.2)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                maxHeight: "60vh",
                textAlign: "center",
                overflowX: "hidden",
                boxSizing: "border-box",
              }}
            >
              {fetchingInfo && <CircularProgress color="primary" />}
              {!fetchingInfo && (
                <img
                  src={orgLogoUrl}
                  alt="Organization Logo"
                  style={{
                    maxWidth: "100px",
                    height: "auto",
                    marginBottom: "20px",
                  }}
                />
              )}
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "1.5rem",
                  color: "#FFFFFF",
                  marginBottom: "20px",
                }}
              >
                You've been added to the waitlist!
              </Typography>
            </Box>
            {/* Powered by Logo */}
            <Box display="flex" justifyContent="right" alignItems="center" marginTop="10px">
              <Box display="flex" alignItems="center">
                <a href="https://penciled.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                  <Typography variant="caption" color={Colors.info} marginRight="5px">
                    Powered by Penciled
                  </Typography>
                </a>
              </Box>
            </Box>
          </>
        )}

        {!addedPatient && (
          <>
            <Box>
              {/* SVG Image */}
              {fetchingInfo && <CircularProgress color="primary" />}
              {orgLogoUrl && (
                <Box display="flex" justifyContent="left" marginBottom="10px">
                  <img src={orgLogoUrl} alt="Organization Logo" style={{ maxHeight: "80px", maxWidth: "80%" }} />
                </Box>
              )}

              {/* Waitlist Text */}
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} marginTop={"10px"}>
                <Typography margin={"10px 0"} fontWeight={"bold"} fontSize={"1.2rem"}>
                  Add Yourself to the Waitlist
                </Typography>
              </Box>
            </Box>

            <Typography variant="body1" sx={{ marginTop: "10px" }}>
              Patient
            </Typography>

            <Box
              display="flex"
              justifyContent="space-between"
              flexWrap="nowrap"
              gap="4px" // Reduced gap between First Name and Last Name
              marginTop="8px"
              height={105}
            >
              <TextField
                onChange={(e) => debouncedSetFirstName(e.target.value)}
                variant="outlined"
                placeholder="First Name"
                sx={{
                  height: "50px !important",
                  flexBasis: "calc(50% - 2px)", // Adjusted for reduced gap
                  borderRadius: "10px",
                  background: "rgba(255, 255, 255, 0.04)",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    height: "100%", // Make the height match the date picker
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px 12px", // Adjust padding to match the date picker
                    fontSize: "0.9rem",
                    color: "#FFFFFF",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#FFF",
                  },
                }}
              />
              <TextField
                onChange={(e) => debouncedSetLastName(e.target.value)}
                variant="outlined"
                placeholder="Last Name"
                sx={{
                  height: "50px !important",
                  flexBasis: "calc(50% - 2px)", // Adjusted for reduced gap
                  borderRadius: "10px",
                  background: "rgba(255, 255, 255, 0.04)",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                    height: "100%", // Make the height match the date picker
                  },
                  "& .MuiInputBase-input": {
                    padding: "8px 12px", // Adjust padding to match the date picker
                    fontSize: "0.9rem",
                    color: "#FFFFFF",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#FFF",
                  },
                }}
              />
            </Box>
            <PhoneNumberField
              phoneNumber={phoneNumber}
              setPhoneNumber={debouncedSetPhoneNumber}
              label={"Phone Number"}
              sx={{
                marginTop: "-30px",
                flexBasis: "100%",
                width: "60%",
                borderRadius: "10px",
                background: "rgba(255, 255, 255, 0.04)",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  "& fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.2)",
                  },
                  "&:hover fieldset": {
                    borderColor: Colors.primary,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: Colors.primary,
                  },
                },
                "& .MuiInputBase-input": {
                  padding: "8px 12px",
                  fontSize: "0.9rem",
                  color: "#FFFFFF",
                  height: "auto",
                  lineHeight: "50px",
                },
                "& .MuiSvgIcon-root": {
                  color: "#FFF",
                },
              }}
            />
            <DatePicker
              value={dob}
              onChange={handleDobChange}
              label={"Date of Birth"}
              sx={{
                marginTop: "20px",
                flexBasis: "100%",
                width: "50%",
                height: "50px",
                borderRadius: "8px",
                background: Colors.textfield,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  padding: "0 12px",
                  height: "34px",
                  boxSizing: "border-box",
                  "& fieldset": {
                    borderColor: "rgba(255, 255, 255, 0.2)",
                  },
                  "&:hover fieldset": {
                    borderColor: Colors.primary,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: Colors.primary,
                  },
                },
                "& .MuiInputBase-root": {
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                },
                "& .MuiInputBase-input": {
                  padding: "8px 12px",
                  color: "#FFFFFF",
                  display: "flex",
                  alignItems: "center",
                  boxSizing: "border-box",
                },
                "& .MuiInputLabel-root": {
                  color: Colors.info,
                  top: "-6px",
                },
                "& .MuiSvgIcon-root": {
                  color: Colors.info,
                  marginRight: "8px",
                },
              }}
            />

            <Typography variant="body1" mt={2} mb={1}>
              AI agent mode of contact
            </Typography>

            <FormControl variant="outlined" fullWidth>
              <Box display="flex" gap="5px">
                <Button
                  onClick={() => handleContactModeChange("call")}
                  variant="outlined"
                  sx={{
                    borderRadius: "10px",
                    textTransform: "none",
                    borderColor: contactMode === "call" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
                    backgroundColor: "transparent",
                    color: contactMode === "call" ? "#fff" : "#9FA1A6",
                  }}
                >
                  Call only
                </Button>

                <Button
                  onClick={() => handleContactModeChange("text")}
                  variant="outlined"
                  sx={{
                    borderRadius: "10px",
                    textTransform: "none",
                    borderColor: contactMode === "text" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
                    backgroundColor: "transparent",
                    color: contactMode === "text" ? "#fff" : "#9FA1A6",
                  }}
                >
                  Text only
                </Button>

                <Button
                  onClick={() => handleContactModeChange("both")}
                  variant="outlined"
                  sx={{
                    borderRadius: "10px",
                    textTransform: "none",
                    borderColor: contactMode === "both" ? Colors.primary : "rgba(255, 255, 255, 0.2)",
                    backgroundColor: "transparent",
                    color: contactMode === "both" ? "#fff" : "#9FA1A6",
                  }}
                >
                  Both
                </Button>
              </Box>
            </FormControl>

            <Typography variant="body1" fontWeight={"bold"} mt={2}>
              Waitlist Parameters{" "}
              <HelpTooltip title={"The patient will automatically be put on waitlists for appointments fitting these criteria."} />
            </Typography>

            <FormControl fullWidth>
              <Box display="flex" alignItems="center" gap="5px">
                <Typography variant="body1">Providers</Typography>
                {selectedProviders.length === providerOptions.length ? (
                  <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllProviders}>
                    Deselect All
                  </Button>
                ) : (
                  <Button sx={{ textTransform: "none" }} onClick={handleSelectAllProviders}>
                    Select All
                  </Button>
                )}
              </Box>
              <Select
                multiple
                displayEmpty
                value={selectedProviders}
                onChange={(e) => setSelectedProviders(e.target.value as string[])}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <Typography sx={{ color: "#9FA1A6" }}>Select options</Typography>;
                  }
                  return (selected as string[]).map((value) => providerOptions.find((option) => option.value === value)?.label).join(", ");
                }}
                sx={{
                  borderRadius: "10px",
                  background: "rgba(255, 255, 255, 0.04)",
                  marginTop: "5px",
                  "& .MuiInputBase-input": {
                    padding: "10px",
                    fontSize: "0.9rem",
                    color: "#FFFFFF",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#FFF",
                  },
                }}
              >
                {providerOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={selectedProviders.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <Box display="flex" alignItems="center" gap="5px" mt={2}>
                <Typography variant="body1">Appointment Types</Typography>
                {selectedAppointmentTypes.length === appointmentOptions.length ? (
                  <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllAppointmentTypes}>
                    Deselect All
                  </Button>
                ) : (
                  <Button sx={{ textTransform: "none" }} onClick={handleSelectAllAppointmentTypes}>
                    Select All
                  </Button>
                )}
              </Box>
              <Select
                multiple
                displayEmpty
                value={selectedAppointmentTypes}
                onChange={(e) => setSelectedAppointmentTypes(e.target.value as string[])}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <Typography sx={{ color: "#9FA1A6" }}>Select options</Typography>;
                  }
                  return (selected as string[]).map((value) => appointmentOptions.find((option) => option.value === value)?.label).join(", ");
                }}
                sx={{
                  borderRadius: "10px",
                  background: "rgba(255, 255, 255, 0.04)",
                  marginTop: "5px",
                  "& .MuiInputBase-input": {
                    padding: "10px",
                    fontSize: "0.9rem",
                    color: "#FFFFFF",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#FFF",
                  },
                }}
              >
                {appointmentOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={selectedAppointmentTypes.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <Box display="flex" alignItems="center" gap="5px" mt={2}>
                <Typography variant="body1">Contact Types</Typography>
                {selectedContactTypes.length === contactTypeOptions.length ? (
                  <Button sx={{ textTransform: "none" }} onClick={handleDeselectAllContactTypes}>
                    Deselect All
                  </Button>
                ) : (
                  <Button sx={{ textTransform: "none" }} onClick={handleSelectAllContactTypes}>
                    Select All
                  </Button>
                )}
              </Box>
              <Select
                multiple
                displayEmpty
                value={selectedContactTypes}
                onChange={(e) => setSelectedContactTypes(e.target.value as string[])}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <Typography sx={{ color: "#9FA1A6" }}>Select options</Typography>;
                  }
                  return (selected as string[]).map((value) => contactTypeOptions.find((option) => option.value === value)?.label).join(", ");
                }}
                sx={{
                  borderRadius: "10px",
                  background: "rgba(255, 255, 255, 0.04)",
                  marginTop: "5px",
                  "& .MuiInputBase-input": {
                    padding: "10px",
                    fontSize: "0.9rem",
                    color: "#FFFFFF",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "#FFF",
                  },
                }}
              >
                {contactTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={selectedContactTypes.indexOf(option.value) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography variant="body1" mt={2} mb={1}>
              Available Days
            </Typography>

            <CustomDatePicker dates={availableDates} onDatesChange={setAvailableDates} placeholder="Select dates" />

            {availableDates.length > 0 && (
              <List
                sx={{
                  width: "100%",
                  bgcolor: "#1A1E36",
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  marginTop: "10px",
                  padding: "10px",
                  borderRadius: "10px",
                  maxHeight: "200px",
                  overflowY: "auto",
                }}
              >
                {availableDates.map((date, index) => (
                  <ListItem
                    key={index}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" style={{ color: "#9FA1A6" }} onClick={() => handleRemoveDate(index)}>
                        <CloseIcon />
                      </IconButton>
                    }
                    sx={{
                      background: "#23283E",
                      color: "#FFF",
                      paddingTop: "2px",
                      paddingBottom: "2px",
                      width: "auto",
                      borderRadius: "10px",
                      fontSize: "0.75rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <ListItemText
                      primary={typeof date === "string" ? formatIsoToCustomDateStringWithEEEE(date) : formatIsoToCustomDateStringWithEEEE(date.start)}
                      sx={{
                        fontSize: "0.75rem",
                        lineHeight: "1.2",
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            )}

            {fetchingInfo && <LoadingWithMessage message={"Loading, please wait..."} size={20} />}
            {(callEnabled || textEnabled) && (
              <Box
                sx={{
                  marginTop: "20px",
                  padding: "20px",
                  backgroundColor: "#1A1E36",
                  borderRadius: "10px",
                  border: "1px solid rgba(255, 255, 255, 0.2)",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                {contactMode === "call" && callEnabled && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#B0B0B0",
                          "&.Mui-checked": {
                            color: "#FFFFFF",
                          },
                        }}
                      />
                    }
                    onChange={(e: any) => setCallConsentChecked(e.target.checked)}
                    label={callConsentMessage}
                    sx={{
                      color: Colors.info,
                      fontSize: "0.6rem !important",
                    }}
                  />
                )}

                {contactMode === "text" && textEnabled && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#B0B0B0",
                          "&.Mui-checked": {
                            color: "#FFFFFF",
                          },
                        }}
                      />
                    }
                    onChange={(e: any) => setTextConsentChecked(e.target.checked)}
                    label={textConsentMessage}
                    sx={{
                      color: Colors.info,
                      fontSize: "0.6rem !important",
                    }}
                  />
                )}

                {contactMode === "both" && textAndCallConsentMessage && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#B0B0B0",
                          "&.Mui-checked": {
                            color: "#FFFFFF",
                          },
                        }}
                      />
                    }
                    onChange={(e: any) => setTextAndCallConsentChecked(e.target.checked)}
                    label={textAndCallConsentMessage}
                    sx={{
                      color: Colors.info,
                      fontSize: "0.6rem !important",
                    }}
                  />
                )}
              </Box>
            )}

            {errorMsg && (
              <Typography variant="body1" color="error" mt={2}>
                {errorMsg}
              </Typography>
            )}

            <PrimaryButton
              disabled={
                !firstName ||
                !lastName ||
                !phoneNumber ||
                availableDates.length === 0 ||
                (contactMode === "call" && !callConsentChecked) ||
                (contactMode === "text" && !textConsentChecked) ||
                (contactMode === "both" && !textAndCallConsentChecked)
              }
              onClick={createPatient}
              sx={{
                marginTop: "20px !important",
                paddingTop: "15px !important", // Increase padding for better vertical space
                padding: "15px", // Increase padding for better vertical space
                width: "100%", // Make the button take the full width of the parent
                "&:disabled": {
                  backgroundColor: "#31364B",
                  color: "#9FA1A6",
                },
              }}
            >
              {uploadingPatient ? <CircularProgress size={24} color="inherit" /> : "Join Waitlist"}
            </PrimaryButton>

            {/* Powered by Logo */}
            <Box display="flex" justifyContent="right" alignItems="center" marginTop="10px">
              <Box display="flex" alignItems="center">
                <a href="https://penciled.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }}>
                  <Typography variant="caption" color={Colors.info} marginRight="5px">
                    Powered by Penciled
                  </Typography>
                </a>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SelfAddForm;
