import React from "react";
import { useNavigate } from "react-router-dom";
import LoginHeader from "../subcomponents/LoginHeader";
import WalkthroughPage from "../generalPages/WalkthroughPage";
import { ChoiceCardType, PageType, CardTypes, AgentType } from "../types";
import PhoneIcon from "@mui/icons-material/Phone";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { RootState } from "../store";
import { useSelector } from "react-redux";

const cards: ChoiceCardType[] = [
  {
    title: "Scheduling",
    description: "Contacts a patient to schedule a time for their appointment.",
    icon: <PhoneIcon />,
    nextRoute: "/walkthrough/communication",
    type: CardTypes.agentType,
    typeValue: AgentType.scheduling,
  },
  {
    title: "Waitlist",
    description: "Contacts a list of patients to fill an open appointment slot.",
    icon: <PhoneIcon />,
    nextRoute: "/walkthrough/communication",
    type: CardTypes.agentType,
    typeValue: AgentType.waitlist,
  },
  {
    title: "Other",
    description: "Whether it’s a small internal app or a new for millions of customers, our design and development teams.",
    icon: <TextsmsIcon />,
    nextRoute: "/walkthrough/",
    type: CardTypes.agentType,
    typeValue: AgentType.other,
  },
];

const AgentTypePage: React.FC = () => {
  const navigate = useNavigate();
  const walkthroughUser = useSelector((state: RootState) => state.auth.authUserModel);

  const handleNext = (nextRoutes: string[]) => {
    nextRoutes.forEach((route) => {
      navigate(route);
    });
  };

  if (!walkthroughUser) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {walkthroughUser && (
        <>
          <LoginHeader />
          <WalkthroughPage
            walkthroughProgress={["AGENT TYPE"]}
            title="Which type of agent would you like to build?"
            selectionType={PageType.single}
            cards={cards}
            onNext={handleNext}
            progress=""
          />
          {/* <Footer /> */}
        </>
      )}
    </>
  );
};

export default AgentTypePage;
