import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { sessionExpired } from "./SessionSlice";
import { WaitlistSlot, WaitlistSlotState } from "../types";

export interface WaitlistSlotsSlice {
  waitlistSlots: { [waitlistSlotId: string]: WaitlistSlot };
  waitlistSlotsLoading: { [waitlistSlotId: string]: boolean };
}

const initialState: WaitlistSlotsSlice = {
  waitlistSlots: {},
  waitlistSlotsLoading: {},
};

export const fetchWaitlistSlot = createAsyncThunk<{ waitlistSlot: WaitlistSlot }, { token: string; waitlistSlotId: string }, { rejectValue: Error }>(
  "waitlist/fetchWaitlistSlot",
  async ({ token, waitlistSlotId }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/waitlist/slots/${waitlistSlotId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data) {
        return { waitlistSlot: response.data as WaitlistSlot };
      }
      throw new Error("Invalid response data");
    } catch (error: any) {
      if (error.response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(new Error("Failed to fetch waitlist slot."));
    }
  }
);

const waitlistSlotsSlice = createSlice({
  name: "waitlistSlots",
  initialState,
  reducers: {
    setWaitlistSlot: (state, action: PayloadAction<{ waitlistSlotId: string; waitlistSlot: WaitlistSlot | undefined }>) => {
      if (action.payload.waitlistSlot) {
        state.waitlistSlots[action.payload.waitlistSlotId] = action.payload.waitlistSlot;
      } else {
        delete state.waitlistSlots[action.payload.waitlistSlotId];
      }
    },
    updateWaitlistSlot: (state, action: PayloadAction<{ waitlistSlotId: string; state: WaitlistSlotState; stateJustification: string; }>) => {
      const { waitlistSlotId, state: newState, stateJustification } = action.payload;
      if (state.waitlistSlots[waitlistSlotId]) {
        state.waitlistSlots[waitlistSlotId] = {
          ...state.waitlistSlots[waitlistSlotId],
          state: newState,
          stateJustification,
        };
      }
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchWaitlistSlot.pending, (state, action) => {
        state.waitlistSlotsLoading[action.meta.arg.waitlistSlotId] = true;
      })
      .addCase(fetchWaitlistSlot.fulfilled, (state, action) => {
        state.waitlistSlots[action.payload.waitlistSlot.waitlistSlotId] = action.payload.waitlistSlot;
        state.waitlistSlotsLoading[action.meta.arg.waitlistSlotId] = false;
      })
      .addCase(fetchWaitlistSlot.rejected, (state, action) => {
        state.waitlistSlotsLoading[action.meta.arg.waitlistSlotId] = false;
      });
  },
});

export const { setWaitlistSlot, updateWaitlistSlot } = waitlistSlotsSlice.actions;

export default waitlistSlotsSlice.reducer;
