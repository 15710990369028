import * as React from "react";
import {
  Box,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HistoryIcon from "@mui/icons-material/History";
import PeopleIcon from "@mui/icons-material/People";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import HelpIcon from "@mui/icons-material/Help";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeveloperModeIcon from "@mui/icons-material/DeveloperMode";
import Typography from "@mui/material/Typography";
import { WhitePenciledLogo } from "../styles/HeaderStyles";
import { Outlet, useNavigate } from "react-router-dom";
import { Colors } from "../Colors";
import { useSelector } from "react-redux";
import { Agent, AgentType, EHR, FrontendUser, OnboardingStep, Organization } from "../types";
import { RootState, useAppDispatch } from "../store";
import { clearAgents, fetchAgents } from "../slices/AgentSlice";
import { useSnackbar } from "../providers/SnackbarProvider";
import { useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { fetchAppointments, fetchHealthiePatients, fetchProviders } from "../slices/HealthieIntegrationSlice";
import { signOut } from "../slices/AuthSlice";
import { usePostHog } from "posthog-js/react";
import { AxiosError } from "axios";
import { useState } from "react";
import { getWaitlistPatients } from "../slices/PatientSlice";
import { dropdownStyles, menuPropsStyles } from "../styles/GeneralStyles";
import { sessionExpired } from "../slices/SessionSlice";
import { fetchWebptPatients, fetchWebptProviders } from "../slices/WebptIntegrationSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { fetchPteverywhereAppointments, fetchPteverywhereProviders } from "../slices/PteverywhereIntegrationSlice";
import { fetchRaintreeAppointments, fetchRaintreePatients, fetchRaintreeProviders } from "../slices/RaintreeIntegrationSlice";

const drawerWidth = 300;

const DashboardSidebar: React.FC = () => {
  const [activeItem, setActiveItem] = React.useState<string | null>(null);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);

  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);

  const agents: Agent[] = useSelector((state: RootState) => state.agents.agents);

  const firstWaitlistAgent = agents.find((agent: Agent) => agent.agentType === AgentType.waitlist);
  const [dashboardPath, setDashboardPath] = useState<string>(`/dashboard/agents/${firstWaitlistAgent?.agentId}`);

  const onboardingStep: OnboardingStep = useSelector((state: RootState) => state.auth.onboardingStep);

  useEffect(() => {
    if (onboardingStep === OnboardingStep.end) {
      setDashboardPath(`/dashboard/agents/${firstWaitlistAgent?.agentId}`);
    } else {
      setDashboardPath(`/dashboard`);
    }
  }, [firstWaitlistAgent, onboardingStep]);

  const posthog = usePostHog();

  const userId = user?.userId;
  const token = user?.token;

  const fetchGlobalAgents = async () => {
    if (userId && token) {
      try {
        await dispatch(fetchAgents({ userId, token }));
      } catch (error) {
        if ((error as AxiosError).response?.status === 401) {
          showMessage("Session has expired", "error");
        }
        console.error("Failed to fetch calls:", (error as Error).message);
      }
    } else {
      dispatch(clearAgents());
    }
  };

  useEffect(() => {
    fetchGlobalAgents();
  }, [user?.token]);

  useEffect(() => {
    if (!user?.token) {
      return;
    }
    if (organization?.integrations.includes(EHR.healthie)) {
      dispatch(fetchProviders({ token: user.token }));
      dispatch(fetchAppointments({ token: user.token }));
      dispatch(getWaitlistPatients({ token: user.token, quietly: false }));
    } else if (organization?.integrations.includes(EHR.webpt)) {
      dispatch(fetchWebptPatients({ token: user.token, organizationId: user.organizationId }));
      dispatch(fetchWebptProviders({ token: user.token, organizationId: user.organizationId }));
    } else if (organization?.integrations.includes(EHR.pteverywhere)) {
      dispatch(fetchPteverywhereProviders({ token: user.token }));
      dispatch(fetchPteverywhereAppointments({ token: user.token }));
      dispatch(getWaitlistPatients({ token: user.token, quietly: false }));
    } else if (organization?.integrations.includes(EHR.raintree)) {
      dispatch(fetchRaintreeProviders({ token: user.token }));
      dispatch(fetchRaintreeAppointments({ token: user.token }));
    }
  }, [organization?.integrations]);

  const handleListItemClick = (item: string, path: string) => {
    if (item === "Advanced") {
      setShowAdvancedOptions((prev) => !prev);
    } else {
      posthog?.capture("[PENCILED] dashboard_sidebar_item_clicked", { item });
      setActiveItem(item);

      if (path.startsWith("https://")) {
        window.open(path, "_blank");
      } else {
        navigate(path);
      }

      if (isMobile) {
        setMobileOpen(false);
      }
    }
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    sessionStorage.removeItem("sessionStartTime");
    dispatch(signOut());
    navigate("/login");
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action?: string) => {
    setAnchorEl(null);
    if (action === "account") {
      navigate("/dashboard/account");
    } else if (action === "logout") {
      handleLogout();
    }
  };

  const menuItems = [
    { text: "Dashboard", path: dashboardPath, icon: <DashboardIcon /> },
    { text: "Agents", path: "/dashboard/agents", icon: <PeopleIcon /> },
    { text: "Patients", path: "/dashboard/patients", icon: <PeopleIcon /> },
    { text: "Logs", path: "/dashboard/logs", icon: <HistoryIcon /> },
  ];

  const otherItems = [{ text: "Help", path: "/dashboard/help", icon: <HelpIcon /> }];

  const advancedMenuItems = [
    {
      text: "Integrations",
      path: "/dashboard/integrations",
      icon: <IntegrationInstructionsIcon />,
      status: organization?.integrations?.includes(EHR.healthie) ? null : "Setup required",
    },
    { text: "Developers", path: "/dashboard/developers", icon: <DeveloperModeIcon /> },
    { text: "Docs", path: "https://docs.penciled.com", icon: <LibraryBooksIcon /> },
  ];

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const drawer = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", background: "#181C2F" }}>
      <Box>
        <WhitePenciledLogo style={{ width: "150px", margin: "20px 15px" }} />
        <List>
          <>
            {menuItems.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton
                  onClick={() => {
                    posthog?.capture(`[PENCILED] Sidebar item clicked: ${item.text}`);
                    handleListItemClick(item.text, item.path);
                  }}
                  sx={{
                    backgroundColor: activeItem === item.text ? "#2D3042" : "inherit",
                    "&:hover": {
                      backgroundColor: activeItem === item.text ? Colors.primary : "rgba(0, 0, 255, 0.1)",
                    },
                    borderRadius: "10px",
                    margin: "5px 15px",
                  }}
                >
                  <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={<Typography sx={{ fontSize: "0.95rem", color: "#FFF" }}>{item.text}</Typography>} />
                </ListItemButton>
              </ListItem>
            ))}

            <Divider sx={{ backgroundColor: "rgba(255, 255, 255, 0.3)", margin: "10px 15px" }} />

            {otherItems.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton
                  onClick={() => handleListItemClick(item.text, item.path)}
                  sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 255, 0.1)" }, borderRadius: "10px", margin: "5px 15px" }}
                >
                  <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={<Typography sx={{ fontSize: "0.95rem", color: "#FFF" }}>{item.text}</Typography>} />
                </ListItemButton>
              </ListItem>
            ))}

            <ListItem>
              <ListItemButton onClick={() => setShowAdvancedOptions((prev) => !prev)}>
                <ListItemIcon sx={{ color: "white" }}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary={<Typography sx={{ fontSize: "0.95rem", color: "#FFF" }}>Advanced</Typography>} />
                <ListItemIcon>{showAdvancedOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}</ListItemIcon>
              </ListItemButton>
            </ListItem>

            {showAdvancedOptions && (
              <Box sx={{ pl: 4 }}>
                {advancedMenuItems.map((subItem) => (
                  <ListItem key={subItem.text} disablePadding>
                    <ListItemButton
                      onClick={() => handleListItemClick(subItem.text, subItem.path)}
                      sx={{ "&:hover": { backgroundColor: "rgba(0, 0, 255, 0.1)" }, borderRadius: "10px", margin: "5px 15px" }}
                    >
                      <ListItemIcon sx={{ color: "white" }}>{subItem.icon}</ListItemIcon>
                      <ListItemText primary={<Typography sx={{ fontSize: "0.85rem", color: "#FFF" }}>{subItem.text}</Typography>} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            )}
          </>
        </List>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex", background: "#151A32", minHeight: "100vh" }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ display: { lg: "none" }, background: Colors.bg, height: "70px" }}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between", height: "70px" }}>
          <IconButton color="primary" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2 }}>
            <MenuIcon sx={{ fontSize: "30px" }} />
          </IconButton>
          <WhitePenciledLogo style={{ width: "125px" }} />
          <Box onClick={handleClick}>
            <AccountCircleIcon style={{ fontSize: "2.1rem", cursor: "pointer", color: Colors.primary }} />
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={() => handleClose()}
            PaperProps={{
              style: menuPropsStyles.PaperProps.sx,
            }}
            sx={dropdownStyles}
          >
            <MenuItem onClick={() => handleClose("account")}>My account</MenuItem>
            <MenuItem onClick={() => handleClose("logout")}>Logout</MenuItem>
          </Menu>
          {/* <SignoutButton
            onClick={() => {
              posthog?.capture("Logout");
              posthog?.reset();
              handleLogout();
            }}
            sx={{ fontSize: "1rem", border: "none", padding: 0 }}
          >
            Sign out
          </SignoutButton> */}
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { md: 0 } }}>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              border: "none",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              border: "none",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1, p: { xs: 3, md: 5 }, background: Colors.bg }}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default DashboardSidebar;
