import React from 'react';
import { FormControl, Typography, Select, MenuItem, SxProps } from '@mui/material';
import { dropdownStyles, menuPropsStyles } from '../styles/GeneralStyles';

interface SelectOption {
  value: string | number;
  label: string;
}

interface SelectComponentProps {
  label: string;
  value: string | number;
  onChange: (value: string | number) => void;
  options: SelectOption[];
  placeholder?: string;
  required?: boolean;
  fullWidth?: boolean;
  sx?: SxProps;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
  label,
  value,
  onChange,
  options,
  placeholder = 'Select an option',
  required = false,
  fullWidth = true,
  sx = {},
}) => {
  return (
    <FormControl fullWidth={fullWidth} sx={{ margin: '10px 0', ...sx }}>
      <Typography variant="body2" sx={{ marginBottom: '5px', color: '#E3E5EA' }}>
        {label}
      </Typography>
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        displayEmpty
        required={required}
        sx={dropdownStyles}
        MenuProps={menuPropsStyles}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectComponent;
