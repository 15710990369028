import styled from 'styled-components';
import { CircularProgress, Box } from '@mui/material';

export const StyledCircularProgress = styled(CircularProgress)`
  width: 24px !important;  // Override any external size styling
  height: 24px !important; // Ensure the spinner is exactly 24x24 pixels
`;


export const StyledDisconnectButton = styled.button`
  background-color: #d32f2f; // Use a red color for the button
  /* padding-bottom: 20px; */
  /* margin-top: 30px;
  padding-top: 30px; */
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; // Margin to separate the button from the logo
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #b71c1c; // Darker shade on hover
  }

  &:active {
    background-color: #7f0000; // Even darker on active
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5); // Adding a focus outline for accessibility
  }
`;


export const StyledIntegrateButton = styled.button`
  background-color: #006BFF; // Use a red color for the button
  /* padding-bottom: 20px; */
  /* margin-top: 30px;
  padding-top: 30px; */
  color: white;
  font-size: 1rem; // Adjust the font size as needed
  padding: 15px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px; // Margin to separate the button from the logo
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.2s ease-in-out;

  /* &:hover {
    background-color: #0CE8F0 // Darker shade on hover
  }

  &:active {
    background-color: #0CE8F0; // Even darker on active
  } */

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0,123,255,0.5); // Adding a focus outline for accessibility
  }
`;


export const IntegrationCard = styled (Box)`
  position: relative;
  padding: 25px,
  backgroundColor: Colors.bg2,
  height: 100%,
  width: { xs: 100%, md: 45%, lg: 32% },
  borderRadius: 20px,
  marginRight: 20px,
  boxShadow: 0 4px 8px rgba(0, 0, 0, 0.3),
  color: white,

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
  }
`;


export const StyledFormBox = styled(Box)`
  margin-top: 10px;
  padding-top: 10px;
  min-width: 120px; // Apply the minimum width here as well
  margin-bottom: 3px;
  padding-bottom: 3px;
`;

export const StyledProgressContainer = styled.div`
  position: relative;
  width: 100%;  // Full width of the container to ensure centering
  height: 50px; // Fixed height for vertical space
  display: flex;
  justify-content: center; // Horizontally center the spinner
  align-items: center; // Vertically center the spinner
`;
