import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import { StyledPenciledTextLogo, WhitePenciledLogo } from '../styles/HeaderStyles';

interface LoadingWithMessageProps {
  message: string;
  size: number;
  customStyles?: React.CSSProperties;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
`;

const StyledCaption = styled(Typography)`
  font-family: Konnect-Regular;
  padding-top: 15px;
  font-size: 3rem;
  color: #FFF; 
  margin-top: 8px;
`;

const Loader: React.FC<LoadingWithMessageProps> = ({ message, size, customStyles }) => {
  return (
    <Container style={{ ...customStyles }}>
      <CircularProgress size={size} />
      <WhitePenciledLogo style={{marginTop: '25px', width: "300px"}}/>
      <StyledCaption variant="h5" >
        {message}
      </StyledCaption>
    </Container>
  );
};

export default Loader;
