// slices/scheduledCallsSlice.tsx

import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import axios, { AxiosError } from "axios";
import { Agent, FrontendScheduledCall, FrontendUser } from "../types";
import { sessionExpired } from "./SessionSlice";
import { DateTime } from "luxon";
import { PostHog } from "posthog-js";

export interface ScheduledCallsSlice {
  scheduledCalls: FrontendScheduledCall[];
  agents: Agent[];
  getCallsLoading: boolean;
  createScheduledCallLoading: boolean;
  lastEvaluatedKey: unknown;
  finalPage: boolean;
  callsDeleted: { [key: string]: boolean };
  callsRunning: { [key: string]: boolean };
  schedulingError?: string;
}

const initialState: ScheduledCallsSlice = {
  scheduledCalls: [],
  agents: [],
  getCallsLoading: false,
  createScheduledCallLoading: false,
  lastEvaluatedKey: undefined,
  finalPage: false,
  callsDeleted: {},
  callsRunning: {},
};

export const refreshScheduledCallsPaginated = createAsyncThunk<
  { scheduledCalls: FrontendScheduledCall[]; lastEvaluatedKey: unknown }, // Expected return type
  { token: string; agentIds?: string[]; dateRange?: [DateTime | null, DateTime | null] }, // Arguments passed to the thunk
  { rejectValue: Error }
>("calls/fetchScheduledCalls/paginated/refresh", async ({ token, agentIds, dateRange }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/calls/paginatedFiltered/scheduled`,
      { agentIds, startTime: dateRange?.[0] || undefined, endTime: dateRange?.[1] || undefined },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // console.log("refreshScheduledCallsPaginated response: ", response.data);

    if (response.data) {
      const scheduledCalls: FrontendScheduledCall[] = response.data.scheduledCalls;
      return {
        scheduledCalls: scheduledCalls,
        lastEvaluatedKey: response.data.lastEvaluatedKey,
      };
    }
    throw new Error("Invalid response data");
  } catch (error) {
    console.log("scheduled calls error: ", error);
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch calls"));
  }
});

export const nextScheduledCallsPaginated = createAsyncThunk<
  { scheduledCalls: FrontendScheduledCall[]; lastEvaluatedKey: unknown }, // Expected return type
  { token: string; lastEvaluatedKey: unknown; agentIds?: string[]; dateRange?: [DateTime | null, DateTime | null] }, // Arguments passed to the thunk
  { rejectValue: Error }
>("calls/fetchScheduledCalls/paginated/next", async ({ token, lastEvaluatedKey, agentIds, dateRange }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/calls/paginatedFiltered/scheduled`,
      { lastEvaluatedKey: lastEvaluatedKey, agentIds, startTime: dateRange?.[0] || undefined, endTime: dateRange?.[1] || undefined },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const scheduledCalls: FrontendScheduledCall[] = response.data.scheduledCalls;
      return {
        scheduledCalls: scheduledCalls,
        lastEvaluatedKey: response.data.lastEvaluatedKey,
      };
    }
    throw new Error("Invalid response data");
  } catch (error) {
    console.log("scheduled calls error: ", error);
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch calls"));
  }
});

interface EnqueuePatientScheduledCallArgs {
  token: string;
  organizationId: string;
  scheduledDate: DateTime; // Adjust the type based on your date library
  agentId: string;
  posthog?: PostHog; // Adjust the type if you have a specific type for posthog
  onClose: () => void;
  setSchedulingCallLoading: (loading: boolean) => void;
  setLocalErrorMsg: (msg: string) => void;
  variables: Record<string, any>;
}

export const enqueuePatientScheduledCall = createAsyncThunk<void, EnqueuePatientScheduledCallArgs, { rejectValue: string }>(
  "calls/enqueuePatientScheduledCall",
  async (
    {
      organizationId,
      scheduledDate,
      agentId,
      posthog,
      onClose,
      setSchedulingCallLoading,
      setLocalErrorMsg,
      variables,
      token,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      setSchedulingCallLoading(true);

      if (!scheduledDate.isValid || !scheduledDate.toISO()) {
        setLocalErrorMsg("Invalid scheduled date");
        return rejectWithValue("Invalid scheduled date");
      }

      const scheduledCall: Partial<FrontendScheduledCall> = {
        organizationId: organizationId,
        scheduledDate: scheduledDate.toISO()!,
        agentId: agentId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/calls/schedule`,
        { 
          scheduledCall: scheduledCall, 
          variables: variables 
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        dispatch(refreshAgentScheduledCallsPaginated({ token, agentId: agentId })); // Refresh the scheduled calls list
        posthog?.capture("[PENCILED] call_patient_via_add_new_patient_modal", { scheduledCall, message: "Patient call scheduled successfully" });
        onClose();
      } else {
        console.error("Error enqueueing patient scheduled call:", response.data);
        posthog?.capture("error", { feature: "schedule_call", error: response.data });
        return rejectWithValue("Error scheduling patient call");
      }
    } catch (error) {
      console.error("Error enqueueing patient scheduled call:", error);
      posthog?.capture("error", { feature: "schedule_call", error: error });

      console.log("(error as any).response?.data?.error as string): ", (error as any).response?.data?.error as string);
      return rejectWithValue(
        "Error scheduling call: " + ((error as any).response?.data?.error as string) || (error as Error).message || "Unknown error"
      );
    } finally {
      setSchedulingCallLoading(false);
    }
  }
);

export const refreshAgentScheduledCallsPaginated = createAsyncThunk<
  { scheduledCalls: FrontendScheduledCall[]; lastEvaluatedKey: unknown }, // Expected return type
  { token: string, agentId: string; dateRange?: [DateTime | null, DateTime | null] }, // Arguments passed to the thunk
  { rejectValue: Error }
>("calls/fetchScheduledCalls/agent/paginated/refresh", async ({ token, agentId, dateRange }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/agents/${agentId}/callsPaginatedFiltered/scheduled`,
      { startTime: dateRange?.[0] || undefined, endTime: dateRange?.[1] || undefined },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const scheduledCalls: FrontendScheduledCall[] = response.data.scheduledCalls;
      return {
        scheduledCalls: scheduledCalls,
        lastEvaluatedKey: response.data.lastEvaluatedKey,
      };
    }
    throw new Error("Invalid response data");
  } catch (error) {
    console.log("scheduled calls error: ", error);
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch calls"));
  }
});

export const nextAgentScheduledCallsPaginated = createAsyncThunk<
  { scheduledCalls: FrontendScheduledCall[]; lastEvaluatedKey: unknown }, // Expected return type
  { token: string; lastEvaluatedKey: unknown; agentId: string; dateRange?: [DateTime | null, DateTime | null] }, // Arguments passed to the thunk
  { rejectValue: Error }
>("calls/fetchScheduledCalls/agent/paginated/next", async ({ token, lastEvaluatedKey, agentId, dateRange }, { rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/agents/${agentId}/callsPaginatedFiltered/scheduled`,
      { lastEvaluatedKey: lastEvaluatedKey, startTime: dateRange?.[0] || undefined, endTime: dateRange?.[1] || undefined },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data) {
      const scheduledCalls: FrontendScheduledCall[] = response.data.scheduledCalls;
      return {
        scheduledCalls: scheduledCalls,
        lastEvaluatedKey: response.data.lastEvaluatedKey,
      };
    }
    throw new Error("Invalid response data");
  } catch (error) {
    console.log("scheduled calls error: ", error);
    if ((error as AxiosError).response?.status === 401) {
      sessionExpired(true);
    }
    return rejectWithValue(new Error("Failed to fetch calls"));
  }
});

export const runScheduledCallNow = createAsyncThunk<
  void,
  { conversation: unknown; token: string },
  { rejectValue: string }
>("calls/runScheduledCallNow", async ({ conversation, token }, { dispatch, rejectWithValue }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/calls/run-scheduled-call`,
      { scheduledCall: conversation },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to run scheduled call");
    }

    // delete the call from local state
    dispatch(deleteScheduledCall({ scheduledCallId: (conversation as { scheduledCallId: string }).scheduledCallId }));
  } catch (error) {
    console.error(error);
    return rejectWithValue("Error running scheduled call");
  }
});

export const deleteScheduledCallThunk = createAsyncThunk<void, { scheduledCallId: string; token: string }, { rejectValue: string }>(
  "calls/deleteScheduledCall",
  async ({ scheduledCallId, token }, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/calls/scheduled/${scheduledCallId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        dispatch(deleteScheduledCall({ scheduledCallId })); // remove from local state
      } else {
        throw new Error("Failed to delete scheduled call");
      }
    } catch (error) {
      console.error(error);
      return rejectWithValue("Error deleting scheduled call");
    }
  }
);

const scheduledCallsSlice = createSlice({
  name: "scheduledCalls",
  initialState,
  reducers: {
    deleteScheduledCall: (state, action: PayloadAction<{ scheduledCallId: string }>) => {
      state.scheduledCalls = state.scheduledCalls.filter(
        (scheduledCall: FrontendScheduledCall) => scheduledCall.scheduledCallId !== action.payload.scheduledCallId
      );
    },
    addScheduledCall: (state, action: PayloadAction<{ scheduledCall: FrontendScheduledCall }>) => {
      state.scheduledCalls.push(action.payload.scheduledCall);
      state.scheduledCalls.sort((a: FrontendScheduledCall, b: FrontendScheduledCall) => {
        if (!a.scheduledDate || !b.scheduledDate) {
          // If either of the scheduledDates is missing, keep the order unchanged
          return 0;
        }
        return b.scheduledDate.localeCompare(a.scheduledDate);
      });
    },
    modifyScheduledCall: (state, action: PayloadAction<{ scheduledCallId: string; changes: Partial<FrontendScheduledCall> }>) => {
      const index = state.scheduledCalls.findIndex((scheduledCall) => scheduledCall.scheduledCallId === action.payload.scheduledCallId);
      if (index !== -1) {
        state.scheduledCalls[index] = { ...state.scheduledCalls[index], ...action.payload.changes } as FrontendScheduledCall;
      }
    },
    clearScheduledCalls: (state) => {
      state.scheduledCalls = [];
      state.lastEvaluatedKey = undefined;
      state.finalPage = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(enqueuePatientScheduledCall.pending, (state) => {
        state.createScheduledCallLoading = true;
      })
      .addCase(enqueuePatientScheduledCall.fulfilled, (state) => {
        state.createScheduledCallLoading = false;
        state.schedulingError = undefined;
      })
      .addCase(enqueuePatientScheduledCall.rejected, (state, action) => {
        state.createScheduledCallLoading = false;
        state.schedulingError = action.payload || "Failed to schedule call";
      })
      .addCase(runScheduledCallNow.pending, (state, action) => {
        const scheduledCallId = (action.meta.arg.conversation as { scheduledCallId: string }).scheduledCallId;
        state.callsRunning[scheduledCallId] = true;
      })
      .addCase(runScheduledCallNow.fulfilled, (state, action) => {
        const scheduledCallId = (action.meta.arg.conversation as { scheduledCallId: string }).scheduledCallId;
        delete state.callsRunning[scheduledCallId];
      })
      .addCase(runScheduledCallNow.rejected, (state, action) => {
        const scheduledCallId = (action.meta.arg.conversation as { scheduledCallId: string }).scheduledCallId;
        delete state.callsRunning[scheduledCallId];
      })
      .addCase(deleteScheduledCallThunk.pending, (state, action) => {
        const scheduledCallId = action.meta.arg.scheduledCallId;
        state.callsDeleted[scheduledCallId] = true;
      })
      .addCase(deleteScheduledCallThunk.fulfilled, (state, action) => {
        const scheduledCallId = action.meta.arg.scheduledCallId;
        delete state.callsDeleted[scheduledCallId];
      })
      .addCase(deleteScheduledCallThunk.rejected, (state, action) => {
        const scheduledCallId = action.meta.arg.scheduledCallId;
        delete state.callsDeleted[scheduledCallId];
      })
      .addCase(refreshScheduledCallsPaginated.pending, (state) => {
        state.getCallsLoading = true;
        state.scheduledCalls = [];
        state.finalPage = false;
        state.lastEvaluatedKey = undefined;
      })
      .addCase(refreshScheduledCallsPaginated.fulfilled, (state, action) => {
        state.scheduledCalls = action.payload.scheduledCalls;
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        if (!action.payload.lastEvaluatedKey) {
          state.finalPage = true;
        } else {
          state.finalPage = false;
        }
        state.getCallsLoading = false;
      })
      .addCase(refreshScheduledCallsPaginated.rejected, (state) => {
        state.getCallsLoading = false;
        // Handle error state
      })
      .addCase(nextScheduledCallsPaginated.pending, (state) => {
        state.getCallsLoading = true;
      })
      .addCase(nextScheduledCallsPaginated.fulfilled, (state, action) => {
        state.scheduledCalls = [...state.scheduledCalls, ...action.payload.scheduledCalls];
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        if (!action.payload.lastEvaluatedKey) {
          state.finalPage = true;
        } else {
          state.finalPage = false;
        }
        state.getCallsLoading = false;
      })
      .addCase(nextScheduledCallsPaginated.rejected, (state) => {
        state.getCallsLoading = false;
        // Handle error state
      })
      .addCase(refreshAgentScheduledCallsPaginated.pending, (state) => {
        state.getCallsLoading = true;
      })
      .addCase(refreshAgentScheduledCallsPaginated.fulfilled, (state, action) => {
        state.scheduledCalls = action.payload.scheduledCalls;
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        if (!action.payload.lastEvaluatedKey) {
          state.finalPage = true;
        } else {
          state.finalPage = false;
        }
        state.getCallsLoading = false;
      })
      .addCase(refreshAgentScheduledCallsPaginated.rejected, (state) => {
        state.getCallsLoading = false;
        // Handle error state
      })
      .addCase(nextAgentScheduledCallsPaginated.pending, (state) => {
        state.getCallsLoading = true;
      })
      .addCase(nextAgentScheduledCallsPaginated.fulfilled, (state, action) => {
        state.scheduledCalls = [...state.scheduledCalls, ...action.payload.scheduledCalls];
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        if (!action.payload.lastEvaluatedKey) {
          state.finalPage = true;
        } else {
          state.finalPage = false;
        }
        state.getCallsLoading = false;
      })
      .addCase(nextAgentScheduledCallsPaginated.rejected, (state) => {
        state.getCallsLoading = false;
        // Handle error state
      });
  },
});

export const { addScheduledCall, deleteScheduledCall, modifyScheduledCall, clearScheduledCalls } = scheduledCallsSlice.actions;

export const selectCalls = (state: RootState) => state.scheduledCalls.scheduledCalls;

export default scheduledCallsSlice.reducer;
