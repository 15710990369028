import React from "react";
import { useNavigate } from "react-router-dom";
import LoginHeader from "../subcomponents/LoginHeader";
import { ChoiceCardType, PageType, CardTypes, PatientInfoRetrieval } from "../types";
import WalkthroughPage from "../generalPages/WalkthroughPage";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import LoopIcon from "@mui/icons-material/Loop";

const cards: ChoiceCardType[] = [
  {
    title: "Spreadsheet upload",
    description: "Whether it’s a small internal app or a new for millions of customers, our design and development teams.",
    icon: <LoopIcon />,
    nextRoute: "/walkthrough/build-agent",
    type: CardTypes.patientInfoRetrieval,
    typeValue: PatientInfoRetrieval.spreadsheet,
  },
  {
    title: "Other",
    description: "Whether it’s a small internal app or a new for millions of customers, our design and development teams.",
    icon: <LoopIcon />,
    nextRoute: "/walkthrough/patient-find",
    type: CardTypes.patientInfoRetrieval,
    typeValue: PatientInfoRetrieval.other,
  },
];

const SelfReferral: React.FC = () => {
  const walkthroughUser = useSelector((state: RootState) => state.auth.authUserModel);
  const navigate = useNavigate();

  const handleNext = (nextRoutes: string[]) => {
    nextRoutes.forEach((route) => {
      navigate(route);
    });
  };

  return (
    <>
      {walkthroughUser ? (
        <>
          <LoginHeader />
          <WalkthroughPage
            walkthroughProgress={[
              "Scheduling",
              walkthroughUser.schedulingType ?? "",
              walkthroughUser.ehr ?? "",
              walkthroughUser.patientFinding ?? "",
            ]}
            title="Where should the AI agent retrieve patient information from?"
            selectionType={PageType.single}
            cards={cards}
            onNext={handleNext}
            progress="1/2"
          />
          {/* <Footer /> */}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SelfReferral;
