import { Box, CircularProgress, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { sessionExpired } from "../slices/SessionSlice";
import { PrimaryButton } from "../subcomponents/CustomButton";
import styled from "styled-components";
import { RootState, useAppDispatch } from "../store";
import { signOut } from "../slices/AuthSlice";
import { useSelector } from "react-redux";

const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30%;
  margin: 10% auto;
  background-color: #fff;
  padding: 2%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 70%;
    padding: 5%;
  }
`;

const SessionExpiredModal: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isSessionExpired: boolean = useSelector((state: RootState) => state.session.sessionExpired);

  const [signingOut, setSigningOut] = useState(false);

  useEffect(() => {
    // Don't show the modal on the login page
    if (window.location.pathname.includes("/login") || window.location.pathname.includes("/walkthrough")) {
      dispatch(sessionExpired(false));
      return;
    }
  }, [dispatch]);

  const handleLogout = async () => {
    try {
      setSigningOut(true);
      sessionStorage.removeItem('sessionStartTime');
      dispatch(sessionExpired(true));
      dispatch(signOut());

      // wait 3 seconds
      await new Promise((resolve) => setTimeout(resolve, 3000));

      dispatch(sessionExpired(false));
      navigate("/login");
    } catch (error) {
      console.error("Error signing out", error);
    } finally {
      setSigningOut(false);
    }
  };

  return (
    <Modal open={isSessionExpired}>
      <ModalBox sx={{ textAlign: "center" }}>
        <Typography variant="h5" fontFamily={"Konnect-SemiBold"} mb={2}>
          Session Expired
        </Typography>
        <Typography variant="subtitle1" fontFamily={"Foundry-Buch"} mb={3}>
          Your session has expired due to inactivity. Please log in again.
        </Typography>
        <PrimaryButton onClick={handleLogout} sx={{ fontFamily: "Konnect-SemiBold", width: "50%", margin: "auto" }}>
          {signingOut && <CircularProgress size={20} color="inherit" />}
          {!signingOut && "Sign Out"}
        </PrimaryButton>
      </ModalBox>
    </Modal>
  );
};

export default SessionExpiredModal;
