import React from "react";
import { useNavigate } from "react-router-dom";
import LoginHeader from "../subcomponents/LoginHeader";
import { ChoiceCardType, PageType, CardTypes, PatientFinding } from "../types";
import WalkthroughPage from "../generalPages/WalkthroughPage";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import LoopIcon from "@mui/icons-material/Loop";

const cards: ChoiceCardType[] = [
  {
    title: "Self-referral",
    description: "Whether it’s a small internal app or a new for millions of customers, our design and development teams.",
    icon: <LoopIcon />,
    nextRoute: "/walkthrough/self-referral",
    type: CardTypes.patientFinding,
    typeValue: PatientFinding.selfReferral,
  },
  {
    title: "Referred by a provider",
    description: "Whether it’s a small internal app or a new for millions of customers, our design and development teams.",
    icon: <LoopIcon />,
    nextRoute: "/walkthrough/referral",
    type: CardTypes.patientFinding,
    typeValue: PatientFinding.referral,
  },
  {
    title: "Other",
    description: "Whether it’s a small internal app or a new for millions of customers, our design and development teams.",
    icon: <LoopIcon />,
    nextRoute: "/walkthrough/referral",
    type: CardTypes.patientFinding,
    typeValue: PatientFinding.other,
  },
];

const PatientFindingPage: React.FC = () => {
  const walkthroughUser = useSelector((state: RootState) => state.auth.authUserModel);

  const navigate = useNavigate();

  const handleNext = (nextRoutes: string[]) => {
    nextRoutes.forEach((route) => {
      navigate(route);
    });
  };

  return (
    <>
      {walkthroughUser && (
        <>
          <LoginHeader />
          <WalkthroughPage
            walkthroughProgress={["Scheduling", walkthroughUser.schedulingType ?? "", walkthroughUser.ehr ?? ""]}
            title="How do these patients find you?"
            selectionType={PageType.single}
            cards={cards}
            onNext={handleNext}
            progress="1/2"
          />
          {/* <Footer /> */}
        </>
      )}
    </>
  );
};

export default PatientFindingPage;
