import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { CSVPatient } from "../types";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { PrimaryButton, TransparentButton } from "../subcomponents/CustomButton";
import { Colors } from "../Colors";
import styled from "styled-components";
import {
  handleDateChange,
  handleOverallScheduleChange,
  handleRowToggle,
  selectUploadCSV,
  setPatients,
  setPatientPhoneNumbersToScheduledDate,
  setPatientPhoneNumbersToOverallSchedule,
} from "../slices/UploadCSVSlice";
import { FooterBox } from "../subcomponents/SidebarComponents";
import { useAppDispatch } from "../store";

interface ScheduleCSVModalProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

const ScheduleCSVModal: React.FC<ScheduleCSVModalProps> = ({ open, onClose, onSuccess }) => {
  const dispatch = useAppDispatch();
  const { patients, patientPhoneNumbersToScheduledDate, overallSchedule, patientPhoneNumbersToOverallSchedule, uploadCSVErrorMsg } =
    useSelector(selectUploadCSV);
  const columnTypes = ["Actions", "First name", "Last name", "Phone number", "Call Scheduled Date"];
  const [selectAll, setSelectAll] = useState(false);
  const [showOverallDatePicker, setShowOverallDatePicker] = useState<Record<string, boolean>>({});
  const [overallDate, setOverallDate] = useState<DateTime | null>(null);
  const overallDatePickerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      setSelectAll(false);
      setOverallDate(null);
      setShowOverallDatePicker({});
    }
  }, [open]);

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      setOverallDate(null);
      const newPatientPhoneNumbersToOverallSchedule = patients.reduce((acc, patient) => {
        acc[patient.patientPhoneNumber] = true;
        return acc;
      }, {} as Record<string, boolean>);
      const newShowOverallDatePicker = patients.reduce((acc, patient) => {
        acc[patient.patientPhoneNumber] = true;
        return acc;
      }, {} as Record<string, boolean>);
      dispatch(setPatientPhoneNumbersToOverallSchedule(newPatientPhoneNumbersToOverallSchedule));
      setShowOverallDatePicker(newShowOverallDatePicker);
    } else {
      setOverallDate(null);
      dispatch(setPatientPhoneNumbersToOverallSchedule({}));
      setShowOverallDatePicker({});
    }
  };

  const handleOverallDateChange = (newDate: DateTime | null) => {
    setOverallDate(newDate);
    if (newDate) {
      const newPatientPhoneNumbersToScheduledDate = patients.reduce((acc, patient) => {
        if (patientPhoneNumbersToOverallSchedule[patient.patientPhoneNumber]) {
          acc[patient.patientPhoneNumber] = newDate.toISO();
        }
        return acc;
      }, {} as Record<string, string | null>);
      dispatch(setPatientPhoneNumbersToScheduledDate(newPatientPhoneNumbersToScheduledDate));
    }
  };

  const handleIndividualDateChange = (patient: CSVPatient, newDate: DateTime | null) => {
    if (!newDate || !newDate.isValid || !newDate.toISO()) return;
    dispatch(handleDateChange({ patient, date: newDate.toISO()! }));
    dispatch({ type: "uploadCSV/clearError" });
  };

  const handleShowRowDatePicker = (patientPhoneNumber: string) => {
    setShowOverallDatePicker((prevState) => ({
      ...prevState,
      [patientPhoneNumber]: !prevState[patientPhoneNumber],
    }));
  };

  useEffect(() => {
    if (showOverallDatePicker && overallDatePickerRef.current) {
      overallDatePickerRef.current.click();
    }
  }, [showOverallDatePicker]);

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "auto",
        paddingBottom: "60px",
        paddingRight: "20px",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          padding: "12px 25px",
          width: "100%",
          display: "flex",
          borderBottom: "2px solid Colors.textfield",
          background: "#151A33",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Box display={"flex"} gap={"10px"}>
          <Typography variant={"body2"} fontSize={"1.1rem"} fontWeight={"bold"}>
            Upload CSV
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ color: "#FFFFFF" }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Typography variant="h5" sx={{ mb: 0, mr: "auto" }}>
        Contact patients
      </Typography>
      {patients.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            textAlign: "center",
            width: 400,
          }}
        >
          <Typography variant="body1" sx={{ mb: 2 }}>
            No patients to contact
          </Typography>
          <Button variant="contained" onClick={onClose} sx={{ mr: 2 }}>
            Close
          </Button>
        </Box>
      ) : (
        <>
          <Box sx={{ display: "flex", alignItems: "center", marginLeft: "auto", marginTop: "30px" }}>
            <Typography variant="body1" fontWeight={"bold"}>
              Select all
            </Typography>
            <Checkbox checked={selectAll} onChange={handleSelectAllChange} style={{ color: "#FFF" }} />
          </Box>

          {selectAll && (
            <DateTimePicker
              label="Pick a date & time for all calls"
              value={overallDate}
              onChange={handleOverallDateChange}
              inputRef={overallDatePickerRef}
              sx={{
                flexGrow: 1,
                width: "280px",
                marginLeft: "auto",
                background: Colors.textfield,
                "& .MuiInputBase-input": {
                  color: "#FFFFFF",
                },
                "& .MuiInputLabel-root": {
                  color: Colors.info,
                },
                "& .MuiSvgIcon-root": {
                  color: Colors.info,
                },
              }}
            />
          )}

          {/* <Table size="small" sx={{ display: xs: 'none', md: 'table' }}>
              <TableHead>
                <TableRow>
                  {columnTypes.map((type, index) => (
                    <TableCell key={index} sx={{ flexGrow: 1 }}>{type}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {patients.map((patient: CSVPatient, index: number) => (
                  <TableRow key={patient.patientPhoneNumber}>
                    <TableCell sx={{ flexGrow: 1 }}>
                      <IconButton onClick={() => dispatch(setPatients(patients.filter((filterPatient) => patient.patientPhoneNumber !== filterPatient.patientPhoneNumber)))}>
                        <CloseIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell sx={{ flexGrow: 1 }}>{patient.patientFirstName}</TableCell>
                    <TableCell sx={{ flexGrow: 1 }}>{patient.patientLastName}</TableCell>
                    <TableCell sx={{ flexGrow: 1 }}>{patient.patientPhoneNumber}</TableCell>
                    <TableCell sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: "space-between" }}>
                      <DateTimePicker
                        value={patientPhoneNumbersToScheduledDate[patient.patientPhoneNumber] ? DateTime.fromISO(patientPhoneNumbersToScheduledDate[patient.patientPhoneNumber] as string) : null}
                        onChange={(newDate: DateTime | null) => handleIndividualDateChange(patient, newDate)}
                        sx={{
                          
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              border: 'none',
                              
                            },
                            '&:hover fieldset': {
                              border: '0.5px solid grey',
                            },
                            '&.Mui-focused fieldset': {
                              border: '0.5px solid grey',
                            },
                          },
                          '& .MuiInputBase-root': {
                            '&:before, &:after': {
                              display: 'none',
                            },
                            '&.Mui-focused': {
                              boxShadow: 'none',
                            },
                          },
                          '& .MuiInputAdornment-root': {
                            marginRight: 0,
                          },
                          '& input': {
                            padding: '10px',
                          },
                        }}
                      />
                      <Checkbox
                        checked={patientPhoneNumbersToOverallSchedule[patient.patientPhoneNumber] ?? false}
                        onClick={(event: React.MouseEvent) => event.stopPropagation()}
                        onChange={() => dispatch(handleRowToggle(patient))}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
 */}

          {/* Mobile View */}
          <Box sx={{ display: { xs: "none", md: "block" }, marginTop: "25px", borderTop: "0.5px solid grey", paddingTop: 2 }}>
            {patients.map((patient: CSVPatient, index: number) => (
              <Grid container spacing={2} key={index} sx={{ mb: 2, borderTop: "0.5px solid grey", paddingBottom: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    First name
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <Typography>{patient.patientFirstName}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Last name
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <Typography>{patient.patientLastName}</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Phone number
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  <Typography>{patient.patientPhoneNumber}</Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Scheduled Date
                  </Typography>
                </Grid>
                <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                  <DateTimePicker
                    value={
                      patientPhoneNumbersToScheduledDate[patient.patientPhoneNumber]
                        ? DateTime.fromISO(patientPhoneNumbersToScheduledDate[patient.patientPhoneNumber] as string)
                        : null
                    }
                    onChange={(newDate: DateTime | null) => handleIndividualDateChange(patient, newDate)}
                    sx={{
                      background: Colors.textfield,
                      "& .MuiInputBase-input": {
                        color: "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: Colors.info,
                      },
                      "& .MuiSvgIcon-root": {
                        color: Colors.info,
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                    Actions
                  </Typography>
                </Grid>
                <Grid item xs={8} textAlign={"right"}>
                  Select
                  <Checkbox
                    checked={patientPhoneNumbersToOverallSchedule[patient.patientPhoneNumber] ?? false}
                    onClick={(event: React.MouseEvent) => event.stopPropagation()}
                    onChange={() => dispatch(handleRowToggle(patient))}
                    style={{ color: "#FFF" }}
                  />
                  <IconButton
                    sx={{ fontSize: "0.9rem", marginLeft: "5px", color: "#FFF" }}
                    onClick={() =>
                      dispatch(setPatients(patients.filter((filterPatient) => patient.patientPhoneNumber !== filterPatient.patientPhoneNumber)))
                    }
                  >
                    Remove
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Box>
        </>
      )}
      {uploadCSVErrorMsg && (
        <Typography variant="body1" sx={{ color: Colors.error, mt: 2 }}>
          {uploadCSVErrorMsg}
        </Typography>
      )}

      <FooterBox onClick={onSuccess} buttonText="Schedule Calls" />
    </Box>
  );
};

export default ScheduleCSVModal;
