import React, { useEffect, useState } from "react";
import { Box, Typography, Checkbox, FormControlLabel, useMediaQuery, IconButton } from "@mui/material";
import styled from "styled-components";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { PrimaryButton } from "../subcomponents/CustomButton";
import { RootState, useAppDispatch } from "../store";
import { Agent, Call, FrontendUser } from "../types";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from '@mui/icons-material/IosShare';
import { Parser } from "@json2csv/plainjs";
import { toSnakeCase } from "../utils/utils";
import { refreshCallsPaginated } from "../slices/CallSlice";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { useParams } from "react-router-dom";

const AgentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
  {
    label: "This Week",
    getValue: () => {
      const today = DateTime.local();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = DateTime.local();
      const prevWeek = today.minus({ weeks: 1 });
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = DateTime.local();
      return [today.minus({ days: 7 }), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = DateTime.local();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

type ExportModalProps = {
  agentsSelector: boolean;
  mainAgentsFilter: string[] | undefined;
  onClose: () => void;
};

const ExportModal: React.FC<ExportModalProps> = ({ agentsSelector, mainAgentsFilter, onClose }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const agents: Agent[] = useSelector((state: RootState) => state.agents.agents);
  const [dateRange, setDateRange] = useState<[DateTime | null, DateTime | null]>([DateTime.now().minus({ weeks: 1 }), DateTime.now()]);
  const isLargeScreen = useMediaQuery("(min-width:800px)");
  const calls: Call[] | null = useSelector((state: RootState) => state.calls.calls);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const loading: boolean = useSelector((state: RootState) => state.calls.loading);
  const dispatch = useAppDispatch();
  const [triggerExport, setTriggerExport] = useState(false);
  const { agentId } = useParams<{ agentId: string }>();
  const [agentsFilter, setAgentsFilter] = useState<string[] | undefined>(mainAgentsFilter);

  useEffect(() => {
    if (agentId && (!agentsFilter || !agentsSelector)) {
      setSelectedAgents((prev) => ({
        ...prev,
        [agentId]: true,
      }));

      setAgentsFilter([agentId]);
    }
  }, [agentsSelector, agentId, agentsFilter]);

  const [selectedAgents, setSelectedAgents] = useState<{ [key: string]: boolean }>(() => {
    const agentsObj: { [key: string]: boolean } = {};
    agents.forEach((agent: Agent) => {
      agentsObj[agent.agentId] = agentsFilter ? agentsFilter.includes(agent.agentId) : true;
    });
    return agentsObj;
  });

  const handleAgentChange = (agent: string) => {
    setSelectedAgents((prev) => ({
      ...prev,
      [agent]: !prev[agent],
    }));
  };

  const getMatchingCalls = (agents: string[], dates: [DateTime | null, DateTime | null]): Partial<Call>[] => {
    if (!calls) return [];
  
    const filteredCalls = calls.filter((call: Call) => {
      const callDate = DateTime.fromISO(call.startedAt);
  
      const isAgentMatch: boolean = agents.includes(call.agentId);
      const isDateMatch: boolean = (!dates[0] || callDate >= dates[0]) && (!dates[1] || callDate <= dates[1]);
  
      return isAgentMatch && isDateMatch;
    });
  
    // Convert each filtered call to snake_case
    return filteredCalls.map((call: Call) => {
      return toSnakeCase({
        callId: call?.callId || "",
        agentName: call?.agentName || "",
        patientFirstName: call?.patientFirstName || "",
        patientLastName: call?.patientLastName || "",
        status: call?.status || "",
        outcome: call?.outcome || "",
        appointmentDate: call?.appointmentDate || "",
        visitReason: call?.visitReason || "",
        cancellationReason: call?.cancellationReason || "",
        patientEmail: call?.patientEmail || "",
        startedAt: DateTime.fromISO(call.startedAt).setZone("local").toISO(),
        endedAt: DateTime.fromISO(call.endedAt).setZone("local").toISO(),
        direction: call?.direction || "",
        actionItem: call?.actionItem || "",
        actionItemReason: call?.actionItemReason || "",
        patientRequest: call?.patientRequest || "",
        patientRequestDetails: call?.patientRequestDetails || "",
      });
    });
  };

  const handleExport = () => {
    if (!user?.token) { return; }
    const agentIds = Object.entries(selectedAgents)
      .filter(([agentId, isSelected]) => isSelected)
      .map(([agentId]) => agentId);
  
    setTriggerExport(true);
    dispatch(refreshCallsPaginated({ token: user.token, agentIds, dateRange: dateRange, pageSize: 1000 }));
  };

  useEffect(() => {
    if (!loading && triggerExport) {
      if (!calls || calls.length === 0) {
        setErrorMsg("No calls available for export.");
        setTriggerExport(false);
        return;
      }

      const filteredCalls: Partial<any[]> = getMatchingCalls(agentsFilter || [], dateRange || [null, null]);

      if (filteredCalls.length > 0) {
        const parser = new Parser();
        const csv = parser.parse(filteredCalls);

        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', `exported_calls_${dateRange[0]?.toFormat("M_d_yy")}_to_${dateRange[1]?.toFormat("M_d_yy")}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setErrorMsg("No matching calls found for the selected criteria.");
      }

      setTriggerExport(false);
    }
  }, [loading, triggerExport, calls, agentsFilter, dateRange, getMatchingCalls]);

  const handleDateChange = (value: [DateTime | null, DateTime | null]) => {
    const [start, end] = value;
    setDateRange([start, end && end.plus({ days: 1 })]);
  };

  return (
    <Box 
      sx={{
        overflowY: 'auto',
        height: 'auto',
        paddingBottom: '60px',   
        paddingRight: '20px',  
      }}    
    >
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          padding: '12px 25px',
          width: '100%',
          display: 'flex',
          borderBottom: '2px solid Colors.textfield',
          background: '#151A33', 
          justifyContent: 'space-between',
          alignItems: 'center',
          zIndex: 1000,
        }}
      >
        <Box display={'flex'} gap={'10px'}>
          <IosShareIcon />
          <Typography variant={'body2'} fontSize={'1.1rem'} fontWeight={'bold'}>Export</Typography>
        </Box>
        <IconButton onClick={() => {
          // do a normal fetch before closing out
          if (!user?.token) { return; }
          dispatch(refreshCallsPaginated({ token: user.token, agentIds: agents.map((agent: Agent) => agent.agentId), dateRange: dateRange }));
          onClose();
        }} sx={{color: '#FFFFFF'}}>
          <CloseIcon />
        </IconButton>
      </Box>

      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <StaticDateRangePicker
          slotProps={{
            shortcuts: isLargeScreen ? { items: shortcutsItems } : undefined,
            actionBar: { actions: [] },
          }}
          calendars={1}
          onChange={handleDateChange}
          sx={{
            "@media (max-width: 768px)": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "-20px",
            },
          }}
          value={dateRange}
        />
      </LocalizationProvider>

      {agentsSelector && (
        <>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "5px" }}>
            <Typography variant="h6"> Agents </Typography>
            <Typography variant="subtitle2"> (select multiple) </Typography>
          </div>
          <AgentsContainer>
            {agents.map((agent) => (
              <FormControlLabel
                key={agent.agentId}
                control={
                  <Checkbox checked={selectedAgents[agent.agentId]} onChange={() => handleAgentChange(agent.agentId)} color="primary" />
                }
                label={agent.name}
              />
            ))}
          </AgentsContainer>
        </>
      )}

      {errorMsg && (
        <Typography variant="body2" color="error">
          {errorMsg}
        </Typography>
      )}

      {loading || triggerExport ? (
        <LoadingWithMessage message="Fetching calls and preparing CSV..." size={60} />
      ) : (
        <>
          <Typography variant="body1" sx={{ marginBottom: '20px' }}>
            Export calls matching the selected criteria.
          </Typography>

          <PrimaryButton variant="contained" onClick={handleExport}>
            Export to CSV
          </PrimaryButton>
        </>
      )}
    </Box>
  );
};

export default ExportModal;
