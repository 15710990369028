import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import SignoutHeader from "../subcomponents/SignoutHeader";
import Typography from "@mui/material/Typography";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import {
  AgentType,
  Consent,
  EHR,
  HealthieProvider,
  StreamType,
  TextThreadMessage,
  TextWebsocketEvents,
  WaitlistRun,
  WaitlistSlot,
  WaitlistSlotStateChange,
  WebsocketActions,
} from "../types";
import { Button, ListItemText } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { OvalOutlineListItem, Sidebar } from "../styles/GeneralStyles";
import { RootState, useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import { Colors } from "../Colors";
import {
  api,
  convertE164ToReadable,
  formatIsoToCustomDateStringWithEEEE,
  formatIsoToCustomDateStringWithEEEEHHMMA,
  formatIsoToCustomDateStringWithEEEEHHMMAWithoutTimezone,
  formatIsoUsingOn,
} from "../utils/utils";
import { getWaitlistPatients } from "../slices/PatientSlice";
import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import TextThreadDisplay from "../components/TextThreadDisplay";
import { PatientStatusAvatarTooltip, statusMessages } from "./WaitlistRunInfo";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { updateWaitlistSlot } from "../slices/WaitlistSlotsSlice";
import { SecondaryButton } from "../subcomponents/CustomButton";
import WarningIcon from "@mui/icons-material/Warning";
import UpdateHealthiePatientModal from "../forms/UpdatePatientModal";
import { UpdateWebptPatientModal } from "../forms/UpdatePatientModal";

const PatientInfoPage: React.FC = () => {
  const patientId = useParams<{ patientId: string }>().patientId || "";
  const user = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector((state: RootState) => state.auth.organization);
  const loadingPatients = useSelector((state: RootState) => state.patients.loadingFetchPatients);

  const patients = useSelector((state: RootState) => state.patients.patients);
  const patient = patients.find((p) => p.patientId === patientId);

  const [textThread, setTextThread] = React.useState<TextThreadMessage[]>([]);
  const [waitlistSlotStateChanges, setWaitlistSlotStateChanges] = useState<WaitlistSlotStateChange[]>([]);
  const [slots, setSlots] = useState<(WaitlistSlot & { waitlistRun: WaitlistRun })[]>([]);
  const [calls, setCalls] = useState<any[]>([]);

  const [textThreadLoading, setTextThreadLoading] = React.useState<boolean>(true);
  const [waitlistSlotStateChangesLoading, setWaitlistSlotStateChangesLoading] = useState<boolean>(false);
  const [slotsLoading, setSlotsLoading] = React.useState<boolean>(true);
  const [callsLoading, setCallsLoading] = React.useState<boolean>(true);

  const loadingProviders = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);

  const [updateOpen, setUpdateOpen] = useState<boolean>(false);

  const socketRef = useRef<WebSocket | null>(null);

  const dispatch = useAppDispatch();

  const fetchPatients = async () => {
    if (user?.token) {
      dispatch(getWaitlistPatients({ token: user.token }));
    }
  };

  const fetchTextThread = async () => {
    setTextThreadLoading(true);
    if (user?.token) {
      const response = await api.get(`/patients/${patientId}/texts`, user.token);
      setTextThread(response.data);
    }
    setTextThreadLoading(false);
  };

  const fetchWaitlistSlotStateChanges = async () => {
    setWaitlistSlotStateChangesLoading(true);
    if (user?.token) {
      const response = await api.get(`/patients/${patientId}/waitlist-slot-state-changes`, user.token);
      setWaitlistSlotStateChanges(response.data);
    }
    setWaitlistSlotStateChangesLoading(false);
  };

  useEffect(() => {
    if (!user || !user?.userId || !user?.token) {
      return;
    }

    // Check if a WebSocket connection already exists
    if (socketRef.current && (socketRef.current.readyState === WebSocket.OPEN || socketRef.current.readyState === WebSocket.CONNECTING)) {
      console.log("WebSocket connection is already open or connecting.");
      return;
    }

    const connectWebSocket = async () => {
      try {
        if (!user?.token) {
          return;
        }
        const socket = new WebSocket(
          `wss://${process.env.REACT_APP_BACKEND_URL_NO_HTTPS}/api/text-subscriptions/${user.userId}?token=${encodeURIComponent(user.token)}`
        );
        socketRef.current = socket;

        socket.onopen = async () => {
          // Add a delay to ensure the WebSocket connection is ready before subscribing
          // TODO (Hizami) - Figure out why the websocket connection is not immediately ready
          await new Promise((resolve) => setTimeout(resolve, 1000));
          console.log("Connected to WebSocket");
          socket.send(JSON.stringify({ action: WebsocketActions.subscribe, type: TextWebsocketEvents.textThreadMessages }));
          socket.send(JSON.stringify({ action: WebsocketActions.subscribe, type: TextWebsocketEvents.waitlistSlotStateChanges }));
        };

        socket.onmessage = (event: MessageEvent<string>) => {
          const message: { eventType: TextWebsocketEvents; streamType: StreamType; data: TextThreadMessage | WaitlistSlotStateChange } = JSON.parse(
            event.data
          ) as {
            eventType: TextWebsocketEvents;
            streamType: StreamType;
            data: TextThreadMessage | WaitlistSlotStateChange;
          };

          switch (message.eventType) {
            case TextWebsocketEvents.textThreadMessages:
              switch (message.streamType) {
                case StreamType.INSERT: {
                  const textThreadMessage: TextThreadMessage = message.data as TextThreadMessage;
                  if (
                    textThreadMessage.patientId === patientId &&
                    !textThread?.find((msg) => msg.textThreadMessageId === textThreadMessage.textThreadMessageId)
                  ) {
                    setTextThread((prevTextThread: TextThreadMessage[]) => [...prevTextThread, textThreadMessage]);
                  }
                  break;
                }
              }
              break;
            case TextWebsocketEvents.waitlistSlotStateChanges:
              switch (message.streamType) {
                case StreamType.INSERT: {
                  const waitlistSlotStateChange: WaitlistSlotStateChange = message.data as WaitlistSlotStateChange;
                  console.log("Received waitlist slot state change message:", waitlistSlotStateChange);
                  dispatch(
                    updateWaitlistSlot({
                      waitlistSlotId: waitlistSlotStateChange.waitlistSlotId,
                      state: waitlistSlotStateChange.state,
                      stateJustification: waitlistSlotStateChange.stateJustification,
                    })
                  );
                  break;
                }
              }
              break;
            default:
              console.log("Received unknown message:", message);
              break;
          }
        };

        socket.onclose = () => {
          console.log("Disconnected from WebSocket");
          socketRef.current = null;
        };

        socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          socket.close();
        };

        return () => {
          socket.close();
        };
      } catch (error) {
        console.error("Error connecting to WebSocket:", error);
      }
    };

    connectWebSocket();

    // Clean up the WebSocket connection when the component unmounts or user changes
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [user?.token]);

  const fetchSlots = async () => {
    setSlotsLoading(true);
    if (user?.token) {
      const response = await api.get(`/patients/${patientId}/slots`, user.token);
      setSlots(response.data);
    }
    setSlotsLoading(false);
  };

  const fetchCalls = async () => {
    setCallsLoading(true);
    if (user?.token) {
      const response = await api.get(`/patients/${patientId}/calls`, user.token);
      setCalls(response.data);
    }
    setCallsLoading(false);
  };

  useEffect(() => {
    fetchTextThread();
    fetchSlots();
    fetchCalls();
    fetchWaitlistSlotStateChanges();
  }, [user?.token, patientId]);

  useEffect(() => {
    fetchPatients();
  }, [user?.token]);

  const navigate = useNavigate();

  const agents = useSelector((state: RootState) => state.agents.agents);
  // TODO (Hizami): This is hardcoded as waitlist type since only waitlist agents use this page for now
  const agent = agents.find((agent) => agent.agentType === AgentType.waitlist);
  const agentId = agent?.agentId || "";

  const consentHistory = patient?.patient.consentHistory || [{ consent: Consent.given, message: "Consent given", date: DateTime.now().toISO() }];
  const latestConsent = consentHistory[consentHistory.length - 1];
  const isRevoked = latestConsent.consent === Consent.revoked;
  const lastConsentMessage = latestConsent.message;
  const lastConsentDate = DateTime.fromISO(latestConsent.date).toLocaleString(DateTime.DATE_MED);
  const revokedMessage = `This patient has placed themselves on the Do Not Call list via the request "${lastConsentMessage}" on ${lastConsentDate}`;

  return (
    <Box
      sx={{
        marginTop: { xs: "75px", sm: 0, color: "#FFF" },
      }}
    >
      <SignoutHeader />
      {loadingPatients && !patient && <LoadingWithMessage message="Loading patients..." size={20} customStyles={{ marginTop: "20px" }} />}
      {patient && (
        <Box>
          <SecondaryButton onClick={() => navigate(-1)} sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center", margin: "20px 0" }}>
            <ArrowBackIcon sx={{ marginRight: "10px" }} /> Back
          </SecondaryButton>
          <Typography variant="h5" sx={{ margin: { xs: "80px 0 10px 0", lg: "20px 0" }, fontSize: { xs: "1.3rem", sm: "1.8rem" }, color: "#FFF" }}>
            Patient Info
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            {isRevoked && (
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "10px 0" }}>
                <WarningIcon sx={{ color: Colors.error, marginRight: "5px" }} />
                <Typography variant="body1" sx={{ color: Colors.error, textAlign: "center" }}>
                  {revokedMessage}
                </Typography>
              </Box>
            )}
            <Typography variant="h6" sx={{ color: "#FFF" }}>
              {patient.patient.firstName} {patient.patient.lastName}
            </Typography>
            <Typography variant="body1" sx={{ color: "#FFF" }}>
              {convertE164ToReadable(patient.patient.phoneNumber)}
            </Typography>
            {patient[EHR.healthie] && (
              <Typography variant="body1" sx={{ color: "#FFF" }}>
                Healthie ID: {patient[EHR.healthie].healthiePatientId}
              </Typography>
            )}
            {patient?.[EHR.healthie] && !loadingProviders && (
              <Typography variant="body1">
                {`Provider: ${patient[EHR.healthie].providerIds
                  .map((providerId: string) => {
                    const provider: HealthieProvider | undefined = providers.find((provider) => provider.id === providerId);
                    return provider?.firstName && provider?.lastName ? `${provider.firstName} ${provider.lastName}` : providerId;
                  })
                  .join(", ")}`}
              </Typography>
            )}
            <Typography variant="body2" color={Colors.info}>
              {patient.waitlist.availableDates
                .map((date) =>
                  typeof date === "string" ? formatIsoToCustomDateStringWithEEEE(date) : formatIsoToCustomDateStringWithEEEE(date.start)
                )
                .join(", ")}
            </Typography>
            <Typography
              onClick={() => {
                setUpdateOpen(true);
              }}
              color="primary"
              fontWeight="bold"
              sx={{ fontSize: "0.8rem", marginTop: "10px", cursor: "pointer" }}
            >
              Update Patient
            </Typography>
          </Box>
          {textThreadLoading ? (
            <LoadingWithMessage message="Loading text history..." size={30} />
          ) : (
            textThread && (
              <TextThreadDisplay
                thread={textThread}
                agentId={agentId}
                patientId={patientId}
                waitlistSlotIds={slots.map((slot) => ({ waitlistSlotId: slot.waitlistSlotId, appointmentDate: slot.waitlistRun.appointmentDate }))}
                waitlistSlotStateChanges={waitlistSlotStateChanges}
              />
            )
          )}
          {slotsLoading ? (
            <LoadingWithMessage message="Loading waitlist slots..." size={30} />
          ) : (
            <Box>
              <Typography variant="h5" sx={{ margin: "20px 0", color: "#FFF" }}>
                Waitlist Slots
              </Typography>
              {slots.map((slot, index) => (
                <OvalOutlineListItem
                  key={slot.waitlistSlotId}
                  maxWidth="350px"
                  alignItems="center"
                  onClick={() => navigate(`/dashboard/agents/${agentId}/waitlist-runs/${slot.waitlistRunId}/slots/${slot?.waitlistSlotId}`)}
                  sx={{
                    cursor: "pointer",
                    "&:hover": {
                      backgroundColor: Colors.secondaryDark,
                    },
                  }}
                >
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                      <PatientStatusAvatarTooltip patientStatus={slot?.state} sx={{ mr: 2 }} />
                      <ListItemText
                        primary={`${formatIsoToCustomDateStringWithEEEEHHMMAWithoutTimezone(slot.waitlistRun.appointmentDate)}`}
                        secondary={statusMessages[slot.state as keyof typeof statusMessages]}
                        secondaryTypographyProps={{ color: Colors.info }}
                      />
                    </Box>
                    <ChevronRightIcon fontSize="large" style={{ color: Colors.info }} />
                  </>
                </OvalOutlineListItem>
              ))}
            </Box>
          )}
          {callsLoading ? (
            <LoadingWithMessage message="Loading call history..." size={30} />
          ) : (
            <Box>
              <Typography variant="h5" sx={{ margin: "20px 0", color: "#FFF" }}>
                Call History
              </Typography>
              {calls.map((call, index) => (
                <OvalOutlineListItem
                  key={index}
                  maxWidth="350px"
                  alignItems="center"
                  onClick={() => navigate(`/dashboard/agents/${agentId}/agent-conversation-history/calls/${call.callId}`)}
                  sx={{ cursor: "pointer", "&:hover": { backgroundColor: Colors.secondaryDark } }}
                >
                  <>
                    <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                      <ListItemText primary={DateTime.fromISO(call.startedAt).toLocaleString(DateTime.DATETIME_MED)} />
                    </Box>
                    <ChevronRightIcon fontSize="large" style={{ color: Colors.info }} />
                  </>
                </OvalOutlineListItem>
              ))}
            </Box>
          )}
          <Sidebar className={updateOpen ? "open" : ""}>
            {organization?.integrations.includes(EHR.healthie) ? (
              <UpdateHealthiePatientModal key={patient.patientId} open={updateOpen} onClose={() => setUpdateOpen(false)} patient={patient} />
            ) : organization?.integrations.includes(EHR.webpt) ? (
              <UpdateWebptPatientModal key={patient.patientId} open={updateOpen} onClose={() => setUpdateOpen(false)} patient={patient} />
            ) : null}
          </Sidebar>
        </Box>
      )}
      {!loadingPatients && !patient && (
        <Typography variant="h5" sx={{ color: "#FFF" }}>
          Patient not found
        </Typography>
      )}
    </Box>
  );
};

export default PatientInfoPage;
