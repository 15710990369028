import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Table, TableHead, TableRow, TableCell, FormControl, Select, MenuItem, TextField, TableBody, Grid, IconButton } from '@mui/material';
import { stringSimilarity } from 'string-similarity-js';
import styled from 'styled-components';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LoadingWithMessage from '../subcomponents/LoadingWithMessage';
import { PrimaryButton } from '../subcomponents/CustomButton';
import { isPhoneNumber } from '../utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../Colors';
import { FooterBox, HeaderBox } from '../subcomponents/SidebarComponents';

const CustomLoadingWithSubtitle = styled(LoadingWithMessage)`
  padding-top: 10px !important;
  margin-top: 10px !important;
`;

const DetectedRowsNotification = styled.div`
  color: #2FCA8B;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

interface MatchColumnsModalProps {
  open: boolean;
  onClose: () => void;
  columns: { name: string, data: string[] }[];
  numRows: number;
  fullData: any[];
  onSuccess: (fullData: any[]) => void;
}

const MatchColumnsModal: React.FC<MatchColumnsModalProps> = ({ open, onClose, columns, numRows, fullData, onSuccess }) => {
  const columnTypes = ["First name", "Last name", "Phone number"];

  const [columnMappings, setColumnMappings] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    const usedColumnTypes = new Set<string>();
    const mappings = columns.map((column: any) => {
      // Compute similarity for each column type and select the best match
      const scores = columnTypes
        .filter(type => !usedColumnTypes.has(type)) // Filter out already used types
        .map(type => ({
          type,
          score: stringSimilarity(column.name, type)
        }));
      
      // Select the type with the highest score
      const bestMatch = scores.reduce((max, item) => max.score > item.score ? max : item, { type: '', score: 0 });

      // Mark the selected type as used
      usedColumnTypes.add(bestMatch.type);

      return {
        name: column.name,
        type: bestMatch.type,
        data: column.data
      };
    });

    setColumnMappings(mappings);
  }, [columns]);

  const handleTypeChange = (index: number, type: string) => {
    const newMappings = [...columnMappings];
    newMappings[index].type = type;
    setColumnMappings(newMappings);
  };

  const validatePhoneNumbers = (data: any[]) => {
    const invalidPhoneNumbers: string[] = [];
  
    for (const row of data) {
      const phoneNumber = row.find((value: any, idx: number) => columnMappings[idx].type === 'Phone number');
      if (phoneNumber && !isPhoneNumber(phoneNumber)) {
        invalidPhoneNumbers.push(phoneNumber);
      }
    }
  
    return invalidPhoneNumbers;
  };
  
  const handleSuccess = () => {
    const invalidPhoneNumbers = validatePhoneNumbers(fullData);
    if (invalidPhoneNumbers.length > 0) {
      setErrorMessage(`Invalid phone number(s) detected: ${invalidPhoneNumbers.join(', ')}. Please correct the phone number(s) and try again.`);
      return;
    }
    onSuccess(fullData);
  };
  

  return (
    <Box 
      sx={{
        overflowY: 'auto',
        height: '100%',
        paddingBottom: '60px',   
        paddingRight: '20px',  
      }}    
    >

      <HeaderBox
        title={'Upload CSV'}
        onClose={onClose}
      />
          <Box sx={{marginBottom: '15px', marginTop: '60px'}}>
            <Typography id="match-columns-modal-title" variant="h6" component="h2" >
              Match Columns
            </Typography>
            
            <DetectedRowsNotification>
              <CheckCircleOutlineIcon />
              Detected {numRows} data rows
            </DetectedRowsNotification>
          </Box>

          <Box sx={{ overflowX: 'auto' }}>
            <Table 
              size="small" 
              sx={{ 
                display: { xs: 'none', md: 'table' }, 
                background: Colors.bg2, 
                borderRadius: "15px",
                "& .MuiTableCell-root": {
                  color: "#FFF",
                  borderColor: "transparent",
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Column</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Matched Column</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Samples</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {columnMappings.map((mapping: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell 
                      component="th" 
                      scope="row"
                      style={{color: "#FFF"}}
                    >
                      {mapping.name}
                    </TableCell>

                    <TableCell>
                      <FormControl fullWidth>
                        <Select
                          labelId={`select-label-${index}`}
                          id={`select-${index}`}
                          value={mapping.type}
                          onChange={(e) => handleTypeChange(index, e.target.value)}
                          size="small"
                          sx={{
                            borderRadius: "10px",
                            background: Colors.textfield,
                            marginTop: "5px",
                            "& .MuiInputBase-input": {
                              padding: "15px",
                              fontSize: "0.9rem",
                              color: "#FFFFFF",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#FFF",
                            },
                          }}
                        >
                          {columnTypes.map((type, idx) => (
                            <MenuItem key={idx} value={type}>
                              {type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>

                    <TableCell>
                      <TextField
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={mapping.data.join(', ')}
                        InputProps={{ readOnly: true }}
                        sx={{
                          borderRadius: "10px",
                          background: Colors.textfield,
                          marginTop: "5px",
                          "& .MuiInputBase-input": {
                            padding: "15px",
                            fontSize: "0.9rem",
                            color: "#FFFFFF",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#FFF",
                          },
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            {/* Mobile View */}
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              {columnMappings.map((mapping: any, index: any) => (
                <Grid container spacing={2} key={index} sx={{ mb: 2, borderBottom: "0.5px solid grey", paddingBottom: 2 }}>

                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Detected Columns</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{mapping.name}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Matched Column Type</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth>
                      <Select
                        labelId={`select-label-${index}`}
                        id={`select-${index}`}
                        value={mapping.type}
                        onChange={(e) => handleTypeChange(index, e.target.value)}
                        size="small"
                      >
                        {columnTypes.map((type, idx) => (
                          <MenuItem key={idx} value={type}>{type}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Samples</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      value={mapping.data.join(', ')}
                      InputProps={{ readOnly: true }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>

          {errorMessage && (
            <Typography color="error" variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
              {errorMessage}
            </Typography>
          )}

          <FooterBox
            onClick={handleSuccess}
            buttonText="Upload All"
          />
      </Box>
  );
};

export default MatchColumnsModal;
