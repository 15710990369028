import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import Toaster from "../Toaster";
import styles from "../../Calendar.module.scss";

import { Period, periodIncludesPeriod } from "../helpers/dates";
import ContextMenu, { ContextMenuConfig } from "../ContextMenu";

type SelectedDatesMap = Map<string, Period[]>;
interface DayClipboardConfig {
  day: string;
  times: Period[];
}

type WeekClipboardConfig = [Period[], Period[], Period[], Period[], Period[], Period[], Period[]] | null;

interface CalendarContextType {
  selectedDatesMap: SelectedDatesMap;
  setSelectedDatesMap: (selectedDatesMap: SelectedDatesMap) => void;
  timeClipboardConfig: Period | null;
  setTimeClipboardConfig: (timeClipboardConfig: Period) => void;
  dayClipboardConfig: DayClipboardConfig | null;
  setDayClipboardConfig: (dayClipboardConfig: DayClipboardConfig) => void;
  weekClipboardConfig: WeekClipboardConfig | null;
  setWeekClipboardConfig: (weekClipboardConfig: WeekClipboardConfig) => void;
  handlePeriodToggle: (date: Date, hourPeriod: Period) => void;
  timezone: { name: string; offset: number };
  showToast: (message: string) => void;
  contextMenuConfig: ContextMenuConfig | null;
  setContextMenuConfig: (contextMenuConfig: ContextMenuConfig) => void;
}

const CalendarContext = createContext<CalendarContextType>({
  selectedDatesMap: new Map(),
  setSelectedDatesMap: () => {},
  timeClipboardConfig: null,
  setTimeClipboardConfig: () => {},
  dayClipboardConfig: null,
  setDayClipboardConfig: () => {},
  weekClipboardConfig: null,
  setWeekClipboardConfig: () => {},
  handlePeriodToggle: () => {},
  timezone: { name: "", offset: 0 },
  showToast: () => {},
  contextMenuConfig: null,
  setContextMenuConfig: () => {},
});

export const CalendarProvider: FunctionComponent<{
  children: ReactNode;
}> = ({ children }) => {
  const [selectedDatesMap, setSelectedDatesMap] = useState<SelectedDatesMap>(new Map());
  const [timeClipboardConfig, setTimeClipboardConfig] = useState<Period | null>(null);
  const [dayClipboardConfig, setDayClipboardConfig] = useState<DayClipboardConfig | null>(null);
  const [weekClipboardConfig, setWeekClipboardConfig] = useState<WeekClipboardConfig | null>(null);
  const [toastMessage, setToastMessage] = useState("");
  const [contextMenuConfig, setContextMenuConfig] = useState<ContextMenuConfig | null>(null);

  const contextValue = useMemo(() => {
    const handlePeriodToggle = (date: Date, hourPeriod: Period) => {
      const newSelectedDatesMap = new Map(selectedDatesMap);
      let newSelectedPeriods = newSelectedDatesMap.get(date.toDateString()) || [];
      const isSelected = newSelectedPeriods?.some((period) => {
        return periodIncludesPeriod(period, hourPeriod);
      });

      // Harmonize selected periods:
      // If there was one selected period of 10:00-11:00
      // and then 10:15-10:30 is toggled, it will result in 2 selected periods:
      // 10:00-10:15 and 10:30-11:00.
      // Conversely, if 10:00-10:15 and 10:30-11:00 were selected and then 10:15-10:30 is toggled,
      // it will result in a single selected period of 10:00-11:00.
      // Basically the periods should be merged if they are consecutive and split if not.

      if (isSelected) {
        // Split the containing period if a section of it is selected.
        newSelectedPeriods = newSelectedPeriods
          .map((period) => {
            if (periodIncludesPeriod(period, hourPeriod)) {
              const startPeriod = {
                start: period.start,
                end: hourPeriod.start,
              };
              const endPeriod = {
                start: hourPeriod.end,
                end: period.end,
              };
              return [startPeriod, endPeriod].filter((period) => {
                return period.start !== period.end;
              });
            }
            return period;
          })
          .flat();
      } else {
        newSelectedPeriods.push(hourPeriod);
        newSelectedPeriods.sort((a, b) => {
          return a.start.localeCompare(b.start);
        });
        // Merge consecutive periods
        newSelectedPeriods = newSelectedPeriods.reduce((merged, current) => {
          const previous = merged[merged.length - 1];

          if (previous && previous.end === current.start) {
            // Merge consecutive periods
            merged[merged.length - 1] = {
              start: previous.start,
              end: current.end,
            };
          } else {
            // Add as new period
            merged.push(current);
          }

          return merged;
        }, [] as Period[]);
      }

      newSelectedDatesMap.set(date.toDateString(), newSelectedPeriods);
      if (!newSelectedDatesMap.get(date.toDateString())?.length) {
        newSelectedDatesMap.delete(date.toDateString());
      }
      setSelectedDatesMap(newSelectedDatesMap);
    };

    const timezone = {
      name: Intl.DateTimeFormat().resolvedOptions().timeZone,
      offset: new Date().getTimezoneOffset(),
    };

    const showToast = (message: string) => {
      setToastMessage(message);
    };

    return {
      selectedDatesMap,
      setSelectedDatesMap,
      timeClipboardConfig,
      setTimeClipboardConfig,
      dayClipboardConfig,
      setDayClipboardConfig,
      weekClipboardConfig,
      setWeekClipboardConfig,
      handlePeriodToggle,
      timezone,
      showToast,
      contextMenuConfig,
      setContextMenuConfig,
    };
  }, [
    selectedDatesMap,
    setSelectedDatesMap,
    timeClipboardConfig,
    setTimeClipboardConfig,
    dayClipboardConfig,
    setDayClipboardConfig,
    weekClipboardConfig,
    setWeekClipboardConfig,
    contextMenuConfig,
    setContextMenuConfig,
  ]);

  useEffect(() => {
    if (toastMessage) {
      setTimeout(() => {
        setToastMessage("");
      }, 2000);
    }
  }, [toastMessage]);

  return (
    <CalendarContext.Provider value={contextValue}>
      <div className={styles.provider}>
        {children}
        <Toaster message={toastMessage} visible={Boolean(toastMessage)} />
        {contextMenuConfig && (
          <ContextMenu
            options={contextMenuConfig.options}
            location={contextMenuConfig.location}
            direction={contextMenuConfig.direction}
            onClose={() => setContextMenuConfig(null)}
          />
        )}
      </div>
    </CalendarContext.Provider>
  );
};

export const useCalendarContext = () => {
  return useContext(CalendarContext);
};
