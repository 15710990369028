import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, IconButton, InputAdornment, Tooltip } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ApiKey, FrontendUser } from "../types";
import { RootState, useAppDispatch } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { PrimaryButton } from "../subcomponents/CustomButton";
import { clearApiKeys, refreshApiToken, makeApiKey, fetchApiKeys, deleteApiKey } from "../slices/KeysSlice";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import AddApiTokenModal from "../forms/AddAPITokenModal";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSnackbar } from "../providers/SnackbarProvider";

const APIKeys: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const loading: boolean = useSelector((state: RootState) => state.apiKeys.loading);
  const loadingMsg: string | undefined = useSelector((state: RootState) => state.apiKeys.loadingMsg);
  const errorMsg: string | undefined = useSelector((state: RootState) => state.apiKeys.errorMsg);
  const dispatch = useAppDispatch();
  const [newTokenName, setNewTokenName] = useState("");
  const { showMessage } = useSnackbar();

  const apiKeys: ApiKey[] | undefined = useSelector((state: RootState) => state.apiKeys.apiKeys);

  useEffect(() => {
    if (user && user.token) {
      dispatch(fetchApiKeys({ token: user.token }));
    }
  }, [dispatch, user]);

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    // when component dismounts, clear the api keys
    return () => {
      dispatch(clearApiKeys());
    };
  }, [dispatch]);

  useEffect(() => {
    if (newTokenName) {
      handleAddApiToken(newTokenName);
    }
    setNewTokenName("");
  }, [newTokenName]);

  const handleAddApiToken = (name: string) => {
    if (!user || !user.token) {
      return;
    }

    dispatch(makeApiKey({ name: name, token: user?.token as string }));
  };

  const handleRefreshApiToken = async (name: string) => {
    if (!user || !user.token) {
      return;
    }

    if (!name) {
      return;
    }

    dispatch(refreshApiToken({ name: name, token: user?.token as string }));
  };

  const handleDeleteApiToken = async (name: string) => {
    if (!user || !user.token) {
      return;
    }

    if (!name) {
      return;
    }

    dispatch(deleteApiKey({ name: name, token: user?.token as string }));
  };

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}>
        <Typography variant="h6">API Keys</Typography>
        <PrimaryButton
          onClick={() => setModalOpen(true)}
          sx={{
            marginLeft: "20px",
            padding: "5px 15px",
            fontSize: "0.9rem",
          }}
        >
          Add API Token
        </PrimaryButton>
      </Box>

      <AddApiTokenModal open={modalOpen} onClose={() => setModalOpen(false)} onSubmit={(tokenName: string) => setNewTokenName(tokenName)} />

      {loading && <LoadingWithMessage message={loadingMsg ?? "Loading..."} size={20} />}

      {apiKeys &&
      apiKeys.length > 0 &&
      apiKeys.map((key) => (
        <Box key={key.name} sx={{ margin: "20px 0" }}>
          <TextField
            fullWidth
            label={key.name}
            value={key.key ? key.key : "sk-.................................................."}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  {key.key && (
                    <Tooltip title="Copy key">
                      <IconButton
                        aria-label="copy key"
                        onClick={() => {
                          navigator.clipboard.writeText(key.key!);
                          showMessage("API key copied to clipboard");
                        }}
                      >
                        <ContentCopyIcon sx={{ color: "#FFFFFF" }} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Replace key">
                    <IconButton aria-label="replace key" onClick={() => handleRefreshApiToken(key.name)}>
                      <RefreshIcon sx={{ color: "#FFFFFF" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete key">
                    <IconButton aria-label="delete key" onClick={() => handleDeleteApiToken(key.name)}>
                      <DeleteIcon sx={{ color: "#FFFFFF" }} />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            sx={{
              '& .MuiInputBase-input': {
                color: '#FFFFFF',
              },
              '& .MuiInputLabel-root': {
                color: '#FFFFFF',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#FFFFFF',
                },
                '&:hover fieldset': {
                  borderColor: '#FFFFFF',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#FFFFFF',
                },
              },
            }}
          />
        </Box>
      ))}
      <Typography variant="caption" display="block" gutterBottom>
        Never share these publicly. Warning: Once generated, the secrets will only be viewable once and cannot be retrieved later without replacing
        it.
      </Typography>

      {errorMsg && <Typography color="error">{errorMsg}</Typography>}
    </Box>
  );
};

export default APIKeys;
