import React from "react";
import styled from "styled-components";
import { StyledPenciledTextLogo, WhitePenciledLogo } from "../styles/HeaderStyles";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, TransparentButton } from "./CustomButton";
import { Colors } from "../Colors";

// Styled container for the header to manage spacing and alignment
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 5% 0 5%;
  background-color: #0F1327;

  @media (max-width: 600px) {
    padding: 20px 20px 10px 25px;
  }
`;

const LoginHeader: React.FC = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <HeaderContainer>
      <WhitePenciledLogo style={{ width: "160px" }} />
      {/* <StyledPenciledTextLogo /> */}
      <PrimaryButton
        onClick={() => {
          handleLogin();
        }}
        sx={{ padding: { xs: "5px 15px", md: "5px 20px" }, fontSize: { xs: "0.9rem", md: "1rem" }, background: "#1B1F32", color: Colors.primary }}
      >
        Login
      </PrimaryButton>
    </HeaderContainer>
  );
};

export default LoginHeader;
