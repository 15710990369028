import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

interface LoadingWithMessageProps {
  message: string;
  size: number;
  customStyles?: React.CSSProperties;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const StyledCaption = styled(Typography)`
  fontFamily: Konnect-Regular;
  padding-top: 15px;
  font-size: 1.5rem;
  color: #fff; 
  margin-top: 8px;
`;

const LoadingWithMessage: React.FC<LoadingWithMessageProps> = ({ message, size, customStyles }) => {
  return (
    <Container style={{...customStyles, }}>
      <CircularProgress size={size} />
      <StyledCaption variant="h5" style={{...customStyles, }} >
        {message}
      </StyledCaption>
    </Container>
  );
};

export default LoadingWithMessage;
