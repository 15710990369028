import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import WalkthroughProgress from "../subcomponents/WalkthroughProgress";
import ChoiceCard from "../subcomponents/ChoiceCard";
import {
  CardTypes,
  ChoiceCardType,
  CloudFaxProvider,
  AgentType,
  PageType,
  PatientFinding,
  PatientInfoRetrieval,
  SchedulingType,
  EHR,
} from "../types";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { updateWalkthroughUser } from "../slices/AuthSlice";
import { PrimaryButton } from "../subcomponents/CustomButton";
import "../fonts/fonts.css";
import { Colors } from "../Colors";
import { usePostHog } from "posthog-js/react";

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  padding-top: 20px;
  padding-right: 100px;
  padding-left: 100px;

  @media (max-width: 1250px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 5% auto;

  @media (max-width: 1200px) {
    justify-content: center;
    width: 90%;
    margin: 5% auto;
  }
`;

const StyledCardBox = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  width: 100%;

  @media (max-width: 600px) {
    flex-direction: column;
    margin: 20px 0;
    justify-content: center;
  }
`;

const StyledTitle = styled(Typography)`
  margin-top: 20px;
  font-size: 2rem;
  width: 100%;
  text-align: left;
  font-family: "Konnect-Medium";

  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const AlignedLeftContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const StyledContainerButton = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin-top: 20px;
`;

interface WalkthroughPageProps {
  walkthroughProgress: string[];
  title: string;
  selectionType: PageType;
  cards: ChoiceCardType[];
  onNext: (nextRoutes: string[]) => void;
  progress: string;
}

const WalkthroughPage: React.FC<WalkthroughPageProps> = ({ walkthroughProgress, title, selectionType, cards, onNext, progress }) => {
  const walkthroughUser = useSelector((state: RootState) => state.auth.authUserModel);
  const [selectedCards, setSelectedCards] = useState<ChoiceCardType[]>([]);
  const dispatch = useAppDispatch();
  const posthog = usePostHog();

  const handleCardClick = (card: ChoiceCardType) => {
    posthog.capture("[PENCILED] walkthrough_card_selected", { cardType: card.type, cardValue: card.typeValue });
    if (selectionType === PageType.single) {
      setSelectedCards([card]);

      if (walkthroughUser) {
        switch (card.type as CardTypes) {
          case CardTypes.agentType:
            dispatch(updateWalkthroughUser({ agentType: card.typeValue as AgentType }));
            break;
          case CardTypes.schedulingType:
            dispatch(updateWalkthroughUser({ schedulingType: card.typeValue as SchedulingType }));
            break;
          case CardTypes.patientFinding:
            dispatch(updateWalkthroughUser({ patientFinding: card.typeValue as PatientFinding }));
            break;
          case CardTypes.patientInfoRetrieval:
            dispatch(updateWalkthroughUser({ patientInfoRetrieval: card.typeValue as PatientInfoRetrieval }));
            break;
          case CardTypes.cloudFaxProvider:
            dispatch(updateWalkthroughUser({ cloudFaxProvider: card.typeValue as CloudFaxProvider }));
            break;
          case CardTypes.ehr:
            dispatch(updateWalkthroughUser({ ehr: card.typeValue as EHR }));
            break;
          default:
            console.log(`No handling for type: ${card.type}`);
        }
      }
    } else {
      // multiple selected cards
      setSelectedCards((prev: ChoiceCardType[]) =>
        prev.includes(card) ? prev.filter((iterCard: ChoiceCardType) => card !== iterCard) : [...prev, card]
      );
    }
  };

  const handleNextClick = () => {
    posthog?.capture("[PENCILED] walkthrough_next_clicked", { selectedCards: selectedCards.map((card) => card.type) });
    if (selectionType === PageType.multiple && selectedCards.length > 1) {
      dispatch(updateWalkthroughUser({ schedulingType: "HYBRID" as SchedulingType }));

      // Dispatch "HYBRID" for other types if needed
      selectedCards.forEach((card) => {
        if (walkthroughUser) {
          switch (card.type as CardTypes) {
            case CardTypes.patientFinding:
              dispatch(updateWalkthroughUser({ patientFinding: "HYBRID" as PatientFinding }));
              break;
            case CardTypes.patientInfoRetrieval:
              dispatch(updateWalkthroughUser({ patientInfoRetrieval: "HYBRID" as PatientInfoRetrieval }));
              break;
            case CardTypes.cloudFaxProvider:
              dispatch(updateWalkthroughUser({ cloudFaxProvider: "HYBRID" as CloudFaxProvider }));
              break;
            case CardTypes.ehr:
              dispatch(updateWalkthroughUser({ ehr: "HYBRID" as EHR }));
              break;
            default:
              console.log(`No handling for type: ${card.type}`);
          }
        }
      });
    } else if (selectedCards.length === 1) {
      const firstSelectedCard = selectedCards[0];
      if (walkthroughUser) {
        switch (firstSelectedCard.type as CardTypes) {
          case CardTypes.agentType:
            dispatch(updateWalkthroughUser({ agentType: firstSelectedCard.typeValue as AgentType }));
            break;
          case CardTypes.schedulingType:
            dispatch(updateWalkthroughUser({ schedulingType: firstSelectedCard.typeValue as SchedulingType }));
            break;
          case CardTypes.patientFinding:
            dispatch(updateWalkthroughUser({ patientFinding: firstSelectedCard.typeValue as PatientFinding }));
            break;
          case CardTypes.patientInfoRetrieval:
            dispatch(updateWalkthroughUser({ patientInfoRetrieval: firstSelectedCard.typeValue as PatientInfoRetrieval }));
            break;
          case CardTypes.cloudFaxProvider:
            dispatch(updateWalkthroughUser({ cloudFaxProvider: firstSelectedCard.typeValue as CloudFaxProvider }));
            break;
          case CardTypes.ehr:
            dispatch(updateWalkthroughUser({ ehr: firstSelectedCard.typeValue as EHR }));
            break;
          default:
            console.log(`No handling for type: ${firstSelectedCard.type}`);
        }
      }
    }

    const nextRoutes = selectedCards.map((card) => card.nextRoute);
    onNext(nextRoutes);
  };

  return (
    <PageContainer>
      <StyledBox>
        <AlignedLeftContainer>
          <WalkthroughProgress walkthroughProgress={walkthroughProgress} />
          <Typography variant="body1" style={{ color: Colors.primary, fontFamily: "Konnect-Regular" }}>
            {progress}
          </Typography>
        </AlignedLeftContainer>
        <StyledTitle>{title}</StyledTitle>
        <AlignedLeftContainer>
          <Typography variant="body2" style={{ marginTop: "0px", fontFamily: "Foundry" }}>
            {`(Select ${selectionType})`}
          </Typography>
        </AlignedLeftContainer>
        <StyledCardBox>
          {cards.map((card, index) => (
            <ChoiceCard
              key={index}
              icon={card.icon}
              title={card.title}
              description={card.description}
              nextRoute={card.nextRoute}
              isSelected={selectedCards.includes(card)}
              onClick={() => handleCardClick(card)}
            />
          ))}
        </StyledCardBox>
        <StyledContainerButton>
          <PrimaryButton onClick={handleNextClick} variant="contained" disabled={selectedCards.length === 0}>
            Next
          </PrimaryButton>
        </StyledContainerButton>
      </StyledBox>
    </PageContainer>
  );
};

export default WalkthroughPage;
