import React from "react";
import { useNavigate } from "react-router-dom";
import LoginHeader from "../subcomponents/LoginHeader";
import WalkthroughPage from "../generalPages/WalkthroughPage";
import { ChoiceCardType, PageType, CardTypes, SchedulingType } from "../types";
import PhoneIcon from "@mui/icons-material/Phone";
import TextsmsIcon from "@mui/icons-material/Textsms";
import EmailIcon from "@mui/icons-material/Email";
import { RootState } from "../store";
import { useSelector } from "react-redux";

// export type AuthUserModel = {
//   agentType: AgentType;
//   schedulingType: SchedulingType;
//   schedulingSoftware: SchedulingSoftware;
//   patientFinding: PatientFinding;
//   patientInfoRetrieval: PatientInfoRetrieval;
//   agentName: string;
//   callbackNumber: string;
//   orgName: string;
//   orgAreaCode: string;
//   cloudFaxProvider?: CloudFaxProvider;
// };

const cards: ChoiceCardType[] = [
  {
    title: "AI Phone Call",
    description: "Whether it’s a small internal app or a new for millions of customers, our design and development teams.",
    icon: <PhoneIcon />,
    nextRoute: "/walkthrough/build-agent",
    type: CardTypes.schedulingType,
    typeValue: SchedulingType.phone,
  },
  {
    title: "AI Text",
    description: "The agent will TEXT the patient.",
    icon: <TextsmsIcon />,
    nextRoute: "/walkthrough/build-agent",
    type: CardTypes.schedulingType,
    typeValue: SchedulingType.text,
  },
  {
    title: "Email",
    description: "The agent will email the patient.",
    icon: <EmailIcon />,
    nextRoute: "/walkthrough/scheduling",
    type: CardTypes.schedulingType,
    typeValue: SchedulingType.email,
  },
];

const PatientCommunication: React.FC = () => {
  const navigate = useNavigate();
  const walkthroughUser = useSelector((state: RootState) => state.auth.authUserModel);

  const handleNext = (nextRoutes: string[]) => {
    nextRoutes.forEach((route) => {
      navigate(route);
    });
  };

  if (!walkthroughUser) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {walkthroughUser && (
        <>
          <LoginHeader />
          <WalkthroughPage
            walkthroughProgress={["SCHEDULING"]}
            title="How would you like the agent to communicate with patients?"
            selectionType={PageType.multiple}
            cards={cards}
            onNext={handleNext}
            progress="1/2"
          />
          {/* <Footer /> */}
        </>
      )}
    </>
  );
};

export default PatientCommunication;
