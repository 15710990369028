import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { useSnackbar } from "../providers/SnackbarProvider";
import { ReactComponent as CalendlyIntegrationLogo } from "../assets/calendly.svg";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { exchangeCodeForToken, getIntegrationStatus, setCalendlyOAuthToken } from "../slices/CalendlySlice";
import { FrontendUser } from "../types";
import { Container, LogoContainer } from "../styles";

const CalendlyIntegration: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const { showMessage } = useSnackbar();
  const [alreadyExchanged, setAlreadyExchanged] = useState(false);
  const [isIntegratingCalendly, setIsIntegratingCalendly] = useState(true);
  const [isExchangingCodeForToken, setIsExchangingCodeForToken] = useState(false);

  useEffect(() => {
    if (!user?.token) { return; }
    const code = searchParams.get("code");
    const exchangeAndCheckStatus = async () => {
      if (code && !alreadyExchanged) {
        try {
          setIsExchangingCodeForToken(true);
          const { calendlyToken } = await dispatch(exchangeCodeForToken({ token: user?.token!, code })).unwrap();
          dispatch(setCalendlyOAuthToken(calendlyToken));
          setAlreadyExchanged(true);
          setIsExchangingCodeForToken(false);

          // Function to continuously check integration status until it's complete
          const checkIntegrationStatus = async () => {
            if (!user?.token) { return; }
            try {
              for (let i = 0; i < 5; i++) {
                const { status } = await dispatch(getIntegrationStatus({ token: user?.token! })).unwrap();
                if (status === "connected") {
                  setIsIntegratingCalendly(false);
                  break;
                }
                await new Promise((resolve) => setTimeout(resolve, 5000)); // wait for 5 seconds before checking again
              }
            } catch (error) {
              console.error("Error checking integration status:", error);
              showMessage("Error checking integration status", "error");
            }
          };

          checkIntegrationStatus();
        } catch (error) {
          console.error("Error exchanging code for token:", error);
          setIsExchangingCodeForToken(false);
        }
      } else {
        console.error("No code found in URL or code already exchanged");
      }
    };

    exchangeAndCheckStatus();
  }, [searchParams, alreadyExchanged, user, dispatch, showMessage]);

  useEffect(() => {
    if (!isIntegratingCalendly) {
      navigate("/dashboard/integrations");
    }
  }, [isIntegratingCalendly, navigate]);

  return (
    <Container>
      <LogoContainer>
        <CalendlyIntegrationLogo />
      </LogoContainer>
      <LoadingWithMessage message="Integrating with Calendly, please wait..." size={40} />
    </Container>
  );
};

export default CalendlyIntegration;
