import React, { useEffect, useState } from "react";
import { Box, Typography, Checkbox, FormControlLabel, useMediaQuery, IconButton, Tooltip } from "@mui/material";
import styled from "styled-components";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { PrimaryButton } from "../subcomponents/CustomButton";
import { RootState, useAppDispatch } from "../store";
import { Agent, EHR, FrontendUser, HealthieProvider, OmniPatient, Patient, WaitlistRun, WaitlistState } from "../types";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Parser } from "@json2csv/plainjs";
import { toSnakeCase } from "../utils/utils";
import { fetchWaitlistRuns } from "../slices/WaitlistRunsSlice";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { useParams } from "react-router-dom";
import { Colors } from "../Colors";

const AgentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
  {
    label: "This Week",
    getValue: () => {
      const today = DateTime.local();
      return [today.startOf("week"), today.endOf("week")];
    },
  },
  {
    label: "Last Week",
    getValue: () => {
      const today = DateTime.local();
      const prevWeek = today.minus({ weeks: 1 });
      return [prevWeek.startOf("week"), prevWeek.endOf("week")];
    },
  },
  {
    label: "Last 7 Days",
    getValue: () => {
      const today = DateTime.local();
      return [today.minus({ days: 7 }), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = DateTime.local();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

type ExportModalProps = {
  agentsSelector: boolean;
  mainAgentsFilter: string[] | undefined;
  onClose: () => void;
};

const ExportWaitlistOutcomesModal: React.FC<ExportModalProps> = ({ agentsSelector, mainAgentsFilter, onClose }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const agents: Agent[] = useSelector((state: RootState) => state.agents.agents);

  // State for date range
  const [dateRange, setDateRange] = useState<[DateTime | null, DateTime | null]>([DateTime.now().minus({ weeks: 1 }), DateTime.now()]);
  const isLargeScreen = useMediaQuery("(min-width:800px)");

  // Waitlist runs selectors
  const waitlistRunIds: string[] = useSelector((state: RootState) => state.waitlistRuns.waitlistRunIds);
  const storeWaitlistRuns: { [key: string]: WaitlistRun } = useSelector((state: RootState) => state.waitlistRuns.waitlistRuns);
  const runs: WaitlistRun[] = waitlistRunIds.map((id: string) => storeWaitlistRuns[id]);
  const providers: HealthieProvider[] = useSelector((state: RootState) => state.healthieIntegration.providers);

  const patients: OmniPatient[] = useSelector((state: RootState) => state.patients.patients);

  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  // Loading state for waitlist runs
  const loadingRuns: boolean = useSelector((state: RootState) => state.waitlistRuns.fetchingRunsLoading);

  const dispatch = useAppDispatch();
  const [triggerExport, setTriggerExport] = useState(false);
  const { agentId } = useParams<{ agentId: string }>();
  const [agentsFilter, setAgentsFilter] = useState<string[] | undefined>(mainAgentsFilter);

  useEffect(() => {
    if (agentId && (!agentsFilter || !agentsSelector)) {
      setSelectedAgents((prev) => ({
        ...prev,
        [agentId]: true,
      }));

      setAgentsFilter([agentId]);
    }
  }, [agentsSelector, agentId, agentsFilter]);

  const [selectedAgents, setSelectedAgents] = useState<{ [key: string]: boolean }>(() => {
    const agentsObj: { [key: string]: boolean } = {};
    agents.forEach((agent: Agent) => {
      agentsObj[agent.agentId] = agentsFilter ? agentsFilter.includes(agent.agentId) : true;
    });
    return agentsObj;
  });

  const handleAgentChange = (agent: string) => {
    setSelectedAgents((prev) => ({
      ...prev,
      [agent]: !prev[agent],
    }));
  };

  const getMatchingWaitlistRuns = (agents: string[], dates: [DateTime | null, DateTime | null]): Partial<WaitlistRun>[] => {
    if (!runs) return [];

    const filteredRuns = runs.filter((run: WaitlistRun) => {
      const runDate = DateTime.fromISO(run.createdAt);

      const isAgentMatch: boolean = agents.includes(run.agentId);
      const isDateMatch: boolean = (!dates[0] || runDate >= dates[0]) && (!dates[1] || runDate <= dates[1]);

      return isAgentMatch && isDateMatch;
    });

    return filteredRuns.map((run: WaitlistRun) => {
      const provider: HealthieProvider | undefined = providers.find((provider) => provider.id === run.ehrProviderId);

      let patientEhrId: string | number | undefined;
      const patient: OmniPatient | undefined = patients.find((patient: OmniPatient) => patient.patientId === run?.bookedBy?.patientId);
      if (run.ehr === EHR.healthie) {
        patientEhrId = patient?.[EHR.healthie]?.healthiePatientId;
      } else if (run.ehr === EHR.webpt) {
        patientEhrId = patient?.[EHR.webpt]?.webptPatientId;
      } else {
        console.log(`Unknown EHR: ${run.ehr}`);
      }

      return toSnakeCase({
        waitlistRunId: run.waitlistRunId,
        appointmentDate: run.appointmentDate,
        state: run.state,
        stateJustification: run.stateJustification,
        expirationDate: run.expirationDate,
        timeToFillMinutes: run.timeToFillMinutes,
        createdAt: run.createdAt,
        timeEnded: run.state !== WaitlistState.ongoing && run.state !== WaitlistState.notStarted ? run.lastModified : "",
        bookedByFirstName: run.bookedBy?.firstName,
        bookedByLastName: run.bookedBy?.lastName,
        bookedByPhoneNumber: run.bookedBy?.phoneNumber,
        bookedByEmail: run.bookedBy?.email,
        ...(run.ehrApptTypeId && { ehrApptTypeId: run.ehrApptTypeId }),
        ...(run.appointmentTypeName && { appointmentTypeName: run.appointmentTypeName }),
        ...(run.ehrProviderId && { ehrProviderId: run.ehrProviderId }),
        ...(provider && { providerFirstName: provider.firstName }),
        ...(provider && { providerLastName: provider.lastName }),
        ...(patientEhrId && { patientEhrId }),
        ...(run.location && { location: run.location }),
      });
    });
  };

  const handleExport = () => {
    if (!user?.token) {
      return;
    }
    const agentIds = Object.entries(selectedAgents)
      .filter(([agentId, isSelected]) => isSelected)
      .map(([agentId]) => agentId);
    setTriggerExport(true);
    dispatch(fetchWaitlistRuns({ token: user.token, agentId: agentIds.join(","), quietly: true }));
  };

  useEffect(() => {
    if (triggerExport && !loadingRuns) {
      if (!runs || runs.length === 0) {
        setErrorMsg("No waitlist runs available for export.");
        setTriggerExport(false);
        return;
      }

      const filteredRuns = getMatchingWaitlistRuns(
        Object.keys(selectedAgents).filter((id) => selectedAgents[id]),
        dateRange
      );

      if (filteredRuns.length > 0) {
        const parser = new Parser();
        const csv = parser.parse(filteredRuns);

        const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.setAttribute("download", `exported_waitlist_runs_${dateRange[0]?.toFormat("M_d_yy")}_to_${dateRange[1]?.toFormat("M_d_yy")}.csv`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setErrorMsg("No matching waitlist runs found for the selected criteria.");
      }

      setTriggerExport(false);
    }
  }, [triggerExport, loadingRuns, runs, selectedAgents, dateRange]);

  const handleDateChange = (value: [DateTime | null, DateTime | null]) => {
    const [start, end] = value;
    setDateRange([start, end && end.plus({ days: 1 })]);
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "auto",
        paddingBottom: "60px",
        paddingRight: "20px",
        paddingTop: "70px",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          padding: "12px 25px",
          width: "100%",
          display: "flex",
          borderBottom: "2px solid Colors.textfield",
          background: "#151A33",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 1000,
        }}
      >
        <Box display={"flex"} gap={"10px"} sx={{ color: Colors.coolWhite }}>
          <IosShareIcon />
          <Typography variant={"body2"} fontSize={"1.1rem"} fontWeight={"bold"}>
            Export Waitlist Runs
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            onClose();
          }}
          sx={{ color: "#FFFFFF" }}
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <StaticDateRangePicker
          slotProps={{
            shortcuts: isLargeScreen ? { items: shortcutsItems } : undefined,
            actionBar: { actions: [] },
          }}
          calendars={1}
          onChange={handleDateChange}
          sx={{
            "@media (max-width: 768px)": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "-20px",
            },
          }}
          value={dateRange}
        />
      </LocalizationProvider>

      {agentsSelector && (
        <>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "5px" }}>
            <Typography variant="h6"> Agents </Typography>
            <Typography variant="subtitle2"> (select multiple) </Typography>
          </div>
          <AgentsContainer>
            {agents.map((agent) => (
              <FormControlLabel
                key={agent.agentId}
                control={<Checkbox checked={selectedAgents[agent.agentId]} onChange={() => handleAgentChange(agent.agentId)} color="primary" />}
                label={agent.name}
              />
            ))}
          </AgentsContainer>
        </>
      )}

      {errorMsg && (
        <Typography variant="body2" color="error">
          {errorMsg}
        </Typography>
      )}

      {loadingRuns || triggerExport ? (
        <LoadingWithMessage message="Fetching waitlist runs and preparing CSV..." size={60} />
      ) : (
        <>
          <Typography variant="body1" sx={{ marginBottom: "20px" }}>
            Export waitlist runs matching the selected criteria.
          </Typography>

          <PrimaryButton variant="contained" onClick={handleExport}>
            Export to CSV
          </PrimaryButton>
        </>
      )}
    </Box>
  );
};

export default ExportWaitlistOutcomesModal;
