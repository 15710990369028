import { FunctionComponent } from "react";
import styles from "../Calendar.module.scss";

type ToasterProps = {
  message: string;
  visible: boolean;
};

const Toaster: FunctionComponent<ToasterProps> = ({ message, visible }) => {
  return (
    <div className={[styles.toaster, visible ? styles.visible : ""].join(" ")}>
      {message}
    </div>
  );
};

export default Toaster;
