// src/auth/ProtectedRoute.tsx

import React from "react";
import { Navigate } from "react-router-dom";
import { RootState } from "../store";
import { useSelector } from "react-redux";

interface ProtectedRouteProps {
  element: React.ReactElement;
  path?: string; // Include other props you might need
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const loading = useSelector((state: RootState) => state.auth.isLoading);
  const sessionExpired = useSelector((state: RootState) => state.session.sessionExpired);

  if (!user && !loading && !sessionExpired) {
    return <Navigate to="/login" />;
  }

  // Render the component if user is authenticated
  return element;
};

export default ProtectedRoute;
