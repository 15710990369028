import * as React from "react";
import Box from "@mui/material/Box";
import { useNavigate, useParams } from "react-router-dom";
import { Consent, FrontendUser, Organization, WaitlistRun, WaitlistSlotState, WaitlistState } from "../types";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { useEffect, useState } from "react";
import SignoutHeader from "../subcomponents/SignoutHeader";
import Typography from "@mui/material/Typography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { SecondaryButton, TransparentButton } from "../subcomponents/CustomButton";
import { Colors } from "../Colors";
import { useSnackbar } from "../providers/SnackbarProvider";
import { DateTime } from "luxon";
import Loader from "../subcomponents/Loader";
import {
  api,
  callStatusColor,
  callStatusDisplay,
  convertKebabCaseToOfficial,
  convertSnakeCaseToOfficial,
  formatIsoToCustomDateStringWithEEEEHHMMA,
  getOutcomeColor,
} from "../utils/utils";
import { set } from "date-fns";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { Avatar, CircularProgress, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import { OvalOutlineListItem } from "../styles/GeneralStyles";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TimerIcon from "@mui/icons-material/Timer";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import NotesIcon from "@mui/icons-material/Notes";
import CloseIcon from "@mui/icons-material/Close";
import EventIcon from "@mui/icons-material/Event";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import LoopIcon from "@mui/icons-material/Loop";
import SendIcon from "@mui/icons-material/Send";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { fetchWaitlistRun, fetchWaitlistRuns } from "../slices/WaitlistRunsSlice";
import { fetchWaitlistSlot } from "../slices/WaitlistSlotsSlice";
import WarningIcon from "@mui/icons-material/Warning";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import CallIcon from "@mui/icons-material/Call";
import PhonePausedIcon from "@mui/icons-material/PhonePaused";

const WaitlistRunInfo: React.FC = () => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const waitlistRuns = useSelector((state: RootState) => state.waitlistRuns.waitlistRuns);
  const runsAgentId = useSelector((state: RootState) => state.waitlistRuns.agentId);
  const { agentId, waitlistRunId } = useParams<{ agentId: string; waitlistRunId: string }>();
  const navigate = useNavigate();

  const agent = useSelector((state: RootState) => state.agents.agents.find((agent) => agent.agentId === agentId));

  const waitlistRun: WaitlistRun | undefined = waitlistRunId ? waitlistRuns[waitlistRunId] : undefined;
  const storeWaitlistSlots = useSelector((state: RootState) => state.waitlistSlots.waitlistSlots);
  const waitlistRunsLoading = useSelector((state: RootState) => state.waitlistRuns.waitlistRunsLoading);
  const loading = waitlistRunId && waitlistRunsLoading[waitlistRunId];
  const waitlistSlotsLoading = useSelector((state: RootState) => state.waitlistSlots.waitlistSlotsLoading);

  const loadingProviders = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);

  const userTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const dispatch = useAppDispatch();

  const { showMessage } = useSnackbar();

  useEffect(() => {
    if (!user?.token) {
      return;
    }
    if (waitlistRunId) {
      dispatch(fetchWaitlistRun({ token: user?.token, waitlistRunId }));
    }
  }, [waitlistRunId, user?.token]);

  useEffect(() => {
    if (!user?.token) {
      return;
    }
    if (waitlistRun?.waitlistSlotIds) {
      waitlistRun.waitlistSlotIds.forEach((slotId: string) => {
        dispatch(fetchWaitlistSlot({ token: user?.token!, waitlistSlotId: slotId }));
      });
    }
  }, [waitlistRun?.waitlistRunId]);

  const handleBack = () => {
    navigate(`/dashboard/agents/${agentId}`);
  };

  const handleEndRun = async (quietly: boolean) => {
    if (!user?.token || !waitlistRunId) {
      return;
    }

    try {
      await api.post(`/waitlist/runs/${waitlistRunId}/end`, user.token, { quietly });
      showMessage("Waitlist run ended successfully", "success");
      dispatch(fetchWaitlistRun({ token: user.token, waitlistRunId }));
    } catch (error) {
      showMessage("Failed to end waitlist run", "error");
    }
  };

  const renderIconBasedOnOutcome = (outcome: string) => {
    switch (outcome) {
      case "accepted":
        return <CheckCircleIcon style={{ color: Colors.success }} />;
      case "declined":
      case "no-valid-patients":
      case "booking-failed":
        return <CloseIcon style={{ color: Colors.error }} />;
      default:
        return <QuestionMarkIcon style={{ color: "#FFF" }} />;
    }
  };

  return (
    <Box sx={{ margin: { xs: "65px 0 20px 0", lg: "0" }, color: "#FFF" }}>
      <SignoutHeader />
      <SecondaryButton onClick={handleBack} sx={{ display: { xs: "none", lg: "flex" }, alignItems: "center", margin: "20px 0" }}>
        <ArrowBackIcon sx={{ marginRight: "10px" }} /> Back to Dashboard
      </SecondaryButton>
      {!waitlistRun && loading ? (
        <LoadingWithMessage message="Loading waitlist run" size={60} />
      ) : !waitlistRun ? (
        <Typography variant="h4">Waitlist Run not found</Typography>
      ) : (
        <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, gap: "20px" }}>
          <Box sx={{ flex: 1, paddingRight: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography gutterBottom variant="h4" component="div" sx={{ my: 2 }}>
                {`Status: ${convertKebabCaseToOfficial(waitlistRun.state)}`}
              </Typography>
            </Box>

            {/*
            <OvalOutlineListItem maxWidth="380px">
              <ListItemIcon>
                <Avatar src={agent?.avatarUrl} alt={agent?.name} sx={{ width: 30, height: 30, color: "#FFF" }} />
              </ListItemIcon>
              {agent && <ListItemText primary="Managed by" secondary={agent.name} secondaryTypographyProps={{ color: Colors.info }} />}
            </OvalOutlineListItem>
            */}

            <OvalOutlineListItem maxWidth="380px">
              <ListItemIcon>
                <EventIcon style={{ color: "#FFF" }} />
              </ListItemIcon>
              <ListItemText
                primary="Appointment details"
                secondary={`${waitlistRun.appointmentTypeName} with ${waitlistRun.pcpName}`}
                secondaryTypographyProps={{ color: Colors.info }}
              />
            </OvalOutlineListItem>

            {waitlistRun.location && (
              <OvalOutlineListItem maxWidth="380px">
                <ListItemIcon>
                  <MapsHomeWorkIcon style={{ color: "#FFF" }} />
                </ListItemIcon>
                <ListItemText primary="Location" secondary={waitlistRun.location} secondaryTypographyProps={{ color: Colors.info }} />
              </OvalOutlineListItem>
            )}

            <OvalOutlineListItem maxWidth="380px">
              <ListItemIcon>
                <EventIcon style={{ color: "#FFF" }} />
              </ListItemIcon>
              <ListItemText
                primary="Appointment date"
                secondary={formatIsoToCustomDateStringWithEEEEHHMMA(waitlistRun?.appointmentDate, organization?.timezone)}
                secondaryTypographyProps={{ color: Colors.info }}
              />
            </OvalOutlineListItem>

            <OvalOutlineListItem maxWidth="380px">
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ListItemIcon>{renderIconBasedOnOutcome(waitlistRun.state)}</ListItemIcon>
                <ListItemText primary="Waitlist Run Outcome" secondary={waitlistRun.state} secondaryTypographyProps={{ color: Colors.info }} />
              </Box>
            </OvalOutlineListItem>

            <OvalOutlineListItem maxWidth="380px">
              <ListItemIcon>
                <NotesIcon style={{ color: "#FFF" }} />
              </ListItemIcon>
              <ListItemText
                primary="Outcome Justification"
                secondary={waitlistRun.stateJustification}
                secondaryTypographyProps={{ color: Colors.info }}
              />
            </OvalOutlineListItem>

            <OvalOutlineListItem maxWidth="380px">
              <ListItemIcon>
                <AccessTimeIcon style={{ color: "#FFF" }} />
              </ListItemIcon>
              <ListItemText
                primary="Started on"
                secondary={formatIsoToCustomDateStringWithEEEEHHMMA(DateTime.fromISO(waitlistRun.createdAt).setZone(userTimeZone).toISO()!)}
                secondaryTypographyProps={{ color: Colors.info }}
              />
            </OvalOutlineListItem>

            {![WaitlistState.notStarted, WaitlistState.ongoing, WaitlistState.noValidPatients].includes(waitlistRun.state) && (
              <>
                <OvalOutlineListItem maxWidth="380px">
                  <ListItemIcon>
                    <AccessTimeIcon style={{ color: "#FFF" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Ended on"
                    secondary={formatIsoToCustomDateStringWithEEEEHHMMA(DateTime.fromISO(waitlistRun.lastModified).setZone(userTimeZone).toISO()!)}
                    secondaryTypographyProps={{ color: Colors.info }}
                  />
                </OvalOutlineListItem>
                <OvalOutlineListItem maxWidth="380px">
                  <ListItemIcon>
                    <AccessTimeIcon style={{ color: "#FFF" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Time elapsed"
                    secondary={DateTime.fromISO(waitlistRun.lastModified)
                      .diff(DateTime.fromISO(waitlistRun.createdAt), ["days", "hours", "minutes"])
                      .toFormat("d 'days' h 'hours' m 'minutes'")}
                    secondaryTypographyProps={{ color: Colors.info }}
                  />
                </OvalOutlineListItem>
              </>
            )}

            {waitlistRun.state === WaitlistState.ongoing && (
              <>
                <OvalOutlineListItem maxWidth="380px">
                  <ListItemIcon>
                    <AccessTimeIcon style={{ color: "#FFF" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Time elapsed"
                    secondary={DateTime.now()
                      .diff(DateTime.fromISO(waitlistRun.lastModified), ["days", "hours", "minutes"])
                      .toFormat("d 'days' h 'hours' m 'minutes'")}
                    secondaryTypographyProps={{ color: Colors.info }}
                  />
                </OvalOutlineListItem>
              </>
            )}

            <OvalOutlineListItem maxWidth="380px">
              <ListItemIcon>
                <FingerprintIcon style={{ color: "#FFF" }} />
              </ListItemIcon>
              {waitlistRun && (
                <ListItemText primary="Waitlist Run ID" secondary={waitlistRun?.waitlistRunId} secondaryTypographyProps={{ color: Colors.info }} />
              )}
            </OvalOutlineListItem>

            {(waitlistRun.state === WaitlistState.ongoing || waitlistRun.state === WaitlistState.notStarted) && (
              <>
                <OvalOutlineListItem
                  maxWidth="380px"
                  sx={{ cursor: "pointer", backgroundColor: Colors.error }}
                  onClick={() => {
                    handleEndRun(false);
                  }}
                >
                  <ListItemIcon>
                    <CloseIcon style={{ color: "#FFF" }} />
                  </ListItemIcon>
                  <ListItemText primary="End Run and Notify Patients" />
                </OvalOutlineListItem>
                <OvalOutlineListItem
                  maxWidth="380px"
                  sx={{ cursor: "pointer", backgroundColor: Colors.error }}
                  onClick={() => {
                    handleEndRun(true);
                  }}
                >
                  <ListItemIcon>
                    <CloseIcon style={{ color: "#FFF" }} />
                  </ListItemIcon>
                  <ListItemText primary="End Run without Notifying Patients" />
                </OvalOutlineListItem>
              </>
            )}
          </Box>

          <Box
            sx={{
              flex: 1,
              paddingLeft: 2,
              maxWidth: { xs: "100%", md: "50%" },
              alignSelf: "flex-start",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Typography gutterBottom variant="h4" component="div" sx={{ my: 2 }}>
                Patients
              </Typography>
            </Box>
            <List>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", mb: 2 }}>
                {waitlistRun.waitlistSlotIds.map((waitlistSlotId: string) => {
                  const slot = storeWaitlistSlots[waitlistSlotId];
                  return (
                    <>
                      <OvalOutlineListItem
                        key={waitlistSlotId}
                        maxWidth="350px"
                        alignItems="center"
                        onClick={() => navigate(`/dashboard/agents/${agentId}/waitlist-runs/${waitlistRunId}/slots/${slot?.waitlistSlotId}`)}
                        sx={{
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: Colors.secondaryDark,
                          },
                        }}
                      >
                        {!slot && waitlistSlotsLoading[waitlistSlotId] ? (
                          <>
                            <CircularProgress size={50} />
                            <Box sx={{ width: 20 }} />
                            <Typography variant="h6">Loading slot</Typography>
                          </>
                        ) : !slot ? (
                          <ListItemText primary="Slot not found" />
                        ) : (
                          <>
                            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                              {/* Consent Logic */}
                              {slot?.patient?.consentHistory && slot.patient.consentHistory.length > 0 ? (
                                (() => {
                                  const consentHistory = slot.patient.consentHistory;
                                  const latestConsent = consentHistory[consentHistory.length - 1];
                                  const isRevoked = latestConsent.consent === Consent.revoked;
                                  const lastConsentMessage = latestConsent.message;
                                  const lastConsentDate = DateTime.fromISO(latestConsent.date).toLocaleString(DateTime.DATE_MED);

                                  const revokedMessage = `This patient has placed themselves on the Do Not Call list via the request "${lastConsentMessage}" on ${lastConsentDate}`;

                                  return (
                                    <PatientStatusAvatarTooltip
                                      patientStatus={slot?.state}
                                      sx={{ mr: 2 }}
                                      isRevoked={isRevoked} // Pass isRevoked to conditionally render WarningIcon
                                      revokedMessage={revokedMessage} // Pass the detailed message
                                    />
                                  );
                                })()
                              ) : (
                                <PatientStatusAvatarTooltip patientStatus={slot?.state} sx={{ mr: 2 }} />
                              )}
                              <ListItemText
                                primary={`${slot?.patient?.firstName} ${slot?.patient?.lastName}`}
                                secondary={statusMessages[slot.state as keyof typeof statusMessages]}
                                secondaryTypographyProps={{ color: Colors.info }}
                              />
                            </Box>
                            <ChevronRightIcon fontSize="large" style={{ color: Colors.info }} />
                          </>
                        )}
                      </OvalOutlineListItem>
                    </>
                  );
                })}
              </Box>
            </List>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export const statusColors = {
  accepted: "#4CAF50",
  "not-contacted": Colors.statusGrey,
  "no-response": Colors.statusGrey,
  declined: "#F44336",
  "in-progress": "#90CAF9",
  unknown: Colors.statusGrey,
  contacted: Colors.statusGrey,
  "booking-failed": "#F44336",
  expired: Colors.statusGrey,
  error: "#F44336",
  "cannot-contact": "#F44336",
  "too-many-runs": "#F44336",
  [WaitlistSlotState.noLongerAvailable]: "#F44336",
  [WaitlistSlotState.callQueued]: Colors.statusGrey,
  [WaitlistSlotState.callInProgress]: Colors.statusGrey,
};

export const statusIcons = {
  "not-contacted": <Box width={24} height={24} />,
  declined: <EventBusyIcon />,
  accepted: <EventAvailableIcon />,
  "in-progress": <LoopIcon />,
  contacted: <SendIcon />,
  "no-response": <Box width={24} height={24} />,
  unknown: <CloseIcon />,
  "booking-failed": <CloseIcon />,
  expired: <CloseIcon />,
  error: <CloseIcon />,
  "cannot-contact": <DoNotDisturbIcon />,
  "too-many-runs": <DoNotDisturbIcon />,
  [WaitlistSlotState.noLongerAvailable]: <EventBusyIcon />,
  [WaitlistSlotState.callQueued]: <PhonePausedIcon />,
  [WaitlistSlotState.callInProgress]: <CallIcon />,
};

export const statusMessages = {
  "not-contacted": "Not Contacted",
  declined: "Declined",
  accepted: "Confirmed",
  "in-progress": "In Progress",
  "no-response": "No Response",
  unknown: "Unsuccessful",
  contacted: "Initial Contact",
  "booking-failed": "Booking Failed",
  expired: "Expired",
  error: "Error",
  "cannot-contact": "Cannot Contact",
  "too-many-runs": "Too Many Runs",
  [WaitlistSlotState.noLongerAvailable]: "Appointment already taken",
  [WaitlistSlotState.callQueued]: "Call queued",
  [WaitlistSlotState.callInProgress]: "Call in progress",
};

export const PatientStatusAvatarTooltip: React.FC<{
  patientStatus: any;
  sx?: object;
  isRevoked?: boolean;
  revokedMessage?: string;
}> = ({ patientStatus, sx, isRevoked, revokedMessage }) => {
  const message = statusMessages[patientStatus as keyof typeof statusMessages];

  return (
    <Tooltip title={isRevoked ? revokedMessage : message}>
      <Box component="span" sx={sx}>
        {isRevoked ? (
          <Avatar sx={{ bgcolor: Colors.statusGrey }}>
            <WarningIcon sx={{ color: "white" }} />
          </Avatar>
        ) : (
          <Avatar sx={{ bgcolor: statusColors[patientStatus as keyof typeof statusColors] }}>
            {statusIcons[patientStatus as keyof typeof statusIcons]}
          </Avatar>
        )}
      </Box>
    </Tooltip>
  );
};

export default WaitlistRunInfo;
