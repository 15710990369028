import React, { useCallback } from 'react';
import { Modal, Box, Typography, Button, IconButton } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { usePostHog } from 'posthog-js/react';
import { FrontendUser } from '../../types';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Colors } from '../../Colors';

interface UploadCSVModalProps {
  onFileUpload: (file: File) => void;
}

const UploadCSVModal: React.FC<UploadCSVModalProps> = ({ onFileUpload }) => {
  const posthog = usePostHog();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization = useSelector((state: RootState) => state.auth.organization);
  
  // Handler for drag and drop
  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    posthog?.capture('[PENCILED] dropped_csv_file', { userId: user?.userId, email: user?.email, orgName: organization?.name });
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      onFileUpload(file);
    }
  }, [onFileUpload]);

  // Handler for file input change
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    posthog?.capture('[PENCILED] click_upload_csv_then_dragged_file', { userId: user?.userId, email: user?.email, orgName: organization?.name });
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      onFileUpload(file);
    }
  }, [onFileUpload]);

  // Prevent default behavior (Prevent file from being opened)
  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
      <Box sx={{
        marginTop: 1,
        bgcolor: Colors.bg2,
        p: 3,
        width: '100%',
        borderRadius: 2,
        textAlign: 'center'
      }}>
        <Typography id="upload-csv-modal-title" variant="body1" fontWeight={'bold'} sx={{ mb: 2 }}>
          Upload Question Bank CSV
        </Typography>
        <label htmlFor="file-input">
          <input
            style={{ display: 'none' }}
            id="file-input"
            type="file"
            accept=".csv"
            onChange={handleChange}
          />
          <Box
            sx={{
              border: '1px dashed rgba(255, 255, 255, 0.2)',
              padding: 2,
              borderRadius: 2,
              backgroundColor: Colors.textfield,
              cursor: 'pointer',
              ':hover': {  
                backgroundColor: 'rgba(255, 255, 255, 0.06)',
              }
            }}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <IconButton component="span">
              <UploadFileIcon sx={{ fontSize: 60, color: "grey" }} />
            </IconButton>
            <Typography variant="body1">Click to upload or drag files here</Typography>
          </Box>
        </label>
      </Box>
  );
};

export default UploadCSVModal;
