import React, { useCallback } from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css'; // Ensure you import the necessary CSS
import { Colors } from '../Colors';

interface PhoneNumberFieldProps {
  phoneNumber: string; 
  setPhoneNumber: (phone: string) => void;
  label: string;
  sx?: any;
}

const PhoneNumberField: React.FC<PhoneNumberFieldProps> = ({ phoneNumber, setPhoneNumber, label, sx}) => {

  const handlePhoneChange = useCallback((value: string | undefined) => {
    setPhoneNumber(value || '');
  }, [setPhoneNumber]);

  const renderInputComponent = useCallback(
    React.forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => (
      <TextField
        {...props}
        inputRef={ref}
        fullWidth
        label={label}
        variant="outlined"
        InputLabelProps={{ 
          shrink: true,
          style: { color: Colors.info },
         }}
        sx={{ ...sx, mb: 2 }}
      />
    )),
    []
  );

  return (
    <PhoneInput
      value={phoneNumber}
      onChange={handlePhoneChange}
      inputComponent={renderInputComponent}
    />
  );
};

export default PhoneNumberField;
