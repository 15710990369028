import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const HelpTooltip: React.FC<{ title: any }> = ({ title }) => {
  return (
    <Tooltip title={title}>
      <IconButton>
        <HelpOutlineIcon style={{ color: "white" }} />
      </IconButton>
    </Tooltip>
  );
};
