import { Box, IconButton, ListItem } from "@mui/material";
import styled from "styled-components";
import { Colors } from "../Colors";

// Define the type for your custom props
type HorizontalSpacerProps = {
  size?: string;
};

export const HorizontalSpacer = styled.div<HorizontalSpacerProps>`
  width: ${(props) => props.size || "16px"};
`;

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  padding-top: 20px;
  padding-right: 100px;
  padding-left: 100px;

  @media (max-width: 600px) {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 100px;
  }
`;

export const ModalBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  margin: 5% auto;
  background-color: #fff;
  padding: 40px;
  border-radius: 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 80%;
    margin: 25% auto;
  }
`;

export const IconWithHover = styled(IconButton)`
  &:hover {
    background-color: #f5f5f5; // Light grey background on hover
  }
`;

interface CustomOvalOutlineListItemProps {
  maxWidth?: string;
}

export const OvalOutlineListItem = styled(ListItem)<CustomOvalOutlineListItemProps>`
  display: flex;
  flex-direction: row; // use row for a horizontal layout since ListItem is typically horizontal
  align-items: center;
  border-radius: 50px; // gives an oval shape
  // border: 1px solid rgba(255, 255, 255, 0.4); // use a light grey border, for example
  padding: 8px 16px; // add padding as needed
  margin-bottom: 18px; // separates each ListItem from the next
  max-width: ${({ maxWidth = "100%" }) => maxWidth}; // ensure maxWidth always has a value
  background-color: ${Colors.secondary}; // primary color background
`;

export const Sidebar = styled(Box)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 33%;
  background-color: #151A33;
  color: #FFF;
  padding: 20px 0px 20px 30px;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;

  @media (max-width: 1100px) {
    width: 55%;
  }

  @media (max-width: 768px) {
    width: 80%;
  }

  &.open {
    transform: translateX(0);
  }
`;


// Dropdown Styles
export const dropdownStyles = {
  borderRadius: '10px',
  background: Colors.textfield,
  marginTop: '5px',
  '& .MuiInputBase-input': {
    padding: '15px',
    fontSize: '0.9rem',
    color: '#FFF',
  },
  '& .MuiSvgIcon-root': {
    color: '#FFF',
  },
};

export const menuPropsStyles = {
  PaperProps: {
    sx: {
      backgroundColor: Colors.bg3,
      color: '#FFF',
      borderRadius: '15px',
      '& .MuiMenuItem-root': {
        backgroundColor: Colors.bg3,
        padding: '6px 15px',
        '&:hover': {
          backgroundColor: Colors.textfield,
        },
      },
      '& .MuiCheckbox-root': {
        color: '#FFF',
      },
      '& .MuiListItemText-root': {
        color: '#FFF',
      },
    },
  },
};

export const InputStyles = {
  borderRadius: "10px",
  backgroundColor: Colors.textfield,
  width: "100%",
  '& .MuiInputBase-input': {
    fontSize: '0.9rem',
    color: '#FFF',
  },
  "& .MuiInputLabel-root": {
    color: Colors.info,
  },
  '& .MuiSvgIcon-root': {
    color: '#FFF',
  },
};

export const ModalBoxVariant1 = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65%;
  margin: 3% auto;
  background-color: #fff;
  padding: 3%;
  border-radius: 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  overflow-y: auto;

  @media (max-width: 1100px) {
    width: 95%;
    margin: 10% auto;
  }

  @media (max-width: 480px) {
    width: 90%;
    padding: 5%;
    margin: 5% auto;
  }
`;
