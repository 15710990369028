import React from "react";
import styled from "styled-components";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";

// Define the colors and styles for the walkthrough progress steps
const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StepText = styled.span`
  font-size: 16px;
  margin-right: 8px;
  color: #5e78ee;
  font-family: "Konnect-Medium";
`;

const ArrowIcon = styled(ArrowRightAltIcon)`
  font-size: 22px;
  color: #5e78ee;
  margin-right: 8px;
`;

interface WalkthroughProgressProps {
  walkthroughProgress: string[];
}

const WalkthroughProgress: React.FC<WalkthroughProgressProps> = ({ walkthroughProgress }) => {
  return (
    <ProgressContainer>
      {walkthroughProgress.map((step, index) => (
        <StepContainer key={index}>
          <StepText>{step}</StepText>
          {index < walkthroughProgress.length - 1 && <ArrowIcon />}
        </StepContainer>
      ))}
    </ProgressContainer>
  );
};

export default WalkthroughProgress;
