import React, { useEffect, useState } from "react";
import { Modal, Typography, FormControl, OutlinedInput, Box, Select, MenuItem } from "@mui/material";
import styled from "styled-components";
import CreateAccountForm from "./CreateAccountForm";
import { RootState, useAppDispatch } from "../store";
import { useSelector } from "react-redux";
import { updateWalkthroughUser } from "../slices/AuthSlice";
import { PrimaryButton } from "../subcomponents/CustomButton";
import { usePostHog } from "posthog-js/react";
import { useDispatch } from "react-redux";
import { Colors } from "../Colors";
import { isPhoneNumber } from "../utils/utils";

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  padding-top: 30px;
  padding-right: 100px;
  padding-left: 100px;

  @media (max-width: 1250px) {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 1% auto;

  @media (max-width: 600px) {
    width: 95%;
    paddingbottom: 50px;
  }
`;

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #f7f9fe;
  padding: 5%;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);

  @media (max-width: 600px) {
    padding: 10%;
  }
`;

const FormInput = styled(OutlinedInput)`
  background-color: #ffffff;
  color: #646769;
  border-radius: 10px;
  margin-bottom: 5%;
`;

const RowBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  gap: 15%;
  margin-bottom: 20px;

  @media (max-width: 920px) {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }
`;

const StyledButtonBox = styled.div`
  display: flex;
  justify-content: left;
  width: 42.5%;
  margin-top: 20px;

  @media (max-width: 600px) {
    justify-content: center;
    width: 100%;
  }
`;

const defaultAreaCode = "916";

const CreateAgentForm: React.FC = () => {
  const [agentName, setAgentName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [callbackNumber, setCallbackNumber] = useState("");
  const [areaCode, setAreaCode] = useState(defaultAreaCode);
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const posthog = usePostHog();
  const dispatch = useDispatch();

  const walkthroughUser = useSelector((state: RootState) => state.auth.authUserModel);

  const handleAgentNameChange = (value: string) => {
    setAgentName(value);
    dispatch(updateWalkthroughUser({ agentName: value }));
  };

  const handleOrgNameChange = (value: string) => {
    setOrgName(value);
    dispatch(updateWalkthroughUser({ orgName: value }));
  };

  const handleCallbackNumberChange = (value: string) => {
    setCallbackNumber(value);
    setErrorMessage(""); // Clear the error message when the user starts typing a new number
    dispatch(updateWalkthroughUser({ callbackNumber: value }));
  };

  const handleAreaCodeChange = (value: string) => {
    if (!value) {
      return;
    }

    if (/^\d{0,3}$/.test(value)) {
      setAreaCode(value);
    }
    dispatch(updateWalkthroughUser({ orgAreaCode: value }));
  };

  const isFormValid = () => {
    return agentName.trim() !== "" && orgName.trim() !== "" && callbackNumber.trim() !== "" && areaCode.trim() !== "";
  };

  useEffect(() => {
    posthog?.capture("[PENCILED] Create Agent page loaded");
  }, []);

  const handleCreateAgentClick = () => {
    if (!isPhoneNumber(callbackNumber)) {
      setErrorMessage("ERROR: Invalid phone number");
      return;
    }

    setCreateAccountModalOpen(true);
    posthog?.capture("[PENCILED] Create Agent button clicked", {
      organizationName: orgName,
      callbackNumber: callbackNumber,
      agentName: agentName
    });
  };

  return (
    <>
      {walkthroughUser ? (
        <>
          <PageContainer>
            <StyledBox>
              <Typography variant="subtitle1" sx={{ marginTop: "20px", fontSize: { xs: "1.2rem", sm: "1.7rem" }, color: Colors.grey3 }}>
                Welcome,   
              </Typography>
              <Typography variant="h4" sx={{ marginBottom: {xs: "15px", md: "30px"}, fontSize: { xs: "1.5rem", sm: "2rem" } }}>
                Let's create your first AI voice agent!
              </Typography>
              <FormBox>
                <Typography style={{ marginBottom: "20px", fontFamily: "Konnect-Regular" }}>General Information</Typography>
                <RowBox>
                  <FormControl fullWidth>
                    <Typography variant="h6" sx={{ marginBottom: {xs: "5px", md: "10px"}, fontSize: {xs: "0.9rem", md: "1.1rem"} }}>
                      Agent's Name
                    </Typography>
                    <OutlinedInput
                      placeholder="eg. Chloe"
                      value={agentName}
                      onChange={(e) => handleAgentNameChange(e.target.value)}
                      sx={{
                        borderRadius: "10px",
                        fontSize: { xs: "15px", sm: "16px" },
                        backgroundColor: "#ffffff",
                      }}
                      required
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography variant="h6" sx={{ marginBottom: {xs: "5px", md: "10px"}, fontSize: {xs: "0.95rem", md: "1.1rem"} }}>
                      Business Name
                    </Typography>
                    <OutlinedInput
                      placeholder="eg. Advanced Neurologists Specialists"
                      value={orgName}
                      onChange={(e) => handleOrgNameChange(e.target.value)}
                      sx={{
                        borderRadius: "10px",
                        fontSize: { xs: "15px", sm: "16px" },
                        backgroundColor: "#ffffff",
                      }}
                      required
                    />
                  </FormControl>
                </RowBox>
                <RowBox>
                  <FormControl fullWidth>
                    <Typography variant="h6" sx={{ marginBottom: {xs: "5px", md: "10px"}, fontSize: {xs: "0.95rem", md: "1.1rem"} }}>
                      Callback Number
                    </Typography>
                    <OutlinedInput
                      placeholder="eg. +10000000000"
                      value={callbackNumber}
                      onChange={(e) => handleCallbackNumberChange(e.target.value)}
                      sx={{
                        borderRadius: "10px",
                        fontSize: { xs: "15px", sm: "16px" },
                        backgroundColor: "#ffffff",
                      }}
                      required
                    />
                    {errorMessage && (
                      <Typography color="error" sx={{ marginTop: "10px" }}>
                        {errorMessage}
                      </Typography>
                    )}
                  </FormControl>
                  <FormControl fullWidth>
                    <Typography
                      variant="h6"
                      sx={{
                        marginBottom: { xs: "5px", md: "10px" },
                        fontSize: { xs: "0.95rem", md: "1.1rem" }
                      }}
                    >
                      Desired Area Code
                    </Typography>
                    <Select
                      value={areaCode || defaultAreaCode}
                      onChange={(e: any) => handleAreaCodeChange(e.target.value)}
                      sx={{
                        borderRadius: "10px",
                        fontSize: { xs: "15px", sm: "16px" },
                        backgroundColor: "#ffffff",
                      }}
                      required
                    >
                      <MenuItem value={"916"}>916</MenuItem>
                    </Select>
                  </FormControl>
                </RowBox>
                <StyledButtonBox>
                  <PrimaryButton
                    onClick={handleCreateAgentClick}
                    disabled={!isFormValid()}
                    style={{ width: "100%" }}
                  >
                    Create Agent
                  </PrimaryButton>
                </StyledButtonBox>
              </FormBox>
            </StyledBox>
          </PageContainer>

          <Modal
            open={createAccountModalOpen}
            onClose={() => setCreateAccountModalOpen(false)}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <CreateAccountForm />
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default CreateAgentForm;
