import React from "react";

interface SessionCheckProps {
  children?: React.ReactNode; // Make children prop optional
}

const SessionCheck: React.FC<SessionCheckProps> = ({ children }) => {
  //const sessionExpired = useSelector((state: RootState) => state.session.sessionExpired);

  // if (sessionExpired) {
  // return <Navigate to="/login" replace />;

  // }

  return <>{children}</>; // Ensure React.Fragment is returned to handle potential undefined children
};

export default SessionCheck;
