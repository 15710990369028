import React from 'react';
import { FormControl, Typography, OutlinedInput, SxProps } from '@mui/material';
import { Colors } from '../Colors';

interface TextInputComponentProps {
  label: string;
  labelVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1' | 'body2';
  placeholder: string;
  value: string;
  type?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: string;
  required?: boolean;
  fullWidth?: boolean;
  sx?: SxProps;
  endAdornment?: React.ReactNode;
}

const TextInputComponent: React.FC<TextInputComponentProps> = ({
  label,
  labelVariant,
  placeholder,
  value,
  type,
  onChange,
  helperText,
  required = false,
  fullWidth = true,
  sx = {},
  endAdornment,
}) => {
  return (
    <FormControl fullWidth={fullWidth} sx={{ margin: '10px 0', ...sx }}>
      <Typography variant={labelVariant || "body2"} sx={{ marginBottom: '5px', color: '#E3E5EA', fontWeight: 'bold' }}>
        {label}
      </Typography>
      <OutlinedInput
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        style={{
          borderRadius: '10px',
          fontSize: '14px',
          color: '#FFFFFF',
          backgroundColor: Colors.textfield,
        }}
        required={required}
        endAdornment={endAdornment}
      />
      {helperText && (
        <Typography variant="body2" color={Colors.info} mt={0.5}>
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};

export default TextInputComponent;
