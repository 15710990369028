import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { QuestionAnswerPair } from "../types";

export interface QuestionsAndAnswersState {
  questionsAndAnswers: QuestionAnswerPair[];
  loading: boolean;
  loadingMsg: string | undefined;
  errorMsg: string | undefined;
}

const initialState: QuestionsAndAnswersState = {
  questionsAndAnswers: [],
  loading: false,
  loadingMsg: "",
  errorMsg: "",
};

export const addQuestionsAndAnswers = createAsyncThunk<
  { questionsAndAnswers: QuestionAnswerPair[] },
  { token: string, questionsAndAnswers: QuestionAnswerPair[] },
  { rejectValue: Error }
>(
  "questionsAndAnswers/addQuestionsAndAnswers",
  async ({ token, questionsAndAnswers }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/store`,
        { questionsAndAnswers },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to store questions and answers"));
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to store questions and answers: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const getQuestionsAndAnswers = createAsyncThunk<
  QuestionAnswerPair[],
  { token: string },
  { rejectValue: Error }
>(
  "questionsAndAnswers/getQuestionsAndAnswers",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/store`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to get questions and answers"));
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to get questions and answers: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const updateAnswer = createAsyncThunk<
  QuestionAnswerPair,
  { token: string, pair: QuestionAnswerPair },
  { rejectValue: string }
>(
  'questionsAndAnswers/updateAnswer',
  async ({ token, pair }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/api/store`,
        { pair: pair },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue('Failed to update question');
      }

      dispatch(updateAnswerLocal({ pair: pair }));

      return pair;
    } catch (error) {
      return rejectWithValue(
        "Failed to update question: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error"
      );
    }
  }
);

export const deleteQuestion = createAsyncThunk<
  string,
  { token: string, vectorId: string },
  { rejectValue: Error }
>(
  "questionsAndAnswers/deleteQuestion",
  async ({ token, vectorId }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/store`,
        {
          data: { vectorId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to delete question"));
      }

      return vectorId;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to delete question: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

const questionAndAnswerSlice = createSlice({
  name: "questionAndAnswerSlice",
  initialState,
  reducers: {
    updateAnswerLocal: (
      state,
      action: PayloadAction<{ pair: QuestionAnswerPair }>
    ) => {
      state.questionsAndAnswers = state.questionsAndAnswers.map((pair) => {
        if (pair.id === action.payload.pair.id) {
          return action.payload.pair;
        }
        return pair;
      });
    },
    deleteAnswerLocal: (
      state,
      action: PayloadAction<{ vectorId: string }>
    ) => {
      state.questionsAndAnswers = state.questionsAndAnswers.filter(
        (pair) => pair.id !== action.payload.vectorId
      );
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(addQuestionsAndAnswers.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Adding questions and answers...";
        state.errorMsg = "";
      })
      .addCase(addQuestionsAndAnswers.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.questionsAndAnswers = action.payload.questionsAndAnswers;
      })
      .addCase(addQuestionsAndAnswers.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload?.message;
      })
      .addCase(getQuestionsAndAnswers.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Loading questions and answers...";
        state.errorMsg = "";
      })
      .addCase(getQuestionsAndAnswers.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.questionsAndAnswers = action.payload;
      })
      .addCase(getQuestionsAndAnswers.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload?.message;
      })
      .addCase(updateAnswer.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Updating question...";
        state.errorMsg = "";
      })
      .addCase(updateAnswer.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.questionsAndAnswers = state.questionsAndAnswers.map((pair) => {
          if (pair.id === action.payload.id) {
            return action.payload;
          }
          return pair;
        });
      })
      .addCase(updateAnswer.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload;
      })
      .addCase(deleteQuestion.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Deleting question...";
        state.errorMsg = "";
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.questionsAndAnswers = state.questionsAndAnswers.filter((pair) => pair.id !== action.payload);
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload?.message;
      });
  },
});

export const { updateAnswerLocal, deleteAnswerLocal } = questionAndAnswerSlice.actions;

export const selectQuestionAndAnswerSlice = (state: RootState) => state.questionsAndAnswers;

export default questionAndAnswerSlice.reducer;
