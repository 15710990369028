import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import LoginHeader from "../subcomponents/LoginHeader";
import WalkthroughPage from "../generalPages/WalkthroughPage";
import { ChoiceCardType, PageType, CardTypes, EHR } from "../types";
import TextsmsIcon from "@mui/icons-material/Textsms";
import EmailIcon from "@mui/icons-material/Email";

const cards: ChoiceCardType[] = [
  {
    title: "Healthie",
    description: "Whether it’s a small internal app or a new for millions of customers, our design and development teams.",
    icon: <TextsmsIcon />,
    nextRoute: "/walkthrough/patient-finding",
    type: CardTypes.ehr,
    typeValue: EHR.healthie,
  },
  {
    title: "Other",
    description: "Whether it’s a small internal app or a new for millions of customers, our design and development teams.",
    icon: <EmailIcon />,
    nextRoute: "/walkthrough/patient-finding",
    type: CardTypes.ehr,
    typeValue: EHR.none,
  },
];

const SchedulingSoftwarePage: React.FC = () => {
  const walkthroughUser = useSelector((state: RootState) => state.auth.authUserModel);
  const navigate = useNavigate();

  const handleNext = (nextRoutes: string[]) => {
    nextRoutes.forEach((route) => {
      navigate(route);
    });
  };

  if (!walkthroughUser) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <LoginHeader />
      <WalkthroughPage
        walkthroughProgress={["Scheduling", walkthroughUser.schedulingType ?? ""]}
        title="Which scheduling software do you currently use?"
        selectionType={PageType.single}
        cards={cards}
        onNext={handleNext}
        progress="1/2"
      />
      {/* <Footer /> */}
    </>
  );
};

export default SchedulingSoftwarePage;
