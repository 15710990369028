import React, { useCallback } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { current } from "@reduxjs/toolkit";
import { PrimaryButton } from "../../subcomponents/CustomButton";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Colors } from "../../Colors";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import HealthieAddPatientModal from "../../forms/AddPatientModal";

interface AddPatientProps {
  onClick: () => void;
  onClose: () => void;
  onUpload: (file: File) => void;
}

enum AddPatientStep {
  ChooseMethod = 0,
  BulkUpload = 1,
  IndividualPatient = 2,
}

const AddPatient: React.FC<AddPatientProps> = ({ onClick, onClose, onUpload }) => {
  const [currentStep, setCurrentStep] = React.useState<AddPatientStep>(AddPatientStep.ChooseMethod);

  // Handler for drag and drop
  const handleDrop = useCallback(
    (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const files = event.dataTransfer.files;
      if (files && files.length > 0) {
        const file = files[0];
        onUpload(file);
      }
    },
    [onUpload]
  );

  // Handler for file input change
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files: FileList | null = event.target.files;
      if (files && files.length > 0) {
        const file = files[0];
        onUpload(file);
      }
    },
    [onUpload]
  );

  // Prevent default behavior (Prevent file from being opened)
  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleAddPatients = () => {
    onClick();
  };

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "92%",
        paddingBottom: "20px",
        paddingRight: "20px",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          padding: "12px 25px",
          width: "100%",
          display: "flex",
          borderBottom: "2px solid Colors.textfield",
          background: "#151A33",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 10000,
        }}
      >
        <Box display={"flex"} gap={"10px"}>
          {(currentStep === AddPatientStep.BulkUpload || currentStep === AddPatientStep.IndividualPatient) && (
            <ChevronLeft onClick={() => setCurrentStep(AddPatientStep.ChooseMethod)} style={{ color: Colors.info, cursor: "pointer" }} />
          )}
          <Typography variant={"body2"} fontSize={"1.1rem"} fontWeight={"bold"}>
            Add waitlist patients
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ color: "#FFFFFF" }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {currentStep === AddPatientStep.ChooseMethod && (
        <>
          <Typography variant="body1" fontSize={"1.1rem"} margin={"30px 0 10px 0"}>
            How would you like to add new patients?
          </Typography>

          <Box display="flex" flexDirection="column" gap={2} marginTop={"20px"}>
            {/* <Box 
                            display="flex" 
                            alignItems="center" 
                            gap={2} 
                            onClick={() => setCurrentStep(AddPatientStep.BulkUpload)} 
                            sx={{background: Colors.bg2, padding: '10px', borderRadius: '10px', cursor: 'pointer'}}
                        >                           
                            <FileDownloadOutlinedIcon style={{color: Colors.primary, background: Colors.textfield, borderRadius: '50%', padding: '4px', fontSize: '2rem'}} />

                            <Box>
                                <Typography variant="body1" mb={1} fontWeight={'bold'} fontSize={'0.9rem'}>Bulk upload</Typography>
                                <Typography variant="body2" fontSize={'0.9rem'}>Import patients data from a csv file.</Typography>
                            </Box>

                            <ChevronRight style={{marginLeft: 'auto', color: Colors.info}} />                           
                        </Box> */}

            <Box
              display="flex"
              alignItems="center"
              gap={2}
              onClick={() => setCurrentStep(AddPatientStep.IndividualPatient)}
              sx={{ background: Colors.bg2, padding: "10px", borderRadius: "10px", cursor: "pointer" }}
            >
              <PersonOutlineOutlinedIcon
                style={{ color: Colors.primary, background: Colors.textfield, borderRadius: "50%", padding: "4px", fontSize: "2rem" }}
              />

              <Box>
                <Typography variant="body1" mb={1} fontWeight={"bold"} fontSize={"0.9rem"}>
                  Add individual patient
                </Typography>
                <Typography variant="body2" mb={1.5} fontSize={"0.9rem"}>
                  Make a call to a single patient.
                </Typography>
              </Box>

              <ChevronRight style={{ marginLeft: "auto", color: Colors.info }} />
            </Box>
          </Box>
        </>
      )}

      {currentStep === AddPatientStep.BulkUpload && (
        <Box>
          <Typography variant="body1" fontSize={"1.1rem"} margin={"30px 0 0 0"}>
            Import patients from a csv file
          </Typography>
          <a href="#" style={{ color: Colors.primary, fontSize: "0.85rem" }}>
            Download a sample csv file
          </a>

          <label htmlFor="file-input">
            <input style={{ display: "none" }} id="file-input" type="file" accept=".csv" onChange={handleChange} />
            <Box
              mt={4}
              sx={{
                background: Colors.bg2,
                textAlign: "center",
                padding: "12px",
                borderRadius: "15px",
                border: "1px dashed rgba(255, 255, 255, 0.06)",
                cursor: "pointer",
              }}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
            >
              <FileDownloadOutlinedIcon
                style={{
                  color: Colors.primary,
                  background: Colors.textfield,
                  borderRadius: "50%",
                  padding: "4px",
                  fontSize: "2rem",
                  marginBottom: "5px",
                }}
              />
              <Typography variant="body1" mb={1} fontWeight={"bold"} fontSize={"0.9rem"}>
                Import patients
              </Typography>
              <Typography variant="body2" mb={1.5} fontSize={"0.9rem"}>
                Drag and drop csv file or{" "}
                <a href="#" style={{ color: Colors.primary }}>
                  browse
                </a>
              </Typography>
            </Box>
          </label>
        </Box>
      )}

      {currentStep === AddPatientStep.IndividualPatient && (
        <Box>
          <HealthieAddPatientModal onClick={handleAddPatients} onClose={() => {}} />
        </Box>
      )}
    </Box>
  );
};

export default AddPatient;
