import { CognitoUserPool } from 'amazon-cognito-identity-js';

const userPoolId = process.env.REACT_APP_USER_POOL;
const clientId = process.env.REACT_APP_CLIENT_ID;

if (!userPoolId || !clientId) {
  throw new Error('Cognito User Pool ID and Client ID must be defined');
}

const poolData = {
  UserPoolId: userPoolId,
  ClientId: clientId,
};

export const userPool = new CognitoUserPool(poolData);
