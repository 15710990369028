import * as React from 'react';
import Box from '@mui/material/Box';
import SignoutHeader from '../subcomponents/SignoutHeader';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';

const Help: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box sx={{ marginTop: { xs: "75px", sm: 0 } }}>
      <SignoutHeader />
      <Typography variant='h5' sx={{ color: "#FFF", fontSize: { xs: "1.3rem", sm: "1.8rem" }, margin: { xs: "80px 0 10px 0", lg: "20px 0" } }}>Help</Typography>

      <div 
        className="calendly-inline-widget" 
        data-url="https://calendly.com/penciled/intro?hide_gdpr_banner=1&primary_color=5e78ee" 
        style={{ minWidth: "320px", height: "700px" }}>
      </div>
      <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" async></script>
      
      
      
    </Box>
  );
}

export default Help;
