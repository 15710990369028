import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Box, Typography, Paper, TextField, Button, CircularProgress, Tooltip } from "@mui/material";
import { formatIsoToCustomDateStringWithEEEEHHMMA, textStateColors, textStateIcons } from "../utils/utils";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FrontendText, FrontendUser, Organization, TextThreadMessage, WaitlistSlotState, WaitlistSlotStateChange, WaitlistTextState } from "../types";
import { useSnackbar } from "../providers/SnackbarProvider";
import { RootState } from "../store";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { StyledHeading } from "../Dashboard/WaitlistSlotInfo";
import { StatusMessageVariantOne, StyledToggleButtonVariantOne } from "../styles";
import { Colors } from "../Colors";
import { set } from "date-fns";
import { PrimaryButton } from "../subcomponents/CustomButton";
import SendIcon from "@mui/icons-material/Send";
import { usePostHog } from "posthog-js/react";
import { DateTime } from "luxon";

const Container = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  overflow: hidden;
  padding: 6px 0;
`;

const TextThreadContainer = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 60vh;
  padding: 20px 15px;
  background: ${Colors.bg2};
  border-radius: 15px;
`;

const TextMetadata = styled(Typography)<{ role: string }>`
  font-size: 0.7rem;
  color: ${(props) => (props.role === "agent" ? "lightgrey" : "#aaa")};
  text-align: right;
  display: flex;
  justify-content: flex-end;
`;

interface TextMessageProps {
  role: "agent" | "patient" | "clinic" | "network";
}

const TextMessage = styled(({ role, ...otherProps }: TextMessageProps & React.ComponentProps<typeof Paper>) => <Paper {...otherProps} />)`
  margin-bottom: 10px;
  margin-left: ${(props: any) => (props.role === "agent" || props.role === "network" || props.role === "clinic" || props.role === "tool" ? "auto" : "0px")};
  padding: 10px 15px;
  max-width: 50%; /* Limit the width of the message bubble */
  width: fit-content; /* Dynamically adjust the width */
  background-color: ${(props: any) => (props.role === "agent" || props.role === "clinic" || props.role === "network" ? "#007AFF" : Colors.bgTextMsg)} !important;
  color: #fff !important;
  border-radius: 15px !important;
  box-sizing: border-box; /* Ensure padding is included in the total width */
  word-wrap: break-word; /* Force wrapping for long words */
  overflow-wrap: break-word; /* Handle long, unbreakable strings */
  word-break: break-word; /* Ensure proper wrapping for long words without spaces */
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
`;


const InputContainer = styled(Box)`
  padding: 16px 0;
  position: relative;
`;

const StyledButton = styled(Button)`
  max-height: 60px;
  background-color: #007bff;
  color: #fff;
  margin-left: 8px;
  &:hover {
    background-color: #0056b3;
  }
  &:active {
    background-color: #004494;
  }
  &:disabled {
    background-color: #d6d6d6;
    color: #a0a0a0;
  }
`;

interface TextThreadDisplayProps {
  thread: TextThreadMessage[];
  agentId: string;
  patientId: string;
  waitlistSlotIds: { waitlistSlotId: string; appointmentDate: string }[];
  waitlistSlotStateChanges: WaitlistSlotStateChange[];
}

const TextThreadDisplay: React.FC<TextThreadDisplayProps> = ({ thread, agentId, patientId, waitlistSlotIds, waitlistSlotStateChanges }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  const [threadLoading, setThreadLoading] = useState(false);
  const posthog = usePostHog();
  const userTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { showMessage } = useSnackbar();

  const [newMessage, setNewMessage] = useState("");

  const scrollableRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const scrollableElement = scrollableRef.current;
    if (scrollableElement) {
      scrollableElement.scrollTop = scrollableElement.scrollHeight;
    }
  }, [thread]);

  const handleSendCustomText = async () => {
    if (!user?.userId) {
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/texts/send`,
        {
          patientId: patientId,
          message: newMessage,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response.status === 200) {
        showMessage("Text sent successfully", "success");
      } else {
        throw new Error(`Failed to send custom text to patient`);
      }
    } catch (error: any) {
      if (error.response?.status === 401) {
        showMessage("Session has expired", "error");
      } else {
        console.error("Error sending custom text:", error);
        // showMessage('Failed to send custom text', 'error');
      }
    }
  };

  const handleSendNewMessage = async () => {
    if (newMessage.trim() === "") return;
    setNewMessage("");

    await handleSendCustomText();
  };

  return (
    <>
      <Container>
        <Typography variant={"h6"} sx={{ marginBottom: 1 }}>
          Text conversation
        </Typography>
        {threadLoading ? (
          <LoadingWithMessage message="Loading thread" size={60} />
        ) : (
          <TextThreadContainer ref={scrollableRef}>
      {thread.map((message: TextThreadMessage, index) => {
        const stateChangesForMessage = waitlistSlotStateChanges.filter(
          (stateChange: WaitlistSlotStateChange) =>
            stateChange.textThreadMessageId === message.textThreadMessageId
        );

        return (
          <Box key={index} display="flex" alignItems="center" gap={1}>
            {message.role === "patient" &&
              (() => {
                const filteredStateChanges = stateChangesForMessage
                  .filter(
                    (stateChange) =>
                      stateChange.state === WaitlistSlotState.accepted ||
                      stateChange.state === WaitlistSlotState.declined
                  )
                  .slice(0, 1);

                if (filteredStateChanges.length > 0) {
                  const stateChange = filteredStateChanges[0];
                  const appointment = waitlistSlotIds.find(
                    (slot) => slot.waitlistSlotId === stateChange.waitlistSlotId
                  );
                  const appointmentDate = appointment?.appointmentDate
                    ? formatIsoToCustomDateStringWithEEEEHHMMA(
                        appointment.appointmentDate
                      )
                    : "Unknown date";

                  return (
                    <Tooltip
                      title={`Appointment Date: ${appointmentDate} - ${stateChange.stateJustification}`}
                      onMouseEnter={() => {
                        posthog.capture("[PENCILED] User hovered over state change", {
                          state: stateChange.state,
                          justification: stateChange.stateJustification,
                          waitlistSlotId: stateChange.waitlistSlotId,
                          appointmentDate: appointmentDate,
                          page: window.location.href.includes("patient")
                            ? "PatientInfoPage"
                            : "WaitlistSlotInfo",
                          orgName: organization?.name,
                          organizationId: organization?.organizationId,
                        });
                      }}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        mr={1}
                        width={24}
                        height={24}
                        color={
                          textStateColors[
                            stateChange.state as unknown as keyof typeof textStateColors
                          ]
                        }
                      >
                        {
                          textStateIcons[
                            stateChange.state as unknown as keyof typeof textStateIcons
                          ]
                        }
                      </Box>
                    </Tooltip>
                  );
                } else {
                  return (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      mr={1}
                      width={24}
                      height={24}
                      sx={{ backgroundColor: Colors.bg2 }}
                    />
                  );
                }
              })()}
                <TextMessage role={message.role}>
                  <Typography
                    variant="body1"
                    fontSize={"0.9rem"}
                    mb={1}
                    sx={{
                      wordWrap: "break-word",
                      overflowWrap: "break-word",
                      maxWidth: "70%", // Max width of the bubble to constrain it
                      whiteSpace: "pre-wrap", // Ensure line breaks are respected
                    }}
                  >
                    {message.message}
                  </Typography>
                  { message.date && (
                    <TextMetadata
                      variant="caption"
                      role={message.role}
                      sx={{ color: Colors.grey0 }}
                    >
                      {`${formatIsoToCustomDateStringWithEEEEHHMMA(
                        DateTime.fromISO(message.date).setZone(userTimeZone).toISO()!
                      )} - ${message.role}`}
                    </TextMetadata>
                  )}
                </TextMessage>
          </Box>
        );
      })}
    </TextThreadContainer>

        )}
        <InputContainer
          sx={{
            display: "flex",
            alignItems: "center",
            padding: "0 10px", // Add consistent horizontal padding
            position: "relative",
            paddingTop: "10px",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleSendNewMessage();
              }
            }}
            multiline
            minRows={1}
            maxRows={20}
            sx={{
              "& .MuiInputBase-input": {
                color: "#FFFFFF",
              },
              "& .MuiOutlinedInput-root": {
                borderRadius: "18px",
                backgroundColor: Colors.textfield,
                paddingRight: "60px", // Create space for the send button
              },
            }}
          />

          <PrimaryButton
            variant="contained"
            onClick={() => {
              handleSendNewMessage();
              posthog.capture("[PENCILED] User sent manual text", {
                ...(waitlistSlotIds.length === 1 ? { waitlistSlotId: waitlistSlotIds[0].waitlistSlotId } : {}),
                patientId: patientId,
                agentId: agentId,
                message: newMessage,
                orgName: organization?.name,
                orgId: organization?.organizationId,
              });
            }}
            sx={{
              borderRadius: "18px",
              marginLeft: "10px", // Space between input and button
              height: "fit-content", // Ensures button fits inside the container
            }}
          >
            <SendIcon />
          </PrimaryButton>
        </InputContainer>
      </Container>
    </>
  );
};

export default TextThreadDisplay;
