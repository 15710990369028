import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { AgentTemplate } from "../types";

export interface AgentTemplatesState {
  templates: AgentTemplate[];
  loading: boolean;
  loadingMsg: string | undefined;
  errorMsg: string | undefined;
}

const initialState: AgentTemplatesState = {
  templates: [],
  loading: false,
  loadingMsg: "",
  errorMsg: "",
};

export const getAgentTemplates = createAsyncThunk<AgentTemplate[], { token: string }, { rejectValue: Error }>(
  "agentTemplates/getAgentTemplates",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/templates`, { headers: { Authorization: `Bearer ${token}` } });

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to get agent templates"));
      }

      return response.data;
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to get agent templates: " + (error as any)?.response?.data?.error || (error as Error).message || "Unknown error")
      );
    }
  }
);

const getAgentTemplateSlice = createSlice({
  name: "getAgentTemplateSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAgentTemplates.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Loading agent templates...";
        state.errorMsg = "";
      })
      .addCase(getAgentTemplates.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.templates = action.payload;
      })
      .addCase(getAgentTemplates.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload?.message;
      });
  },
});

export const selectAgentTemplateSlice = (state: RootState) => state.agentTemplates;

export default getAgentTemplateSlice.reducer;
