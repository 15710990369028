import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { usePostHog } from "posthog-js/react";
import CreateAccountForm from "./components/CreateAccountForm";
import VerifyEmailForm from "./components/VerifyEmailForm";
import StartTrialPage from "./components/StartTrialPage";
import LoginHeader from "../subcomponents/LoginHeader";
import { PartialFrontendUser } from "../types";
import { Colors } from "../Colors";
import { PrimaryButton } from "../subcomponents/CustomButton";
import { WhitePenciledLogo } from "../styles/HeaderStyles";
import styled from "styled-components";

const GradientText = styled(Typography)({
    background: 'linear-gradient(90deg, #C5C5C5 0%, #FFFFFF 50%, #818181 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
});

enum CreateAccountStep {
  CreateAccount = 0,
  VerifyEmail = 1,
  WelcomeMessage = 2,
  StartTrial = 3,
}

const SignupWalkthrough: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(CreateAccountStep.CreateAccount);
  const [userData, setUserData] = useState<PartialFrontendUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture("[PENCILED] Create Account page loaded");
  }, [posthog]);

  const handleSuccess = (step: CreateAccountStep) => {
    setCurrentStep(step);
  };

  const handleWelcomeClick = () => {
    setCurrentStep(CreateAccountStep.StartTrial);
  }

  const handleLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

  return (
    <>
      {currentStep === CreateAccountStep.CreateAccount && (
        <>
          <LoginHeader />
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "3vh" }}>
            <Typography variant="body1" sx={{ marginTop: "20px", fontSize: { xs: "1.2rem", sm: "1.5rem" }, color: Colors.grey3, margin: "auto" }}>
              Hey there,
            </Typography>
            <GradientText variant="h4" sx={{ marginBottom: { xs: "15px", md: "20px" }, fontSize: { xs: "1.5rem", sm: "1.8rem" }, margin: "auto" }}>
              Let's get you started!
            </GradientText>
          </Box>
          <CreateAccountForm onSuccess={() => handleSuccess(CreateAccountStep.VerifyEmail)} onUserData={setUserData} onLoading={handleLoading} />
        </>
      )}

      {currentStep === CreateAccountStep.VerifyEmail && userData && (
        <>
          <WhitePenciledLogo style={{ width: "160px", margin: "auto", padding: "35px 5% 0 5%", color: "white" }} />
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "15vh", marginBottom: "25px" }}>
            <GradientText variant="h4" sx={{ margin: "auto", marginBottom: "5px" }}>
              Verify Email
            </GradientText>
            <Typography variant="body1" sx={{ margin: "auto", textAlign: "center", fontSize: "1.05rem" }}>
              Enter the verification code sent to <br /> {userData.email}.
            </Typography>
          </Box>
          <VerifyEmailForm email={userData.email!} userData={userData} onSuccess={() => handleSuccess(CreateAccountStep.WelcomeMessage)} onLoading={handleLoading} />
        </>
      )}

      {currentStep === CreateAccountStep.WelcomeMessage && userData && (
        <>
        <WhitePenciledLogo style={{ width: "160px", margin: "auto", padding: "35px 5% 0 5%", color: "white" }} />
        <Box sx={{ width: { xs: "85%", md: "29%" }, display: "flex", flexDirection: "column", justifyContent: "center", margin: "auto", marginTop: "25vh", marginBottom: "25px" }}>
            <GradientText 
                variant="h4" 
                sx={{ 
                    margin: "auto", 
                    marginBottom: "5px", 
                    fontSize: { xs: "1.8rem", md: "2.1rem" }, 
                }}>
                Welcome onboard, {userData.firstName!}!
            </GradientText>
            <Typography variant="body1" sx={{ margin: "auto", mb: '20px', textAlign: "center", fontSize: "1.05rem" }}>
                Penciled AI voice agents help you reduce no-shows, save lost hours and increase appointment booking rate!
            </Typography>
            <PrimaryButton onClick={handleWelcomeClick}>
                Let's Go
            </PrimaryButton>
        </Box>
        </>
      )}

      {currentStep === CreateAccountStep.StartTrial && (
        <StartTrialPage />
      )}
    </>
  );
};

export default SignupWalkthrough;
