import React, { createContext, useContext, useState, ReactNode, useCallback } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import { Colors } from "../Colors";

interface SnackbarContextType {
  showMessage: (title: string, severity?: "error" | "success", subtitle?: ReactNode) => void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error("useSnackbar must be used within a SnackbarProvider");
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState<ReactNode | undefined>(undefined);
  // TODO (Hizami): figure out what we intend to use this severity for
  // eslint-disable-next-line
  const [severity, setSeverity] = useState<"error" | "success">("success");

  const showMessage = useCallback((title: string, severity: "error" | "success" = "success", subtitle: ReactNode = "") => {
    setTitle(title);
    setSubtitle(subtitle);
    setSeverity(severity);
    setOpen(true);
  }, []);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose} style={{ position: "absolute", top: "10px", right: "5px" }}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  const snackbarStyle = {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#151A32",
    color: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    padding: "15px 25px",
    maxWidth: "800px",
    minWidth: "350px",
  };

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        sx={{ padding: 0 }}
        ContentProps={{
          sx: {
            backgroundColor: "transparent",
            padding: 0,
            boxShadow: "none",
          },
        }}
        message={
          <Box sx={snackbarStyle}>
            <Box sx={{ flexGrow: 1 }}>
              <span style={{ display: "flex", gap: "10px" }}>
                {severity === "error" ? (
                  <WarningIcon sx={{ color: Colors.error }} />
                ) : (
                  <CheckOutlinedIcon
                    style={{
                      fontSize: "1.2rem",
                      color: "#151A32",
                      background: Colors.success,
                      borderRadius: "50%",
                      padding: "3px",
                      marginRight: "10px",
                    }}
                  />
                )}

                <span>
                  <Typography variant="body1" fontWeight="bold">
                    {title}
                  </Typography>
                  {subtitle && (
                    <Typography variant="body1" sx={{ color: "#FFF" }}>
                      {subtitle}
                    </Typography>
                  )}
                </span>
              </span>
            </Box>
            {action}
          </Box>
        }
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </SnackbarContext.Provider>
  );
};
