import React from "react";
import LoginHeader from "../subcomponents/LoginHeader";
import CreateAgentForm from "../forms/CreateAgentForm";

const AgentInfo: React.FC = () => {
  // const walkthroughUser = useSelector((state: RootState) => state.auth.authUserModel);

  // const walkthroughProgress = ["Scheduling", walkthroughUser.schedulingType, walkthroughUser.schedulingSoftware, walkthroughUser.patientFinding];

  return (
    // <>
    // {walkthroughUser ? (
    <>
      <LoginHeader />
      {/* <WalkthroughProgress walkthroughProgress={["Scheduling", walkthroughUser.schedulingType ?? "", walkthroughUser.schedulingSoftware ?? "", walkthroughUser.patientFinding ?? "", walkthroughUser.patientInfoRetrieval ?? ""]} /> */}
      <CreateAgentForm />
      {/* <Footer /> */}
    </>
    //   ): (
    //   <></>
    //   )}
    // </>
  );
};

export default AgentInfo;
