import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { Colors } from "../Colors";
import { StyledFormBox, StyledPasswordTextField, StyledProgressContainer, TextFieldWithBottomPadding } from "../styles";
import LoadingWithMessage from "./LoadingWithMessage";
import { EHR, FrontendUser, HealthieProvider } from "../types";
import { convertSnakeCaseToOfficial, encryptTokenWithPublicKey } from "../utils/utils";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store";
import { useSnackbar } from "../providers/SnackbarProvider";
import { PrimaryButton } from "./CustomButton";
import { clearIntegration, fetchAppointments, fetchProviders } from "../slices/HealthieIntegrationSlice";
import { usePostHog } from "posthog-js/react";

interface SignupCardProps {
  ehr: EHR;
  logo: string;
  handleSubmit: (providerId: string, appointmentId: string) => void;
}

const SignupIntegrationCard: React.FC<SignupCardProps> = ({ ehr, logo, handleSubmit }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [token, setToken] = useState<string>("");
  const [showAestheticField, setShowAestheticField] = useState<boolean>(true);
  const { showMessage } = useSnackbar();
  const [loadingDeletingIntegration, setLoadingDeletingIntegration] = useState<boolean>(false);
  const [loadingUploadingToken, setLoadingUploadingToken] = useState<boolean>(false);
  const posthog = usePostHog();

  const [selectedProvider, setSelectedProvider] = useState<string>("");
  const [selectedAppointment, setSelectedAppointment] = useState<string>("");
  const providers = useSelector((state: RootState) => state.healthieIntegration.providers);
  const appointments = useSelector((state: RootState) => state.healthieIntegration.appointments);
  const loadingAppointments = useSelector((state: RootState) => state.healthieIntegration.loadingAppointments);
  const loadingProviders = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const [integrated, setIntegrated] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (integrated && user?.token) {
      dispatch(fetchProviders({ token: user.token }));
      dispatch(fetchAppointments({ token: user.token }));
    }
  }, [integrated]);

  const handleBlur = () => {
    setShowAestheticField(true);
  };

  const handleProviderChange = (event: SelectChangeEvent) => {
    posthog?.capture("[PENCILED] Provider's name selected from dropdown", { schedulingSoftware: ehr });
    const providerId = event.target.value;
    setSelectedProvider(providerId);
  };

  const handleAppointmentChange = (event: SelectChangeEvent) => {
    posthog?.capture("[PENCILED] Appointment type selected from dropdown", { schedulingSoftware: ehr });
    const appointmentId = event.target.value;
    setSelectedAppointment(appointmentId);
  };

  const handleTokenUpload = async (token: string) => {
    setLoadingUploadingToken(true);
    try {
      if (!token) {
        console.error("Token is empty");
        return;
      }

      if (!user?.token) {
        return;
      }

      const encryptedIntegrationToken: string = await encryptTokenWithPublicKey(token);

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/integrations/${ehr}`,
        {
          encryptedIntegrationToken: encryptedIntegrationToken,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response.status === 200) {
        setToken("");
        setIntegrated(true);
        // dispatch(getSession(user.token));
        showMessage(`Securely uploaded ${convertSnakeCaseToOfficial(ehr)} token.`);
      } else {
        showMessage(`Failed to upload ${ehr} token. Please refresh the page and try again.`);
      }
    } catch (error) {
      // TODO: handle token expiration
      console.error(`Error updating ${ehr} token:`, error);
      showMessage(`Failed to upload ${ehr} token. Please refresh the page and try again.`);
    } finally {
      setLoadingUploadingToken(false);
    }
  };

  const handleDeleteIntegration = async () => {
    setLoadingDeletingIntegration(true);
    try {
      if (!user?.token) {
        throw new Error("User not found");
      }

      const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/integrations/${ehr}`, {
        headers: {
          Authorization: `Bearer ${user?.token}`,
        },
      });

      if (response.status === 200) {
        showMessage(`Successfully disconnected from ${ehr}`);
        setToken("");
        setSelectedProvider("");
        setSelectedAppointment("");
        dispatch(clearIntegration());
        // dispatch(getSession(user.token));
      } else {
        showMessage(`Failed to disconnect from ${ehr}. Please refresh the page and try again.`);
      }
    } catch (error) {
      console.error(`Error disconnecting from ${ehr}:`, error);
      showMessage(`Failed to disconnect from ${ehr}. Please refresh the page and try again.`);
    } finally {
      setLoadingDeletingIntegration(false);
    }
  };

  const handleFocus = () => {
    setShowAestheticField(false); // Switch to editable TextField on focus
  };

  const handleTokenChange = (value: string) => {
    setToken(value);
    if (value === "") {
      setShowAestheticField(true); // Switch back to aesthetic field when text is cleared
    }
  };

  const LoadingMessage: React.FC<{ message: string }> = ({ message }) => (
    <StyledProgressContainer>
      <LoadingWithMessage message={message} size={12} customStyles={{ fontSize: "1rem" }} />
    </StyledProgressContainer>
  );

  return (
    <>
      <Box
        sx={{
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.2)",
          border: "1px solid rgba(0, 0, 0, 0.1)",
          padding: "25px",
          width: "30%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "15px",
          margin: "auto",

          "@media (max-width: 1100px)": {
            width: "40%",
          },

          "@media (max-width: 850px)": {
            width: "50%",
          },

          "@media (max-width: 480px)": {
            width: "80%",
          },
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between", gap: "15px", alignItems: "center" }}>
          <Typography variant="h6" fontWeight="bold">
            {convertSnakeCaseToOfficial(ehr)}
          </Typography>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src={logo} alt={`${convertSnakeCaseToOfficial(ehr)} logo`} style={{ marginTop: "20px", width: "250px", height: "auto" }} />
        </div>
        <br />
        {loadingDeletingIntegration && <LoadingMessage message="Disconnecting..." />}
        {loadingUploadingToken && <LoadingMessage message="Uploading token..." />}
        {loadingProviders && !loadingDeletingIntegration && !loadingUploadingToken && <LoadingWithMessage message="Loading providers..." size={12} />}
        {loadingAppointments && !loadingDeletingIntegration && !loadingUploadingToken && (
          <LoadingWithMessage message="Loading appointments..." size={12} />
        )}
        {!loadingProviders && !loadingAppointments && !integrated && (
          <>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
              <TextFieldWithBottomPadding
                label={`${convertSnakeCaseToOfficial(ehr)}` + " API token"}
                variant="outlined"
                value={token}
                onChange={(e: any) => setToken(e.target.value)}
                fullWidth
              />
              <PrimaryButton
                onClick={() => {
                  posthog?.capture("[PENCILED] Integrations button submitted", { schedulingSoftware: ehr, action: "submit" });
                  handleTokenUpload(token);
                }}
              >
                Submit Token
              </PrimaryButton>
            </Box>
          </>
        )}
        {!loadingAppointments && !loadingProviders && integrated && (
          <>
            {showAestheticField ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    gap: "10px",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  <StyledPasswordTextField
                    variant="outlined"
                    type="text"
                    placeholder="●●●●●●●●"
                    onFocus={handleFocus}
                    sx={{ width: "100%", fontSize: "1rem" }}
                  />
                  <PrimaryButton
                    onClick={() => {
                      posthog?.capture("[PENCILED] Integrations 'Disconnect' button clicked", { schedulingSoftware: ehr });
                      handleDeleteIntegration;
                    }}
                    style={{ backgroundColor: Colors.error, padding: "10px 20px", width: "100%", fontSize: "0.75rem" }}
                  >
                    Disconnect
                  </PrimaryButton>
                </Box>
                {!loadingAppointments && !loadingProviders && providers.length > 0 && (
                  <StyledFormBox sx={{ minWidth: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel id="select-provider-name-label">Provider name</InputLabel>
                      <Select
                        labelId="select-provider-name-label"
                        id="select-provider-name"
                        value={selectedProvider || ""}
                        label="Provider name"
                        onChange={handleProviderChange}
                      >
                        {providers.map((provider: HealthieProvider) => (
                          <MenuItem key={provider.id} value={provider.id}>
                            {provider.firstName} {provider.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyledFormBox>
                )}
                {!loadingAppointments && !loadingProviders && providers.length === 0 && (
                  <Typography variant="body2" sx={{ color: Colors.grey1, fontWeight: "bold", marginBottom: "15px" }}>
                    No providers found. Please add a provider in Healthie.
                  </Typography>
                )}
                {selectedProvider && appointments.length > 0 && (
                  <StyledFormBox sx={{ minWidth: "100%" }}>
                    <FormControl fullWidth>
                      <InputLabel id="select-appointment-type-label">Appointment Type</InputLabel>
                      <Select
                        labelId="select-appointment-type-label"
                        id="select-appointment-type"
                        value={selectedAppointment || ""}
                        label="Appointment type"
                        onChange={handleAppointmentChange}
                      >
                        {appointments.map((appointment) => (
                          <MenuItem key={appointment.id} value={appointment.id}>
                            {appointment.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </StyledFormBox>
                )}
                {selectedProvider && appointments.length === 0 && (
                  <Typography variant="body2" sx={{ color: Colors.grey1, fontWeight: "bold", marginBottom: "15px" }}>
                    No appointment types found. Please add an appointment type in Healthie.
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", gap: "10px" }}>
                  <TextFieldWithBottomPadding
                    label={`${convertSnakeCaseToOfficial(ehr)}` + " API token"}
                    variant="outlined"
                    value={token}
                    onChange={(e) => handleTokenChange(e.target.value)}
                    fullWidth
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                  <PrimaryButton
                    onClick={() => {
                      posthog?.capture("[[PENCILED] Integrations button submitted", { schedulingSoftware: ehr, action: "update" });
                      handleTokenUpload(token);
                    }}
                  >
                    Update token
                  </PrimaryButton>
                </Box>
              </>
            )}
            {selectedProvider && selectedAppointment && (
              <PrimaryButton
                onClick={() => {
                  posthog?.capture("[PENCILED] Create Agent button clicked", { schedulingSoftware: ehr, ehrTokenBranch: "yes" });
                  handleSubmit(selectedProvider, selectedAppointment);
                }}
                sx={{ width: "100%", marginTop: "10px" }}
              >
                Create Agent
              </PrimaryButton>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default SignupIntegrationCard;
