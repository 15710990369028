import styled from 'styled-components';
import { ReactComponent as PenciledTextLogo } from "../assets/logo-primary.svg";
import { ReactComponent as PenciledLogo } from "../assets/logo-white.svg";

export const StyledPenciledTextLogo = styled(PenciledTextLogo)`
  width: 160px;
  height: auto;

  @media (max-width: 600px) {
    width: 135px;
  }
`;

export const WhitePenciledLogo = styled(PenciledLogo)` 
  width: 100%;
  height: auto;
`;