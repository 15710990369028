import { DateTime } from "luxon";
import { CSVPatient } from "../types";
import { RootState } from "../store";
import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { systemTimezone } from "../utils/timeUtils";
import axios from "axios";

const defaultTimeAheadToSchedule = 5;

export interface UploadCSVState {
  patients: CSVPatient[];
  patientPhoneNumbersToScheduledDate: Record<string, string | null>;
  overallSchedule: string;
  patientPhoneNumbersToOverallSchedule: Record<string, boolean>;
  uploadCSVLoading: boolean;
  uploadCSVErrorMsg?: string;
}

const initialState: UploadCSVState = {
  patients: [],
  patientPhoneNumbersToScheduledDate: {},
  patientPhoneNumbersToOverallSchedule: {},
  overallSchedule: DateTime.now().plus({ minutes: defaultTimeAheadToSchedule }).toISO(),
  uploadCSVLoading: false,
};

export const handleDateChange = createAsyncThunk(
  'uploadCSV/handleDateChange',
  async ({ patient, date }: { patient: CSVPatient, date: string }, { getState, dispatch }) => {
    const state = getState() as RootState;
    const patientPhoneNumbersToScheduledDate = state.uploadCSV.patientPhoneNumbersToScheduledDate;

    if (!date) return;

    if (!patientPhoneNumbersToScheduledDate || !patientPhoneNumbersToScheduledDate[patient.patientPhoneNumber]) {
      throw new Error("Error setting scheduled date");
    }
    if (!date) throw new Error("Date is required");

    const scheduledDate: DateTime = DateTime.fromISO(date);

    if (!scheduledDate.isValid) {
      throw new Error("Invalid date");
    }

    if (scheduledDate < DateTime.now()) {
      throw new Error("Scheduled date cannot be in the past");
    }

    const newScheduledDate = { ...patientPhoneNumbersToScheduledDate, [patient.patientPhoneNumber]: scheduledDate.set({ second: 0, millisecond: 0 }).toISO()! };
    if (!newScheduledDate || !newScheduledDate[patient.patientPhoneNumber]) {
      throw new Error("Error setting scheduled date");
    }
    dispatch(setPatientPhoneNumbersToScheduledDate(newScheduledDate));
  }
);



export const handleOverallScheduleChange = createAsyncThunk(
  'uploadCSV/handleOverallScheduleChange',
  async (date: DateTime | null, { dispatch }) => {
    if (!date || !date.toISO()) return;

    if (date < DateTime.now().set({ hour: 0, minute: 0, second: 0, millisecond: 0 })) {
      throw new Error("Scheduled date cannot be in the past");
    }

    dispatch(setOverallSchedule(date.toISO()!));
    return date.toISO()!;
  }
);

export const handleRowToggle = createAsyncThunk(
  'uploadCSV/handleRowToggle',
  async (patient: CSVPatient, { getState, dispatch }) => {
    const state = getState() as RootState;
    const { patientPhoneNumbersToOverallSchedule } = state.uploadCSV;

    const newPatientPhoneNumbersToOverallSchedule = {
      ...patientPhoneNumbersToOverallSchedule,
      [patient.patientPhoneNumber]: !patientPhoneNumbersToOverallSchedule[patient.patientPhoneNumber]
    };

    dispatch(setPatientPhoneNumbersToOverallSchedule(newPatientPhoneNumbersToOverallSchedule));
  }
);

export const sendPatientsToServer = createAsyncThunk<
  {},
  { token: string, agentId: string },
  { rejectValue: Error }
>(
  "uploadCSV/sendPatientsToServer", // Ensure the prefix matches the slice name
  async ({ token, agentId }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/calls/schedule/csv`, {
        patients: state.uploadCSV.patients.map((patient: CSVPatient) => {
          return {
            patientFirstName: patient.patientFirstName,
            patientLastName: patient.patientLastName,
            patientPhoneNumber: patient.patientPhoneNumber,
            scheduledDate: state.uploadCSV.patientPhoneNumbersToScheduledDate[patient.patientPhoneNumber],
          };
        }),
        agentId: agentId
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to send patients to server"));
      }

      return;

    } catch (error) {
      return rejectWithValue(new Error("Failed to upload CSV patients: " + (error as Error).message));
    }
  }
);

const uploadCSVSlice = createSlice({
  name: "uploadCSV",
  initialState,
  reducers: {
    setPatients: (state, action: PayloadAction<CSVPatient[]>) => {
      state.patients = action.payload;

      // set every patient to not tracking the main scheduler initially
      for (const patient of action.payload) {
        state.patientPhoneNumbersToOverallSchedule[patient.patientPhoneNumber] = false;
        state.patientPhoneNumbersToScheduledDate[patient.patientPhoneNumber] = DateTime.now().plus({ minutes: defaultTimeAheadToSchedule }).toISO();
        // state.patientPhoneNumbersToScheduledDate[patient.patientPhoneNumber] = null;
      }
    },
    setPatientPhoneNumbersToScheduledDate: (state, action: PayloadAction<Record<string, string | null>>) => {
      state.patientPhoneNumbersToScheduledDate = action.payload;
    },
    setOverallSchedule: (state, action: PayloadAction<string>) => {
      state.overallSchedule = action.payload;
    },
    setPatientPhoneNumbersToOverallSchedule: (state, action: PayloadAction<Record<string, boolean>>) => {
      state.patientPhoneNumbersToOverallSchedule = action.payload;
    },
    clearError: (state) => {
      state.uploadCSVErrorMsg = undefined;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(handleDateChange.fulfilled, (state) => {
      state.uploadCSVErrorMsg = undefined;
      return state;
    });
    builder.addCase(handleOverallScheduleChange.fulfilled, (state) => {
      state.uploadCSVErrorMsg = undefined;
      return state;
    });
    builder.addCase(handleRowToggle.fulfilled, (state) => state);
    
    builder.addCase(sendPatientsToServer.pending, (state) => {
      state.uploadCSVLoading = true;
    });
    builder.addCase(handleDateChange.rejected, (state, action) => {
      state.uploadCSVErrorMsg = action.error.message ?? "Failed to change date";
    });
    builder.addCase(handleOverallScheduleChange.rejected, (state, action) => {
      state.uploadCSVErrorMsg = action.error.message ?? "Failed to change overall schedule";
    });
    builder.addCase(sendPatientsToServer.fulfilled, (state) => {
      state.uploadCSVLoading = false;
      state.patients = [];
      state.patientPhoneNumbersToScheduledDate = {};
      state.patientPhoneNumbersToOverallSchedule = {};
    });
    builder.addCase(sendPatientsToServer.rejected, (state) => {
      state.uploadCSVLoading = false;
      state.uploadCSVErrorMsg = "Failed to upload CSV patients";
    });
  },
});


export const {
  setPatients,
  setPatientPhoneNumbersToScheduledDate,
  setOverallSchedule,
  setPatientPhoneNumbersToOverallSchedule,
} = uploadCSVSlice.actions;

export const selectUploadCSV = (state: RootState) => state.uploadCSV;

export default uploadCSVSlice.reducer;
