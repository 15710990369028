import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { HealthieAppointment, RawHealthiePatient, HealthieProvider } from "../types";
import { sessionExpired } from "./SessionSlice";
import { HealthieIntegrationState } from "./HealthieIntegrationSlice";

const initialState: HealthieIntegrationState = {
  providers: [],
  appointments: [],
  contactTypes: ["In Person", "Healthie Video Call", "Secure Videochat", "Phone Call"],
  patientMap: {},
  patientSearches: [],
  validPatientSearches: [],
  invalidPatientSearches: [],
  error: null,
  loadingUploadingToken: false,
  loadingDeletingIntegration: false,
  loadingProviders: false,
  loadingAppointments: false,
  loadingPatients: false,
};

export function convertToHealthieProvidersList(providers: { id: string; name: string }[]): HealthieProvider[] {
    return providers.map(provider => {
        let [firstName, lastName] = provider.name.split(" "); // Assumes name is "First Last"
        lastName = lastName.replace(/,\s*$/, "");
        return {
            type: "provider", // Assuming a default value for type
            id: provider.id,
            firstName: firstName || "", // Assigns first name, or empty if missing
            lastName: lastName || "", // Assigns last name, or empty if missing
            email: "", // Default empty string; replace with actual if available
            phoneNumber: "", // Default empty string; replace with actual if available
            brandName: "" // Default empty string; replace with actual if available
        };
    });
}

export const fetchPteverywhereProviders = createAsyncThunk<
  { providers: HealthieProvider[] },
  { token: string },
  { rejectValue: Error }
>(
  "/integrations/fetchProviders",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/pteverywhere/providers`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.data) {
        throw new Error("No data received");
      }

      return { providers: convertToHealthieProvidersList(response.data) };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(
        new Error("Failed to fetch providers: " + (error as Error).message)
      );
    }
  }
);

export const fetchPteverywhereAppointments = createAsyncThunk<
  { appointments: HealthieAppointment[] },
  { token: string },
  { rejectValue: Error }
>(
  "integrations/fetchAppointments",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/pteverywhere/appointmentTypes`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return { appointments: response.data as HealthieAppointment[] };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(
        new Error("Failed to fetch appointments: " + (error as Error).message)
      );
    }
  }
);

export const fetchPteverywherePatients = createAsyncThunk<
  { patients: RawHealthiePatient[] },
  { token: string; keywords: string },
  { rejectValue: Error }
>(
  "/integrations/fetchPatients",
  async ({ token, keywords }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/pteverywhere/patients`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            keywords,
          },
        }
      );

      if (!response.data) {
        throw new Error("No data received");
      }

      return { patients: response.data };
    } catch (error) {
      if ((error as AxiosError).response?.status === 401) {
        sessionExpired(true);
      }
      return rejectWithValue(
        new Error("Failed to fetch patients: " + (error as Error).message)
      );
    }
  }
);

const pteverywhereIntegrationSlice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    clearIntegration: (state) => {
      state.providers = [];
      state.appointments = [];
      state.error = null;
      state.loadingProviders = false;
      state.loadingAppointments = false;
      state.loadingPatients = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPteverywhereProviders.pending, (state) => {
        state.loadingProviders = true;
      })
      .addCase(fetchPteverywhereProviders.fulfilled, (state, action) => {
        state.providers = action.payload.providers;
        state.loadingProviders = false;
      })
      .addCase(fetchPteverywhereProviders.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch providers";
        state.loadingProviders = false;
      })
        .addCase(fetchPteverywhereAppointments.pending, (state) => {
        state.loadingAppointments = true;
      })
      .addCase(fetchPteverywhereAppointments.fulfilled, (state, action) => {
        state.appointments = action.payload.appointments;
        state.loadingAppointments = false;
      })
      .addCase(fetchPteverywhereAppointments.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch appointments";
        state.loadingAppointments = false;
      })
      .addCase(fetchPteverywherePatients.pending, (state, action) => {
        state.patientSearches.push(action.meta.arg.keywords);
        state.loadingPatients = true;
      })
      .addCase(fetchPteverywherePatients.fulfilled, (state, action) => {
        action.payload.patients.forEach((patient) => {
          state.patientMap[patient.healthiePatientId] = patient;
        });
        state.validPatientSearches.push(action.meta.arg.keywords);
        state.loadingPatients = false;
      })
      .addCase(fetchPteverywherePatients.rejected, (state, action) => {
        state.error = action.error.message || "Failed to fetch patients";
        state.loadingPatients = false;
        state.invalidPatientSearches.push(action.meta.arg.keywords);
      })
  },
});

export default pteverywhereIntegrationSlice.reducer;