import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import AudioScrubber from "./AudioScrubber";
import { Colors } from "../Colors";

// icons

import { useSelector } from "react-redux";
import { useSnackbar } from "../providers/SnackbarProvider";
import { RootState } from "../store";
import { FrontendUser } from "../types";

const AudioComponent: React.FC<{ audioURL: string }> = ({ audioURL }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const [audioLoading, setAudioLoading] = useState(false);
  const { showMessage } = useSnackbar();
  const audioContextRef = useRef<AudioContext>(new AudioContext());
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);

  useEffect(() => {
    loadAudio("", audioContextRef.current, setAudioBuffer);
  }, []);

  type SetAudioBuffer = React.Dispatch<React.SetStateAction<AudioBuffer | null>>;

  const loadAudio = async (range: string, audioContext: AudioContext, setAudioBuffer: SetAudioBuffer) => {
    try {
      setAudioLoading(true);
      // Create an instance of Headers to append our custom headers
      const headers = new Headers();
      headers.append("Authorization", `Bearer ${user?.token}`);
      headers.append("user_id", user?.userId || "");
      if (range) {
        headers.append("Range", range);
      }

      // Fetch the audio file from the server
      const response = await fetch(audioURL, { headers });
      const arrayBuffer = await response.arrayBuffer();

      // Decode the audio data and set up the audio context
      const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

      // Use a setter function to update the audio buffer state if using React, or handle accordingly
      setAudioBuffer(audioBuffer);
    } catch (error) {
      console.error("Failed to load and play audio:", error);
      showMessage("Failed to load and play audio. Please try again later.", "error");
    } finally {
      setAudioLoading(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          padding: 1,
          maxWidth: "100%",
          alignSelf: "center",
          backgroundColor: Colors.bg2,
          borderRadius: "25px",
          margin: "16px 0",
        }}
      >
        {audioLoading && <CircularProgress />}
        {!audioLoading && audioBuffer && <AudioScrubber audioBuffer={audioBuffer} audioContext={audioContextRef.current} />}
      </Box>
    </>
  );
};

export default AudioComponent;
