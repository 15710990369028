import React, { useEffect, useRef, useState } from 'react';
import { Box, Modal, Typography, FormControl, OutlinedInput, Button, CircularProgress } from '@mui/material';
import { PrimaryButton, TransparentButton } from '../subcomponents/CustomButton'; // Update the import path as needed
import { FrontendUser } from '../types';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { DateTime } from 'luxon';

interface AddApiTokenModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
}

const AddApiTokenModal: React.FC<AddApiTokenModalProps> = ({ open, onClose, onSubmit }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const loading: boolean = useSelector((state: RootState) => state.apiKeys.loading);
  const errorMsg: string | undefined = useSelector((state: RootState) => state.apiKeys.errorMsg);
  const [name, setName] = useState(user?.firstName ? `${user.firstName}'s API Key ${DateTime.now().toFormat("M/d/yy")}` : '');
  const [localErrorMsg, setLocalErrorMsg] = useState<string | null>(null);
  const wasLoading = useRef(false);

  const handleSubmit = () => {
    if (!name) {
      setLocalErrorMsg("Name is required");
      return;
    }

    onSubmit(name);
  };

  // when loading and switching to not loading, close the modal
  useEffect(() => {
    if (wasLoading.current && !loading) {
      setName('');
      onClose();
    }
    wasLoading.current = loading;
  }, [loading, onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ overflowY: "auto", height: "100%" }}>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxWidth: 500,
            margin: '100px auto',
          }}
        >
          <Typography variant="h4" fontWeight="bold" marginBottom="30px" sx={{ fontSize: { xs: "1.6rem", sm: "2rem" } }}>
            Add API Token
          </Typography>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>Token Name</Typography>
            <OutlinedInput
              placeholder="My API Key"
              style={{ borderRadius: "10px", fontSize: "14px" }}
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </FormControl>

          { (localErrorMsg || errorMsg) && <Typography color="error">{localErrorMsg || errorMsg}</Typography> }

          <Box sx={{ mt: 3, display: "flex", justifyContent: "right" }}>
            <PrimaryButton onClick={handleSubmit} variant="contained" color="primary" fullWidth={false}>
              {loading ? <CircularProgress size={24} color="inherit" /> : "Add Token"}
            </PrimaryButton>
            
            <TransparentButton onClick={onClose} variant="outlined" color="primary" fullWidth={false} sx={{ marginLeft: "10px" }}>
              Cancel
            </TransparentButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddApiTokenModal;
