import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SignoutHeader from '../subcomponents/SignoutHeader';
import { Colors } from '../Colors';

const HIPAA: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <SignoutHeader />
      <h2>HIPAA</h2>
      <Box sx={{ padding: '10px 25px', backgroundColor: '#FFF', height: '250px', width: '300px', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
        <h2 style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '8px' }}>Business Associate Agreement (BAA)</h2>
        <div style={{ backgroundColor: '#EE2626', color: 'white', padding: '3px 6px', width: '85px', fontSize: '0.75rem', textAlign: 'center', borderRadius: '30px' }}>Not signed</div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
          <Box
          component="button"
          onClick={handleClickOpen}
          sx={{
            backgroundColor: Colors.primary,
            borderRadius: '10px',
            border: 'none',
            padding: '15px',
            fontSize: '1rem',
            color: 'white',
            fontWeight: 'bold',
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: '#4a66cc',
            },
          }}
        >
          Sign BAA
        </Box>
        </div>
      </Box>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Sign BAA</DialogTitle>
        <DialogContent>
          <a href="https://developers.docusign.com/docs/esign-rest-api/how-to/request-signature-in-app-embedded/" target="_blank" rel="noopener noreferrer">
            https://developers.docusign.com/docs/esign-rest-api/how-to/request-signature-in-app-embedded/
          </a>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default HIPAA;
