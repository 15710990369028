import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { DateTimeObject } from '../components/calendar/utils/types';
import Calendar from '../components/calendar/Calendar';
import { CalendarProvider } from "../components/calendar/utils/hooks/useCalendarContext";
import { WhitePenciledLogo } from "../styles/HeaderStyles";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { AvailableDate, FrontendUser } from '../types';
import { RootState } from '../store';
import Loader from '../subcomponents/Loader';
import { DateTime } from 'luxon';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { Colors } from '../Colors';

const timezoneObject = {
  name: Intl.DateTimeFormat().resolvedOptions().timeZone,
  offset: new Date().getTimezoneOffset(),
};

function convertToDateTimeObject(dates: AvailableDate[]): DateTimeObject[] {
  return dates.map((date) => {
    if (typeof date === "string") {
      const dateStr = DateTime.fromISO(date).toFormat("EEE MMM dd yyyy");
      return { date: dateStr, time: { start: "00:00", end: "00:00" }, timezone: timezoneObject };
    }

    const dateStr = DateTime.fromISO(date.start).toFormat("EEE MMM dd yyyy");

    return {
      date: dateStr,
      time: { start: DateTime.fromISO(date.start).toFormat("HH:mm"), end: DateTime.fromISO(date.end).toFormat("HH:mm") },
      timezone: timezoneObject,
    };
  });
}

function convertToAvailableDate(dateTimes: DateTimeObject[]) {
  return dateTimes.map((dateTime) => {
    const { date, time, timezone } = dateTime;
    if (time.start === "00:00" && time.end === "00:00") {
      return DateTime.fromFormat(date, "EEE MMM dd yyyy").toISO() || "";
    }

    const start = DateTime.fromFormat(`${date} ${time.start}`, "EEE MMM dd yyyy HH:mm", { zone: timezone.name }).toISO() || "";
    const end = DateTime.fromFormat(`${date} ${time.end}`, "EEE MMM dd yyyy HH:mm", { zone: timezone.name }).toISO() || "";
    return { start, end };
  })
}

const PatientAvailability: React.FC = () => {
  const { uniqueId } = useParams<{ uniqueId: string }>();
  const [dates, setDates] = useState<DateTimeObject[]>([]);
  const [initialDates, setInitialDates] = useState<DateTimeObject[]>([]);
  const [loading, setLoading] = useState(true);
  const [validId, setValidId] = useState(false);
  const [submitState, setSubmitState] = useState("initial");

  useEffect(() => {
      const fetchAvailability = async () => {
        try {
          setLoading(true);
          const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/availability?unique_id=${uniqueId}`);
          if (response.data.uniqueId === uniqueId) {
            setValidId(true);
            const convertedDates = convertToDateTimeObject(response.data.dates);
            setDates(convertedDates);
            setInitialDates(convertedDates);
          }
        } catch (error) {
          console.error("Error fetching availability:", error);
        } finally {
          setLoading(false);
        }
      }
      if (uniqueId) {
        fetchAvailability();
      }
  }, [uniqueId]);

  useEffect(() => {
    if (submitState === "pending") {
      axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/availability`, {
        unique_id: uniqueId,
        dates: convertToAvailableDate(dates),
      }).then(() => {
        setSubmitState("success");
      }).catch((error) => {
        console.error("Error submitting availability:", error);
        setSubmitState("error");
      });
    }
  }, [dates, submitState]);

  if (loading) {
    return <Loader message="" size={60} />
  } else if (validId) {
    if (submitState === "pending") {
      return <Loader message="Updating availability..." size={60} />
    } else if (submitState === "success") {
      return (
        <>
          <WhitePenciledLogo style={{ width: "150px", margin: "20px 15px", position: "absolute", top: "10px", left: "10px" }} />
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', justifyContent: 'center', alignItems: 'center', height: '100dvh', margin: { xs: "0px 24px"}, textAlign: "center" }}>
            <Box>
              <EventAvailableIcon sx={{ fontSize: "5rem", color: "#00AC4F", background: "#D3FFE7", padding: "20px", borderRadius: "50%" }} />
            </Box>
            <Typography variant="h1" sx={{ fontSize: { xs: '2.25rem', sm: '2.5rem' }, color: '#FFF' }}>
              Availability updated!
            </Typography>
            <Typography variant="body1" sx={{ fontSize: { xs: '1rem' }, color: '#FFF', maxWidth: "600px" }}>
              Your availability has been updated. You can now close this window. Please request a new link if you want to update your availability again.
            </Typography>
          </Box>
        </>
      )
    }
    return (
      <>
      <WhitePenciledLogo style={{ width: "150px", margin: "20px 15px", position: "absolute", top: "10px", left: "10px" }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', justifyContent: 'center', alignItems: 'center', height: '100dvh', margin: { xs: "0px 24px"}, textWrap: "pretty" }}>
          <Typography variant="h1" sx={{ fontSize: { xs: '2.25rem', sm: '2.5rem' }, color: '#FFF' }}>
            When are you available?
        </Typography>
        <Box>
          {submitState === "error" && <Typography variant="body1" sx={{ fontSize: { xs: '1rem', marginBottom: "12px" }, color: Colors.error }}>There was an error updating your availability. Please try again.</Typography>}
          <CalendarProvider>
            <Calendar dateTimes={dates} onDateTimesChange={setDates} onCancel={() => setDates(JSON.parse(JSON.stringify(initialDates)))}  onSubmit={() => setSubmitState("pending")} />
          </CalendarProvider>
        </Box>
      </Box>
      </>
    );
  } else {
    return (
      <>
      <WhitePenciledLogo style={{ width: "150px", margin: "20px 15px", position: "absolute", top: "10px", left: "10px" }} />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', justifyContent: 'center', alignItems: 'center', height: '100dvh', margin: { xs: "0px 24px"}, textAlign: "center" }}>
        <Typography variant="h1" sx={{ fontSize: { xs: '2rem', sm: '2.25rem' }, color: '#FFF' }}>
          404 - Link not found
        </Typography>
        <Typography variant="body1" sx={{ fontSize: { xs: '1rem' }, color: '#FFF' }}>
          This link is invalid or has expired. Please request the bot for a new link.
        </Typography>
      </Box>
      </>
    );
  }
};

export default PatientAvailability;