import React, { useCallback, useRef } from "react";
import { Box, Typography, IconButton, Modal } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";

interface UploadLogoModalProps {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File) => void;
}

const UploadLogoModal: React.FC<UploadLogoModalProps> = ({ open, onClose, onUpload }) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  // Handler for file input change
  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const files: FileList | null = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      onUpload(file);
    }
  }, [onUpload]);

  // Handler for clicking the box
  const handleClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  // Handler for drag and drop
  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      onUpload(file);
    }
  }, [onUpload]);

  // Prevent default behavior when dragging over
  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          outline: 0,
        }}
      >
        <Typography variant="h6">Upload Logo</Typography>
        
        {/* Hidden File Input */}
        <input
          ref={inputRef}
          type="file"
          accept=".jpg,.png"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />

        {/* Clickable Box with Drag and Drop support */}
        <Box
          sx={{
            border: "1px dashed grey",
            padding: 5,
            borderRadius: 2,
            cursor: "pointer",
            textAlign: "center",
            ":hover": {
              borderColor: "primary.main",
              backgroundColor: "primary.lighter",
            },
          }}
          onClick={handleClick}
          onDrop={handleDrop}       // Handle file drop
          onDragOver={handleDragOver} // Prevent default drag over behavior
        >
          <IconButton style={{ color: "grey" }}>
            <UploadFileIcon sx={{ fontSize: 60 }} />
          </IconButton>
          <Typography variant="body1">Click to upload or drag a file here</Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default UploadLogoModal;
