import React, { useState, useRef, useEffect } from 'react';
import { Modal, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, Grid, FormControl, Select, MenuItem, TextField } from '@mui/material';
import LoadingWithMessage from '../subcomponents/LoadingWithMessage';
import { PrimaryButton, TransparentButton } from '../subcomponents/CustomButton';
import { Colors } from '../Colors';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';


interface NoApiKeyMsgProps {
  handleBackToApiKeyQuestion: () => void;
}

const NoApiKeyMsg: React.FC<NoApiKeyMsgProps> = ({ handleBackToApiKeyQuestion }) => {
  const navigate = useNavigate();

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <Box sx={{ background: Colors.bgGrey, padding: { xs: "7%", sm: "3% 5%" }, width: { xs: "80%", md: "35%" }, borderRadius: "20px" }}>
          <Typography variant="h6" sx={{ marginBottom: "20px", textAlign: "left" }}>
            A request for an API key has been sent to Healthie.
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", marginBottom: "10px" }}>
            An API key should appear on your Healthie dashboard within 24-48 hours. Please check the email attached to your Healthie
            account.
          </Typography>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://penciled.com/blogs/how-to-get-healthie-api-key"
              style={{ textDecoration: "none", color: Colors.link, fontFamily: "Foundry-Buch", fontSize: "0.9rem" }}
            >
              How do I get this from my Healthie dashboard?
            </a>
            <PrimaryButton onClick={() => navigate('/dashboard')} style={{ marginTop: "30px" }}>
              Continue to Dashboard
            </PrimaryButton>
          </div>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "left", marginTop: "20px" }}>
        <TransparentButton onClick={handleBackToApiKeyQuestion}>
          <ArrowBackIcon sx={{ marginRight: "5px" }} /> Back
        </TransparentButton>
      </Box>
    </>
  );
}

export default NoApiKeyMsg;
