import React, { useState } from "react";
import styled from "styled-components";
import { signOut } from "../slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import { TransparentButton } from "./CustomButton";
import { useAppDispatch, RootState } from "../store";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { FrontendUser } from "../types";
import { useSelector } from "react-redux";
import { Box, Menu, MenuItem } from "@mui/material";
import { dropdownStyles, menuPropsStyles } from "../styles/GeneralStyles"; 

// Styled container for the header to manage spacing and alignment
const HeaderContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: transparent;
  padding: 10px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const SignoutButton = styled(TransparentButton)`
  @media (max-width: 1200px) {
    display: none;
  }
`;

const SignoutHeader: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(signOut());
    navigate("/login");
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (action?: string) => {
    setAnchorEl(null);
    if (action === "account") {
      navigate("/dashboard/account");
    } else if (action === "logout") {
      handleLogout();
    }
  };

  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);

  return (
    <HeaderContainer>
      <Box sx={{ display: "flex", alignItems: "center", fontSize: "1.1rem" }}>
        <Box onClick={handleClick} sx={{ display: "flex", alignItems: "center", cursor: "pointer", gap: "10px", color: '#FFF' }}>
          {user?.firstName} {user?.lastName}
          <AccountCircleIcon style={{ fontSize: "2.1rem", color: '#FFF' }} />
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => handleClose()}
          PaperProps={{
            style: menuPropsStyles.PaperProps.sx,
          }}
          sx={dropdownStyles}
        >
          <MenuItem onClick={() => handleClose("account")}>My account</MenuItem>
          <MenuItem onClick={() => handleClose("logout")}>Logout</MenuItem>
        </Menu>
      </Box>
      {/* <SignoutButton onClick={handleLogout}>Sign out</SignoutButton> */}
    </HeaderContainer>
  );
};

export default SignoutHeader;
