import React, { useEffect, useState } from "react";
import { Box, Typography, Tooltip, OutlinedInput, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import axios from "axios";
import { Colors } from "../../Colors";
import { WhitePenciledLogo } from "../../styles/HeaderStyles";
import renewPTImage from "../../assets/nicoleRenew.jpg";
import { PrimaryButton } from "../../subcomponents/CustomButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { useSelector, useDispatch } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { completeWalkthrough, setUser } from "../../slices/AuthSlice";
import styled from "styled-components";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { set } from "date-fns";
import { fi } from "date-fns/locale";

const GradientText = styled(Typography)({
  background: "linear-gradient(90deg, #C5C5C5 0%, #FFFFFF 50%, #818181 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
});

const CustomCheckIcon = () => (
  <CheckOutlinedIcon
    style={{
      fontSize: "1.2rem",
      color: Colors.primary,
      background: Colors.textfield,
      borderRadius: "50%",
      padding: "3px",
      marginRight: "10px",
    }}
  />
);

const stripePublishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

if (!stripePublishableKey) {
  throw new Error("Stripe publishable key is not defined in environment variables");
}

const stripePromise = loadStripe(stripePublishableKey);

const cardElementOptions = {
  style: {
    base: {
      color: "#FFFFFF",
      "::placeholder": {
        color: "#CCCCCC",
      },
      iconColor: "#FFFFFF",
    },
    invalid: {
      color: "#FF0000",
    },
  },
};

const CheckoutForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const { showMessage } = useSnackbar();
  const user = useSelector((state: RootState) => state.auth.user);

  const [checkoutLoading, setCheckoutLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setCheckoutLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      setCheckoutLoading(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement!,
      billing_details: {
        name: name,
        email: email,
      },
    });

    if (error) {
      console.error(error);
      showMessage(error.message || "An error occurred", "error");
      setCheckoutLoading(false);
    } else {
      // console.log('PaymentMethod:', paymentMethod);

      // console.log('User:', user);
      if (!user || !user.token) {
        console.error("User is not authenticated:", user);
        showMessage("User is not authenticated.", "error");
        setCheckoutLoading(false);
        return;
      }

      try {
        // Attach payment method to Stripe customer
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/stripe/add-payment-method`,
          {
            paymentMethodId: paymentMethod.id,
          },
          {
            headers: { Authorization: `Bearer ${user.token}` },
          }
        );

        showMessage("Payment method added successfully!", "success");
        onSuccess();
      } catch (err) {
        console.error("Error adding payment method:", err);
        showMessage("Error adding payment method.", "error");
      } finally {
        setCheckoutLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ width: "90%" }}>
      <Box mb={2}>
        <Typography variant="h6" sx={{ fontSize: "1rem" }}>
          Email Address
        </Typography>
        <OutlinedInput
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          fullWidth
          sx={{
            backgroundColor: Colors.textfield,
            borderRadius: 2,
            color: "#fff",
            "& .MuiInputBase-input": {
              padding: "10px",
            },
            "& input::placeholder": {
              fontSize: "0.9rem",
              color: "#ccc",
            },
          }}
        />
      </Box>

      <Box mb={2}>
        <Typography variant="h6" sx={{ fontSize: "1rem" }}>
          Cardholder Name
        </Typography>
        <OutlinedInput
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          fullWidth
          sx={{
            borderRadius: 2,
            color: "#fff",
            backgroundColor: Colors.textfield,
            "& .MuiInputBase-input": {
              padding: "10px",
            },
            "& input::placeholder": {
              fontSize: "0.9rem",
              color: "#ccc",
            },
          }}
        />
      </Box>
      <Box>
        <Typography variant="h6" sx={{ fontSize: "1rem" }}>
          Card Details
        </Typography>
        <Box sx={{ padding: 1.5, backgroundColor: Colors.textfield, borderRadius: 2 }}>
          <CardElement options={cardElementOptions} />
        </Box>
      </Box>

      <PrimaryButton type="submit" disabled={!stripe} sx={{ marginTop: "20px", width: "100%" }}>
        {checkoutLoading ? <CircularProgress size={24} color="inherit" /> : "Start Free Trial"}
      </PrimaryButton>
    </form>
  );
};

const StartTrialPage: React.FC = () => {
  const [isCheckout, setIsCheckout] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showMessage } = useSnackbar();
  const user = useSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    // Create a Stripe customer for the user if not already created
    const createStripeCustomer = async () => {
      if (user && !user.stripeCustomerId) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}/api/stripe/create-customer`,
            {},
            {
              headers: { Authorization: `Bearer ${user.token}` },
            }
          );

          const stripeCustomerId = response.data.id;

          // console.log('Stripe customer created:', user.email, stripeCustomerId);

          // Update user in Redux store
          dispatch(setUser({ ...user, stripeCustomerId }));
        } catch (error) {
          console.error("Error creating Stripe customer:", error);
          showMessage("Error creating Stripe customer.", "error");
        }
      }
    };

    createStripeCustomer();
  }, [user, dispatch, showMessage]);

  const handleStartTrial = () => {
    setIsCheckout(true);
  };

  const handleCheckoutSuccess = async () => {
    try {
      // Complete the walkthrough
      await dispatch(completeWalkthrough());

      // Navigate to the next page
      navigate("/dashboard");
    } catch (error) {
      showMessage("Error completing the walkthrough.", "error");
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <Box sx={{ display: "flex", justifyContent: "space-between", height: "100vh" }}>
        <Box sx={{ background: Colors.bg, width: { xs: "100%", md: "50%" }, padding: "0 5%" }}>
          <WhitePenciledLogo style={{ width: "160px", margin: "auto", marginTop: "35px", color: "white" }} />

          {!isCheckout ? (
            <Box sx={{ width: { xs: "100%", md: "80%" }, height: "80vh", marginTop: "40px" }}>
              <GradientText variant="h4" sx={{ color: "#FFFFFF", marginBottom: "5px", textAlign: "center" }}>
                Start your 7-day free trial
              </GradientText>
              <Typography variant="body1" sx={{ color: "#FFFFFF", textAlign: "center" }}>
                Cancel anytime.
              </Typography>

              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  margin: "40px 0",
                  fontFamily: "Foundry-Buch",
                  color: "#FFFFFF",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  gap: "22px",
                }}
              >
                <li style={{ display: "flex", alignItems: "center" }}>
                  <CustomCheckIcon />
                  10,000 conversations
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <CustomCheckIcon />
                  AI calling with no cap on call length
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <CustomCheckIcon />
                  AI texting with manual override
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <CustomCheckIcon />
                  Voicemail
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <CustomCheckIcon />
                  Inbound call redirecting
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <CustomCheckIcon />
                  Call transcripts and recordings
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <CustomCheckIcon />
                  Post-processing data like call outcome, call status, action items
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <CustomCheckIcon />
                  24/7 dedicated support with custom Slack channel
                </li>
                <li style={{ display: "flex", alignItems: "center" }}>
                  <CustomCheckIcon />
                  Custom prompts and agent responses
                </li>
              </ul>

              <PrimaryButton fullWidth onClick={handleStartTrial}>
                Start Free Trial
              </PrimaryButton>
            </Box>
          ) : (
            <Box sx={{ width: { xs: "100%", md: "85%" }, height: "80vh", marginTop: "40px" }}>
              <Box sx={{ backgroundColor: "#1B1F32", padding: "15px", marginBottom: "30px", borderRadius: "15px" }}>
                <Typography variant="h5" sx={{ color: "#FFFFFF", marginBottom: "5px" }}>
                  7 days free
                </Typography>
                <Typography variant="body1" sx={{ color: "#FFFFFF", marginBottom: "10px" }}>
                  Then your subscription is $25.50 per{" "}
                  <Tooltip title="You will be charged $25.50 for each successful booking initiated by Penciled AI agents.">
                    <span style={{ textDecoration: "underline", textUnderlineOffset: "4px", textDecorationThickness: "0.1px", cursor: "pointer" }}>
                      successful booking made
                    </span>
                  </Tooltip>{" "}
                  billed until canceled.
                </Typography>
                <Box sx={{ width: "100%", height: "0.5px", backgroundColor: "#666", margin: "10px 0" }} />
                <Typography variant="body1" sx={{ color: "#FFFFFF", marginBottom: "10px" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Due today:</span>
                    <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>$0.00</span>
                  </div>
                  <br />
                  <div style={{ display: "flex", alignItems: "center", marginTop: "-10px" }}>
                    <VerifiedIcon sx={{ marginRight: "8px", color: Colors.success }} />
                    You won’t pay anything until 10 August. We’ll remind you 24 hours beforehand.
                  </div>
                </Typography>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  height: "auto",
                  borderRadius: "15px",
                  backgroundColor: "#151A32",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFFFFF",
                  padding: "30px 0",
                }}
              >
                <CheckoutForm onSuccess={handleCheckoutSuccess} />
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={{ background: "#151B2B", width: "50%", padding: "5%", display: { xs: "none", md: "flex" }, justifyContent: "center" }}>
          <Box sx={{ width: "70%", margin: "auto", background: "#252F4A", padding: "40px", borderRadius: "15px" }}>
            <Typography variant="body1" color="#FFF" mb={2} style={{ textAlign: "left", fontSize: "1.3rem" }}>
              &quot;Penciled developed an innovative, clinic-specific solution to help us optimize waitlist backfilling and scheduling, ensuring our
              patients receive the care they need more efficiently.&quot;
            </Typography>
            <Box style={{ display: "flex" }}>
              <img
                src={renewPTImage}
                alt="Review"
                style={{ width: "50px", height: "50px", borderRadius: "50%", marginTop: "10px", marginRight: "10px" }}
              />
              <Box>
                <Typography variant="body1" color="#FFF" mt={2} style={{ textAlign: "left", fontWeight: "bold" }}>
                  Nicole Sabes
                </Typography>
                <Typography variant="body2" color="#FFF" style={{ textAlign: "left" }}>
                  Clinic Director at Renew Physical Therapy
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Elements>
  );
};

export default StartTrialPage;
