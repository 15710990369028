import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { FrontendUser, Webhook } from "../types";

export interface WebhookState {
  webhooks: Webhook[] | undefined;
  loading: boolean;
  loadingMsg: string | undefined;
  errorMsg: string | undefined;
}

const initialState: WebhookState = {
  webhooks: undefined,
  loading: false,
  loadingMsg: "",
  errorMsg: "",
};

export const addWebhook = createAsyncThunk<
  { signingKey: string; url: string; name: string, recordingEnabled: boolean }, 
  { token: string, url: string; name: string, recordingEnabled: boolean },
  { rejectValue: Error }
>(
  "webhooks/addWebhook",
  async ({ token, url, name, recordingEnabled }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/webhooks`,
        { 
          webhookUrl: url, 
          name: name,
          recordingEnabled: recordingEnabled,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to add webhook"));
      }

      return { signingKey: response.data.signingKey, url: url, name: name, recordingEnabled: recordingEnabled };
    } catch (error) {
      console.error("Error: ", error);
      return rejectWithValue(
        new Error("Failed to add webhook: " + ((error as any).response?.data?.error as string) || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const refreshWebhook = createAsyncThunk<
  { signingKey: string; name: string }, 
  { token: string; name: string },
  { rejectValue: Error }
>(
  "webhooks/refreshWebhook",
  async ({ token, name }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/webhooks/refresh`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to refresh webhook"));
      }

      return { signingKey: response.data.signingKey, name };
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to refresh webhook: " + ((error as any).response?.data?.error as string) || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const deleteWebhook = createAsyncThunk<
  { message: string; name: string }, 
  { token: string; name: string },
  { rejectValue: Error }
>(
  "webhooks/deleteWebhook",
  async ({ token, name }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/webhooks`,
        {
          data: { name },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to delete webhook"));
      }

      return { message: response.data.message, name };
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to delete webhook: " + ((error as any).response?.data?.error as string) || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const fetchWebhooks = createAsyncThunk<
  Webhook[], 
  { token: string }, 
  { rejectValue: string }
>(
  'webhooks/fetchWebhooks',
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/webhooks`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue('Failed to fetch webhooks');
      }

      return response.data;
    } catch (error) {
      new Error("Failed to fetch webhook: " + ((error as any).response?.data?.error as string) || (error as Error).message || "Unknown error")
    }
  }
);

export const toggleWebhookActivity = createAsyncThunk<
  { message: string; name: string }, 
  { token: string, name: string },
  { rejectValue: Error }
>(
  "webhooks/toggleWebhookActivity",
  async ({ token, name }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/webhooks/activity/toggle`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to toggle webhook activity"));
      }

      return { message: response.data.message, name };
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to toggle webhook activity: " + ((error as any).response?.data?.error as string) || (error as Error).message || "Unknown error")
      );
    }
  }
);

export const toggleWebhookRecording = createAsyncThunk<
  { message: string; name: string }, 
  { token: string, name: string },
  { rejectValue: Error }
>(
  "webhooks/toggleWebhookRecording",
  async ({ token, name }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/webhooks/recording/toggle`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status !== 200) {
        return rejectWithValue(new Error("Failed to toggle webhook recording"));
      }

      return { message: response.data.message, name };
    } catch (error) {
      return rejectWithValue(
        new Error("Failed to toggle webhook recording: " + ((error as any).response?.data?.error as string) || (error as Error).message || "Unknown error")
      );
    }
  }
);

const webhooksSlice = createSlice({
  name: "webhooks",
  initialState,
  reducers: {
    setWebhooks: (
      state,
      action: PayloadAction<{ webhooks: Webhook[] | undefined }>
    ) => {
      state.webhooks = action.payload.webhooks;
    },
    clearWebhooks: (state) => {
      state.webhooks = state.webhooks?.map((webhook) => ({
        ...webhook,
        signingKey: undefined,
      }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchWebhooks.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Getting webhooks...";
        state.errorMsg = undefined; // Clear previous error message on new request
      })
      .addCase(fetchWebhooks.fulfilled, (state, action: PayloadAction<Webhook[]>) => {
        state.webhooks = action.payload.map((webhook: Webhook) => ({ ...webhook, signingKey: undefined }));
        state.loadingMsg = undefined;
        state.loading = false;
      })
      .addCase(fetchWebhooks.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = action.payload || 'Failed to fetch webhooks'; // Capture the error message
      })
      .addCase(addWebhook.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Adding webhook...";
        state.errorMsg = undefined; // Clear previous error message on new request
      })
      .addCase(addWebhook.fulfilled, (state, action) => {
        state.webhooks = state.webhooks 
          ? [...state.webhooks, { ...action.payload, active: true, recordingEnabled: action.payload.recordingEnabled }] 
          : [{ ...action.payload, active: true, recordingEnabled: action.payload.recordingEnabled }];
        state.loadingMsg = undefined;
        state.loading = false;
      })      
      .addCase(addWebhook.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = undefined;
        state.errorMsg = (action.payload as Error)?.message || "Failed to add webhook"; // Capture the error message
      })
      .addCase(refreshWebhook.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Refreshing webhook...";
        state.errorMsg = undefined;
      })
      .addCase(refreshWebhook.fulfilled, (state, action) => {
        state.loading = false;
        if (state.webhooks) {
          const index = state.webhooks.findIndex(
            (webhook) => webhook.name === action.meta.arg.name
          );
          if (index !== -1) {
            state.webhooks[index].signingKey = action.payload.signingKey;
          }
        }
      })
      .addCase(refreshWebhook.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = "";
        state.errorMsg = (action.payload as Error)?.message || "Failed to refresh webhook";
      })
      .addCase(deleteWebhook.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Deleting webhook...";
        state.errorMsg = undefined;
      })
      .addCase(deleteWebhook.fulfilled, (state, action) => {
        state.loading = false;
        if (state.webhooks) {
          state.webhooks = state.webhooks.filter(
            (webhook) => webhook.name !== action.meta.arg.name
          );
        }
        state.loadingMsg = undefined;
        state.errorMsg = undefined;
      })
      .addCase(deleteWebhook.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = undefined;
        state.errorMsg = (action.payload as Error)?.message || "Failed to delete webhook";
      })
      .addCase(toggleWebhookActivity.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Toggling webhook activity...";
        state.errorMsg = undefined;
      })
      .addCase(toggleWebhookActivity.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = undefined;
        state.errorMsg = undefined;

        if (state.webhooks) {
          const index = state.webhooks.findIndex(
            (webhook) => webhook.name === action.payload.name
          );
          if (index !== -1) {
            state.webhooks[index].active = !state.webhooks[index].active;
          }
        }
      })
      .addCase(toggleWebhookActivity.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = undefined;
        state.errorMsg = (action.payload as Error)?.message || "Failed to toggle webhook activity";
      })
      .addCase(toggleWebhookRecording.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Toggling webhook recording...";
        state.errorMsg = undefined;
      })
      .addCase(toggleWebhookRecording.fulfilled, (state, action) => {
        state.loading = false;
        state.loadingMsg = undefined;
        state.errorMsg = undefined;

        if (state.webhooks) {
          const index = state.webhooks.findIndex(
            (webhook) => webhook.name === action.payload.name
          );
          if (index !== -1) {
            state.webhooks[index].recordingEnabled = !state.webhooks[index].recordingEnabled;
          }
        }
      })
      .addCase(toggleWebhookRecording.rejected, (state, action) => {
        state.loading = false;
        state.loadingMsg = undefined;
        state.errorMsg = (action.payload as Error)?.message || "Failed to toggle webhook recording";
      });
  },
});

export const { setWebhooks, clearWebhooks } = webhooksSlice.actions;

export const selectWebhooks = (state: RootState) => state.webhooks;

export default webhooksSlice.reducer;
