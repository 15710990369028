import { getRange } from "../helpers/dates";
import type { Range, RangeType, Value } from "../types";

function isValueWithinRange(value: Date, range: Range<Date>): boolean {
  return range[0] <= value && range[1] >= value;
}

function isRangeWithinRange(
  greaterRange: Range<Date>,
  smallerRange: Range<Date>
): boolean {
  return (
    greaterRange[0] <= smallerRange[0] && greaterRange[1] >= smallerRange[1]
  );
}

function doRangesOverlap(
  range1: Range<Date>,
  range2: Range<Date>
): boolean {
  return (
    isValueWithinRange(range1[0], range2) ||
    isValueWithinRange(range1[1], range2)
  );
}

function getRangeClassNames(
  valueRange: Range<Date>,
  dateRange: Range<Date>,
  baseClassName: string
): string[] {
  const isRange = doRangesOverlap(dateRange, valueRange);

  const classes = [];

  if (isRange) {
    classes.push(baseClassName);

    const isRangeStart = isValueWithinRange(valueRange[0], dateRange);
    const isRangeEnd = isValueWithinRange(valueRange[1], dateRange);

    if (isRangeStart) {
      classes.push(`${baseClassName}Start`);
    }

    if (isRangeEnd) {
      classes.push(`${baseClassName}End`);
    }

    if (isRangeStart && isRangeEnd) {
      classes.push(`${baseClassName}BothEnds`);
    }
  }

  return classes;
}

function isCompleteValue<T>(
  value: T | null | Range<T | null>
): value is T | Range<T> {
  if (Array.isArray(value)) {
    return value[0] !== null && value[1] !== null;
  }

  return value !== null;
}

export function getTileClasses(args: {
  date?: Date | Range<Date>;
  dateType?: RangeType;
  hover?: Date | null;
  value?: Value;
  valueType?: RangeType;
  stylesMap?: Record<string, string>;
}): string[] {
  if (!args) {
    throw new Error("args is required");
  }

  const { value, date, hover, stylesMap } = args;

  const classes: string[] = [];

  if (!date) {
    return classes;
  }

  const now = new Date();
  const dateRange = (() => {
    if (Array.isArray(date)) {
      return date;
    }

    const { dateType } = args;

    if (!dateType) {
      throw new Error(
        "dateType is required when date is not an array of two dates"
      );
    }

    return getRange(dateType, date);
  })();

  if (isValueWithinRange(now, dateRange)) {
    classes.push(stylesMap ? stylesMap.today : "today");
  }

  if (!value || !isCompleteValue(value)) {
    return classes;
  }

  const valueRange = (() => {
    if (Array.isArray(value)) {
      return value;
    }

    const { valueType } = args;

    if (!valueType) {
      throw new Error(
        "valueType is required when value is not an array of two dates"
      );
    }

    return getRange(valueType, value);
  })();

  if (isRangeWithinRange(valueRange, dateRange)) {
    classes.push(stylesMap ? stylesMap.active : "active");
  } else if (doRangesOverlap(valueRange, dateRange)) {
    classes.push(stylesMap ? stylesMap["has-active"] : "has-active");
  }

  const valueRangeClassNames = getRangeClassNames(
    valueRange,
    dateRange,
    stylesMap ? stylesMap.range : "range"
  );

  classes.push(...valueRangeClassNames);

  const valueArray = Array.isArray(value) ? value : [value];

  if (hover && valueArray.length === 1) {
    const hoverRange: Range<Date> =
      hover > valueRange[0] ? [valueRange[0], hover] : [hover, valueRange[0]];
    const hoverRangeClassNames = getRangeClassNames(
      hoverRange,
      dateRange,
      stylesMap ? stylesMap.hover : "hover"
    );

    classes.push(...hoverRangeClassNames);
  }

  return classes;
}
