import { MouseEvent, TouchEvent, useMemo } from "react";

import { useCalendarContext } from "../utils/hooks/useCalendarContext";
import styles from "../Calendar.module.scss";
import { Period, periodIncludesPeriod } from "./helpers/dates";
import { ContextMenuConfig } from "./ContextMenu";
import useDeviceType from "./hooks/useDeviceType";
import { TileDisabledFunc } from "./types";
import { formatOutputPeriod } from "./helpers/dateFormatters";

const defaultGridPeriods = Array(24)
  .fill(null)
  .map((_, hourIndex) => {
    const hourPeriods = Array(4)
      .fill(null)
      .map((_, quarterIndex) => {
        const period = quarterIndex * 15;
        const isLastPeriod = quarterIndex === 3;
        const hourString = hourIndex.toString().padStart(2, "0");
        const endHourString = isLastPeriod
          ? (hourIndex + 1).toString().padStart(2, "0")
          : hourString;
        const startPeriodString = period.toString().padStart(2, "0");
        const endPeriodString = isLastPeriod
          ? "00"
          : (period + 15).toString().padStart(2, "0");
        return {
          start: `${hourString}:${startPeriodString}`,
          end: `${endHourString}:${endPeriodString}`,
        };
      });

    return hourPeriods;
  });

interface GestureConfig {
  day: string;
  period: Period;
}

let gestureStartConfig: GestureConfig | null;

type HoursViewGridProps = {
  timeMode?: "12" | "24";
  gridStart?: number;
  activeStartDate: Date;
  disableGestureSelection?: boolean;
  shouldDisableDate?: TileDisabledFunc;
};

const HoursViewGrid = ({
  timeMode = "12",
  gridStart = 0,
  activeStartDate,
  disableGestureSelection = false,
  shouldDisableDate,
}: HoursViewGridProps) => {
  const deviceType = useDeviceType();

  const {
    selectedDatesMap,
    handlePeriodToggle,
    setSelectedDatesMap,
    timeClipboardConfig,
    setTimeClipboardConfig,
    showToast,
    setContextMenuConfig,
  } = useCalendarContext();

  const handleContextMenu = ({
    event,
    date,
    hourPeriod,
  }: {
    event: MouseEvent | TouchEvent;
    date: Date;
    hourPeriod: Period;
  }) => {
    event.preventDefault();

    let location = {
      x: (event as MouseEvent).clientX,
      y: (event as MouseEvent).clientY,
    };

    if (deviceType === "mobile") {
      location = {
        x: (event as any).pageX - 10,
        y: (event as any).pageY - 120,
      };
    }

    const newSelectedPeriods = selectedDatesMap.get(date.toDateString()) || [];
    const selectedParentPeriod = newSelectedPeriods.find((period) => {
      return periodIncludesPeriod(period, hourPeriod);
    });

    if (!selectedParentPeriod && !timeClipboardConfig) {
      // Nothing to copy, paste or delete
      return;
    }

    setContextMenuConfig({
      options: [
        selectedParentPeriod
          ? {
              label: "Copy",
              action: () => {
                setTimeClipboardConfig(selectedParentPeriod);
                const displayedPeriod =
                  formatOutputPeriod(selectedParentPeriod);
                showToast(
                  `Period copied for ${displayedPeriod.start} - ${displayedPeriod.end}`
                );
              },
            }
          : null,
        timeClipboardConfig
          ? {
              label: "Paste",
              action: () => {
                handlePeriodToggle(date, timeClipboardConfig);
              },
            }
          : null,
        selectedParentPeriod
          ? {
              label: "Delete",
              action: () => {
                const newSelectedDatesMap = new Map(selectedDatesMap);
                newSelectedDatesMap.set(
                  date.toDateString(),
                  newSelectedPeriods.filter(
                    (period) => period !== selectedParentPeriod
                  )
                );
                if (!newSelectedDatesMap.get(date.toDateString())?.length) {
                  newSelectedDatesMap.delete(date.toDateString());
                }
                setSelectedDatesMap(newSelectedDatesMap);
              },
              color: "#ff6955",
            }
          : null,
      ].filter(Boolean) as ContextMenuConfig["options"],
      location,
    });
  };

  const gridPeriods = useMemo(() => {
    return [
      ...defaultGridPeriods.slice(gridStart),
      ...defaultGridPeriods.slice(0, gridStart),
    ];
  }, [gridStart]);

  const handleMouseUp = (
    e: MouseEvent | TouchEvent,
    gestureConfig?: GestureConfig
  ) => {
    if (disableGestureSelection) {
      return;
    }
    let gestureEndConfig: GestureConfig | null = gestureConfig || null;

    if ((e as TouchEvent).changedTouches) {
      // Handle for mobile devices
      const touch = (e as TouchEvent).changedTouches?.[0];
      const targetElement = document.elementFromPoint(
        touch.clientX,
        touch.clientY
      );
      gestureEndConfig = targetElement?.ariaLabel
        ? {
            day: targetElement.ariaLabel.split("-")[0],
            period: {
              start: targetElement.ariaLabel.split("-")[1],
              end: targetElement.ariaLabel.split("-")[2],
            },
          }
        : null;
    }

    if (
      !gestureStartConfig ||
      !gestureEndConfig ||
      gestureStartConfig.day !== gestureEndConfig.day ||
      (gestureStartConfig.period.start === gestureEndConfig.period.start &&
        gestureStartConfig.period.end === gestureEndConfig.period.end)
    ) {
      // Gesture did spanned multiple days or the same period, so we bail
      return;
    }

    const shouldReversePeriod =
      gestureStartConfig.period.start.localeCompare(
        gestureEndConfig.period.end
      ) > 0;
    handlePeriodToggle(new Date(gestureStartConfig.day), {
      start: shouldReversePeriod
        ? gestureEndConfig.period.start
        : gestureStartConfig.period.start,
      end: shouldReversePeriod
        ? gestureStartConfig.period.end
        : gestureEndConfig.period.end,
    });
  };

  return (
    <div className={styles["hours-view-grid"]}>
      {gridPeriods.map((periods, index) => {
        return (
          <div key={index}>
            {periods.map((hourPeriod, hourPeriodIndex) => {
              let hourRender = "";
              if (hourPeriodIndex === 0) {
                const date = new Date();
                date.setHours(gridStart + index);
                date.setMinutes(0);
                hourRender = date.toLocaleTimeString("en-US", {
                  hour: timeMode === "12" ? "numeric" : "2-digit",
                  hour12: timeMode === "12",
                });
              }
              return (
                <div
                  className={styles["hours-view-grid-row"]}
                  key={hourPeriodIndex}
                >
                  <div
                    className={[
                      styles["hours-view-grid-period-label"],
                      hourPeriodIndex === 0 ? styles.full : "",
                    ].join(" ")}
                  >
                    {hourRender.toLowerCase().replace(" ", "")}
                    {hourPeriodIndex !== 0 && <div className={styles.dot} />}
                  </div>
                  <div className={styles["hours-view-grid-row-right"]}>
                    <div className={styles["hours-view-grid-period-divider"]} />
                    <div className={styles["hours-view-grid-period-buttons"]}>
                      {Array(7)
                        .fill(null)
                        .map((_, buttonIndex) => {
                          const date = new Date(activeStartDate);
                          date.setDate(date.getDate() + buttonIndex);
                          const selectedPeriods = selectedDatesMap.get(
                            date.toDateString()
                          );
                          const isSelected = selectedPeriods?.some((period) => {
                            return periodIncludesPeriod(period, hourPeriod);
                          });

                          return (
                            <button
                              className={[
                                styles["hours-view-grid-period-button"],
                                isSelected ? styles.selected : "",
                                shouldDisableDate?.({
                                  date,
                                  activeStartDate,
                                })
                                  ? styles.disabled
                                  : "",
                              ].join(" ")}
                              disabled={shouldDisableDate?.({
                                date,
                                activeStartDate,
                              })}
                              key={buttonIndex}
                              onClick={() =>
                                handlePeriodToggle(date, hourPeriod)
                              }
                              aria-label={`${date.toDateString()}-${
                                hourPeriod.start
                              }-${hourPeriod.end}`}
                              onTouchStart={() => {
                                gestureStartConfig = {
                                  day: date.toDateString(),
                                  period: hourPeriod,
                                };
                              }}
                              onMouseDown={() => {
                                gestureStartConfig = {
                                  day: date.toDateString(),
                                  period: hourPeriod,
                                };
                              }}
                              onTouchEnd={(e) => handleMouseUp(e)}
                              onMouseUp={(e) =>
                                handleMouseUp(e, {
                                  day: date.toDateString(),
                                  period: hourPeriod,
                                })
                              }
                              onContextMenu={(event) =>
                                handleContextMenu({
                                  event,
                                  date,
                                  hourPeriod,
                                })
                              }
                            ></button>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default HoursViewGrid;
