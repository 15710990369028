import { MouseEvent, TouchEvent, useMemo } from "react";
import styles from "../Calendar.module.scss";

import type {
  ClassName,
  TileClassNameFunc,
  TileContentFunc,
  TileDisabledFunc,
  View,
} from "./types";
import { useCalendarContext } from "./hooks/useCalendarContext";

type TileProps = {
  /**
   * The beginning of a period that shall be displayed.
   *
   * @example new Date(2017, 0, 1)
   */
  activeStartDate: Date;
  children: React.ReactNode;
  classes?: string[];
  date: Date;
  formatAbbr?: (locale: string | undefined, date: Date) => string;
  /**
   * Locale that should be used by the calendar. Can be any [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag).
   *
   * **Note**: When using SSR, setting this prop may help resolving hydration errors caused by locale mismatch between server and client.
   *
   * @example 'hu-HU'
   */
  locale?: string;
  /**
   * Maximum date that the user can select. Periods partially overlapped by maxDate will also be selectable, although react-calendar will ensure that no later date is selected.
   *
   * @example new Date()
   */
  maxDate?: Date;
  maxDateTransform: (date: Date) => Date;
  /**
   * Minimum date that the user can select. Periods partially overlapped by minDate will also be selectable, although react-calendar will ensure that no earlier date is selected.
   *
   * @example new Date()
   */
  minDate?: Date;
  minDateTransform: (date: Date) => Date;
  onClick?: (date: Date, event: React.MouseEvent<HTMLButtonElement>) => void;
  onMouseOver?: (date: Date) => void;
  style?: React.CSSProperties;
  /**
   * Class name(s) that will be applied to a given calendar item (day on month view, month on year view and so on).
   *
   * @example 'class1 class2'
   * @example ['class1', 'class2 class3']
   * @example ({ activeStartDate, date, view }) => view === 'month' && date.getDay() === 3 ? 'wednesday' : null
   */
  tileClassName?: TileClassNameFunc | ClassName;
  /**
   * Allows to render custom content within a given calendar item (day on month view, month on year view and so on).
   *
   * @example 'Sample'
   * @example ({ activeStartDate, date, view }) => view === 'month' && date.getDay() === 0 ? <p>It's Sunday!</p> : null
   */
  tileContent?: TileContentFunc | React.ReactNode;
  /**
   * Pass a function to determine if a certain day should be displayed as disabled.
   *
   * @example ({ activeStartDate, date, view }) => date.getDay() === 0
   */
  tileDisabled?: TileDisabledFunc;
  /**
   * Determines which calendar view shall be opened. Does not disable navigation. Can be `"month"`, `"year"`, `"decade"` or `"century"`.
   *
   * @example 'year'
   */
  view: View;
  disableGestureSelection?: boolean;
};

let gestureStartDate: Date | null = null;

export default function Tile(props: TileProps): React.ReactElement {
  const {
    activeStartDate,
    children,
    date,
    formatAbbr,
    locale,
    maxDate,
    maxDateTransform,
    minDate,
    minDateTransform,
    onClick,
    onMouseOver,
    style,
    tileContent: tileContentProps,
    tileDisabled,
    view,
    classes,
    disableGestureSelection,
    tileClassName: _tileClassName,
  } = props;

  const tileContent = useMemo(() => {
    const args = { activeStartDate, date, view };

    return typeof tileContentProps === "function"
      ? tileContentProps(args)
      : tileContentProps;
  }, [activeStartDate, date, tileContentProps, view]);

  const { selectedDatesMap, setSelectedDatesMap } = useCalendarContext();

  const isDisabled =
    (minDate && minDateTransform(minDate) > date) ||
    (maxDate && maxDateTransform(maxDate) < date) ||
    tileDisabled?.({ activeStartDate, date });

  const handleMouseUp = (e: MouseEvent | TouchEvent) => {
    if (disableGestureSelection) {
      return;
    }
    let gestureEndDate: Date | null = date;
    if ((e as TouchEvent).changedTouches) {
      // Handle for mobile devices
      const touch = (e as TouchEvent).changedTouches?.[0];
      const targetElement = document.elementFromPoint(
        touch.clientX,
        touch.clientY
      );
      gestureEndDate = targetElement?.ariaLabel
        ? new Date(targetElement.ariaLabel)
        : null;
    }

    if (
      !gestureStartDate ||
      !gestureEndDate ||
      gestureStartDate.toISOString() === gestureEndDate.toISOString() ||
      gestureStartDate.getMonth() !== gestureEndDate.getMonth()
    ) {
      // Gesture did not span multiple days or spanned multiple months, so we bail
      return;
    }

    const startDate = gestureStartDate.getDate();
    const endDate = gestureEndDate.getDate();

    // It's horizontal if start and end is on same week
    let trackDirection: "vertical" | "horizontal" | "invalid" = "invalid";
    if (Math.abs((startDate - endDate) % 7) === 0) {
      trackDirection = "vertical";
    } else {
      // Track direction is horizontal only if start and end is on same week
      const offset = activeStartDate.getDay();
      const startDateWeek = Math.floor((startDate + offset - 1) / 7);
      const endDateWeek = Math.floor((endDate + offset - 1) / 7);
      if (startDateWeek === endDateWeek) {
        trackDirection = "horizontal";
      }
    }

    const newSelectedDatesMap = new Map(selectedDatesMap);
    switch (trackDirection) {
      case "horizontal":
        for (
          let i = Math.min(startDate, endDate);
          i <= Math.max(startDate, endDate);
          i += 1
        ) {
          newSelectedDatesMap.set(
            new Date(
              gestureEndDate.getFullYear(),
              gestureEndDate.getMonth(),
              i
            ).toDateString(),
            [
              {
                start: "00:00",
                end: "00:00",
              },
            ]
          );
        }
        break;

      case "vertical":
        for (
          let i = Math.min(startDate, endDate);
          i <= Math.max(startDate, endDate);
          i += 7
        ) {
          newSelectedDatesMap.set(
            new Date(
              gestureEndDate.getFullYear(),
              gestureEndDate.getMonth(),
              i
            ).toDateString(),
            [
              {
                start: "00:00",
                end: "00:00",
              },
            ]
          );
        }
        break;

      default:
        break;
    }

    setSelectedDatesMap(newSelectedDatesMap);
  };

  const tileClassName =
    typeof _tileClassName === "function"
      ? _tileClassName({ activeStartDate, date })
      : _tileClassName;
  let customClassName = "";
  if (Array.isArray(tileClassName)) {
    customClassName = tileClassName.filter(Boolean).join(" ");
  } else {
    customClassName = tileClassName || "";
  }

  return (
    <button
      className={[
        ...(classes || []),
        styles.tile,
        isDisabled && styles.disabled,
        customClassName,
      ].join(" ")}
      disabled={isDisabled}
      onClick={onClick ? (event) => onClick(date, event) : undefined}
      onFocus={onMouseOver ? () => onMouseOver(date) : undefined}
      onMouseOver={onMouseOver ? () => onMouseOver(date) : undefined}
      style={style}
      type="button"
      onTouchStart={() => {
        gestureStartDate = date;
      }}
      onMouseDown={() => {
        gestureStartDate = date;
      }}
      onMouseUp={handleMouseUp}
      onTouchEnd={handleMouseUp}
    >
      {formatAbbr ? (
        <abbr aria-label={formatAbbr(locale, date)}>{children}</abbr>
      ) : (
        children
      )}
      {tileContent}
    </button>
  );
}
