import styled from "styled-components";
import { convertCamelCaseToOfficial } from "../../utils/utils";
import { AgentTemplate } from "../../types";
import { PrimaryButton } from "../../subcomponents/CustomButton";
import { StyledCard } from "../../styles";
import { Box, Typography } from "@mui/material";
import { Colors } from "../../Colors";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { BorderBottom } from "@mui/icons-material";
import Healthie from "../../assets/HealthieWhiteLogo.png";

const StyledPrimaryButton = styled(PrimaryButton)`
  margin-top: 50px !important;
`;

interface AgentCardProps {
  onClick: () => void;
  agentTemplate: AgentTemplate;
}

const AgentCard: React.FC<AgentCardProps> = ({ onClick, agentTemplate }) => {

  return (
    <>
      {agentTemplate && (
        <StyledCard>
          <Box onClick={onClick}>
            <img src={agentTemplate.avatarUrl} alt={agentTemplate.name}/>

            <Box display="flex" alignItems="baseline" gap='10px'>
              <Typography variant="body1" fontSize={'16px'} fontWeight={'bold'}>{agentTemplate.name}</Typography>
              <Box sx={{background: Colors.bg, borderRadius: '20px', padding: '0 10px', display: 'flex', justifyContent: 'center' }}>
                <Typography variant="caption" color={'primary'}>{convertCamelCaseToOfficial(agentTemplate.type)} agent</Typography>
              </Box>
            </Box>

            <Box display={'flex'} flexDirection={'column'} mt={1.5} p={1} sx={{background: '#21253C', borderRadius: '10px', boxShadow: "0 1px 2px rgba(0, 0, 0, 0.2)"}}>
              <Box sx={{borderBottom: '1px solid Colors.textfield', paddingBottom: '10px'}}>
                <Typography variant="caption">Best known for eliminating last minute cancellation</Typography>
                <span style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <CheckOutlinedIcon 
                    style={{
                      fontSize: '1.2rem', 
                      color: Colors.primary, 
                      background: Colors.textfield, 
                      borderRadius: '50%', 
                      padding: '2px'
                    }}
                    /> 
                    <Typography variant="caption">
                      Connects seamlessly to your EHR
                    </Typography></span>
                
                <span style={{display: 'flex', alignItems: 'center', gap: '8px'}}>
                  <CheckOutlinedIcon 
                    style={{
                      fontSize: '1.2rem', 
                      color: Colors.primary, 
                      background: Colors.textfield, 
                      borderRadius: '50%', 
                      padding: '2px'
                    }}
                    />
                    <Typography variant="caption">
                      Detects no-shows and call waitlist patients
                    </Typography>
                </span>
              </Box>

              <Typography variant="caption" mt={1} sx={{color: Colors.info}}>Supported EHR</Typography>
              <img src={Healthie} alt="Healthie" style={{width: '85px', height: 'auto', marginTop: '5px', borderRadius: '0'}}/>
            </Box>

          {/* <h1>EHR: {agentTemplate.ehr}</h1> */}
          {/* <StyledPrimaryButton onClick={onClick}>Select</StyledPrimaryButton> */}
          </Box>
        </StyledCard>
      )}
    </>
  );  
};

export default AgentCard;
