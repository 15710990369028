import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../store";
import { CalendlyStatus, FrontendUser } from "../types";

interface CalendlyState {
  token: string | null;
  status: string | null;
  error: string | null;
  loading: boolean;
  loadingMsg?: string;
  selectedMeetingName?: string;
  trackedMeetingNames?: string[];
  isIntegrated: boolean;
}

const initialState: CalendlyState = {
  token: null,
  status: null,
  error: null,
  loading: false,
  isIntegrated: false,
};

export const exchangeCodeForToken = createAsyncThunk<
  { calendlyToken: string },
  { token: string, code: string },
  { rejectValue: string }
>(
  "calendly/exchangeCodeForToken",
  async ({ token, code }, { rejectWithValue }) => {
    try {   
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      const response = await axios.post(`${backendUrl}/api/calendly/exchange-code`, { code }, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });

      if (response.status !== 200) {
        return rejectWithValue('Failed to exchange code for token');
      }

      return { calendlyToken: response.data.token };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error || error.message || 'Unknown error');
    }
  }
);

export const getIntegrationStatus = createAsyncThunk<
  { status: string },
  { token: string },
  { rejectValue: string }
>(
  "calendly/getIntegrationStatus",
  async ({ token }, { rejectWithValue }) => {
    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      if (!backendUrl) {
        throw new Error('Backend URL not found');
      }

      const response = await axios.get(`${backendUrl}/api/calendly/status`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status !== 200) {
        return rejectWithValue('Failed to get Calendly integration status');
      }

      return { status: response.data.status };
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error || error.message || 'Unknown error');
    }
  }
);

export const checkCalendlyIntegration = createAsyncThunk<
  boolean,
  { token: string },
  { rejectValue: string }
>(
  "calendly/checkIntegration",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/calendly/check-if-integrated`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data.integrated;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error || error.message || 'Unknown error');
    }
  }
);

export const disconnectCalendly = createAsyncThunk<
  boolean,
  { token: string },
  { rejectValue: string }
>(
  "calendly/disconnect",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/api/integrations/calendly`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      return response.status === 200;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error || error.message || 'Unknown error');
    }
  }
);

export const fetchCalendlyMeetingNames = createAsyncThunk<
  string[],
  { token: string },
  { rejectValue: string }
>(
  "calendly/fetchMeetingNames",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/calendly/meetings`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error || error.message || 'Unknown error');
    }
  }
);

export const updateCalendlyMeetingName = createAsyncThunk<
  void,
  { token: string, meetingName: string },
  { rejectValue: string }
>(
  "calendly/updateMeetingName",
  async ({ token, meetingName }, { rejectWithValue }) => {
    try {
      await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/calendly/meetings`,
      { meetingName },
      {
        headers: { Authorization: `Bearer ${token}` },
      });

    } catch (error: any) {
      return rejectWithValue(error.response?.data?.error || error.message || 'Unknown error');
    }
  }
);

const calendlySlice = createSlice({
  name: "calendly",
  initialState,
  reducers: {
    setCalendlyOAuthToken: (state, action) => {
      state.token = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(exchangeCodeForToken.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Exchanging code for token...";
        state.isIntegrated = false;
      })
      .addCase(exchangeCodeForToken.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload.calendlyToken;
        state.isIntegrated = true;
      })
      .addCase(exchangeCodeForToken.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to exchange code for token";
        state.isIntegrated = false;
      })
      .addCase(getIntegrationStatus.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Getting integration status...";
        state.isIntegrated = false;
      })
      .addCase(getIntegrationStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload.status;
        state.isIntegrated = action.payload.status === CalendlyStatus.connected;
      })
      .addCase(getIntegrationStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to get integration status";
        state.isIntegrated = false;
      })
      .addCase(checkCalendlyIntegration.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Checking Calendly integration...";
      })
      .addCase(checkCalendlyIntegration.fulfilled, (state, action) => {
        state.loading = false;
        state.isIntegrated = action.payload;
      })
      .addCase(checkCalendlyIntegration.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to check Calendly integration";
        state.isIntegrated = false;
      })
      .addCase(disconnectCalendly.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Disconnecting from Calendly...";
        state.isIntegrated = false;
      })
      .addCase(disconnectCalendly.fulfilled, (state) => {
        state.loading = false;
        state.isIntegrated = false;
      })
      .addCase(disconnectCalendly.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to disconnect Calendly";
        state.isIntegrated = true;
      })
      .addCase(fetchCalendlyMeetingNames.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Fetching Calendly meeting names...";
        state.trackedMeetingNames = [];
        state.selectedMeetingName = undefined;
      })
      .addCase(fetchCalendlyMeetingNames.fulfilled, (state, action) => {
        state.loading = false;
        state.trackedMeetingNames = action.payload;
        state.selectedMeetingName = undefined;
      })
      .addCase(fetchCalendlyMeetingNames.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to fetch Calendly meeting names";
        state.trackedMeetingNames = [];
        state.selectedMeetingName = undefined;
      })
      .addCase(updateCalendlyMeetingName.pending, (state) => {
        state.loading = true;
        state.loadingMsg = "Updating Calendly meeting name...";
      })
      .addCase(updateCalendlyMeetingName.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedMeetingName = action.meta.arg.meetingName;
      })
      .addCase(updateCalendlyMeetingName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to update Calendly meeting name";
      });
  },
});

export const { setCalendlyOAuthToken } = calendlySlice.actions;

export default calendlySlice.reducer;
