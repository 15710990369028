import styles from "../Calendar.module.scss";

import Flex from "./Flex";

import { getDayOfWeek, getMonthStart } from "./helpers/dates";
import {
  formatNarrowWeekday as defaultFormatShortWeekday,
  formatWeekday as defaultFormatWeekday,
} from "./helpers/dateFormatters";

import type { CalendarType, TileDisabledFunc } from "./types";
import { useCalendarContext } from "./hooks/useCalendarContext";
import { useMemo } from "react";

type WeekdaysProps = {
  /**
   * Type of calendar that should be used. Can be `'gregory`, `'hebrew'`, `'islamic'`, `'iso8601'`. Setting to `"gregory"` or `"hebrew"` will change the first day of the week to Sunday. Setting to `"islamic"` will change the first day of the week to Saturday. Setting to `"islamic"` or `"hebrew"` will make weekends appear on Friday to Saturday.
   *
   * @example 'iso8601'
   */
  calendarType: CalendarType | undefined;
  /**
   * Function called to override default formatting of weekday names (shortened). Can be used to use your own formatting function.
   *
   * @example (locale, date) => formatDate(date, 'dd')
   */
  formatShortWeekday?: typeof defaultFormatShortWeekday;
  /**
   * Function called to override default formatting of weekday names. Can be used to use your own formatting function.
   *
   * @example (locale, date) => formatDate(date, 'dd')
   */
  formatWeekday?: typeof defaultFormatWeekday;
  /**
   * Locale that should be used by the calendar. Can be any [IETF language tag](https://en.wikipedia.org/wiki/IETF_language_tag). **Note**: When using SSR, setting this prop may help resolving hydration errors caused by locale mismatch between server and client.
   *
   * @example 'hu-HU'
   */
  locale?: string;
  onMouseLeave?: () => void;
  activeStartDate: Date;
  shouldDisableDate?: TileDisabledFunc;
  disableWeekdaySelection?: boolean;
};

export default function Weekdays(props: WeekdaysProps): React.ReactElement {
  const {
    calendarType,
    formatShortWeekday = defaultFormatShortWeekday,
    formatWeekday = defaultFormatWeekday,
    locale,
    onMouseLeave,
    activeStartDate,
    shouldDisableDate,
    disableWeekdaySelection,
  } = props;

  return (
    <Flex
      className={styles.weekdays}
      count={7}
      onFocus={onMouseLeave}
      onMouseOver={onMouseLeave}
      data-testid="calendar-weekdays"
    >
      {Array(7)
        .fill(null)
        .map((_, i) => {
          const weekday = i + 1;

          return (
            <Weekday
              key={weekday}
              weekday={weekday}
              formatWeekday={formatWeekday}
              formatShortWeekday={formatShortWeekday}
              locale={locale}
              calendarType={calendarType}
              activeStartDate={activeStartDate}
              shouldDisableDate={shouldDisableDate}
              disableWeekdaySelection={disableWeekdaySelection}
            />
          );
        })}
    </Flex>
  );
}

const Weekday = ({
  weekday,
  formatWeekday,
  formatShortWeekday,
  locale,
  calendarType,
  activeStartDate,
  shouldDisableDate,
  disableWeekdaySelection,
}: {
  weekday: number;
  formatWeekday: (locale: string | undefined, date: Date) => string;
  formatShortWeekday: (locale: string | undefined, date: Date) => string;
  locale?: string;
  calendarType?: CalendarType;
  activeStartDate: Date;
  shouldDisableDate?: TileDisabledFunc;
  disableWeekdaySelection?: boolean;
}) => {
  const { selectedDatesMap, setSelectedDatesMap } = useCalendarContext();

  const { year, monthIndex, beginOfMonth } = useMemo(() => {
    const beginOfMonth = getMonthStart(activeStartDate || new Date());
    const year = beginOfMonth.getFullYear();
    const monthIndex = beginOfMonth.getMonth();
    return { beginOfMonth, year, monthIndex };
  }, [activeStartDate]);

  const isWeekDaySelected = useMemo(() => {
    if (disableWeekdaySelection) {
      return false;
    }
    const firstWeekdayInMonth = new Date(
      year,
      monthIndex,
      weekday - getDayOfWeek(beginOfMonth, calendarType)
    );

    const monthlyDaysOnWeekday: string[] = [];
    for (let i = 0; i < 6; i += 1) {
      const date = new Date(firstWeekdayInMonth);
      date.setDate(date.getDate() + i * 7);
      if (date.getMonth() === monthIndex) {
        monthlyDaysOnWeekday.push(date.toDateString());
      }
    }

    return monthlyDaysOnWeekday.every((date) => selectedDatesMap.has(date));
  }, [
    beginOfMonth,
    calendarType,
    monthIndex,
    selectedDatesMap,
    weekday,
    year,
    disableWeekdaySelection,
  ]);

  const onWeekdayClick = (weekday: number) => {
    if (disableWeekdaySelection) {
      return;
    }
    const newSelectedDatesMap = new Map(selectedDatesMap);
    const firstWeekdayInMonth = new Date(
      year,
      monthIndex,
      weekday - getDayOfWeek(beginOfMonth, calendarType)
    );

    for (let i = 0; i < 6; i += 1) {
      const date = new Date(firstWeekdayInMonth);
      date.setDate(date.getDate() + i * 7);
      if (date.getMonth() === monthIndex) {
        if (isWeekDaySelected) {
          newSelectedDatesMap.delete(date.toDateString());
        } else if (
          !shouldDisableDate ||
          !shouldDisableDate({ date, activeStartDate })
        ) {
          newSelectedDatesMap.set(date.toDateString(), [
            {
              start: "00:00",
              end: "24:00",
            },
          ]);
        }
      }
    }

    setSelectedDatesMap(newSelectedDatesMap);
  };

  const weekdayDate = new Date(
    year,
    monthIndex,
    weekday - getDayOfWeek(beginOfMonth, calendarType)
  );

  const abbr = formatWeekday(locale, weekdayDate);

  return (
    <button
      key={weekday}
      className={styles.weekday}
      onClick={() => onWeekdayClick(weekday)}
    >
      <abbr
        aria-label={abbr}
        title={/en/.test(locale || "en") ? `Select all ${abbr}s` : abbr}
        className={isWeekDaySelected ? styles.selected : ""}
      >
        {formatShortWeekday(locale, weekdayDate).replace(".", "")}
      </abbr>
    </button>
  );
};
