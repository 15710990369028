import React from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import { deleteUserAccount } from '../slices/AuthSlice';
import { useAppDispatch } from '../store';
import { PrimaryButton, TransparentButton } from '../subcomponents/CustomButton';
import { Colors } from '../Colors';

interface DeleteAccountModalProps {
  open: boolean;
  onClose: () => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    dispatch(deleteUserAccount())
      .then(() => {
        onClose();
        window.location.href = '/login';
      })
      .catch((error) => {
        console.error("Failed to delete account:", error);
      });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle }}>
        <Typography variant="h6" mb={2}>
          Confirm Account Deletion
        </Typography>
        <Typography variant="body1" mb={4}>
          Are you sure you want to delete your account? This action cannot be undone.
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <TransparentButton variant="contained" color="primary" onClick={onClose}>
            Cancel
          </TransparentButton>
          <PrimaryButton 
            variant="contained" 
            onClick={handleDelete} 
            sx={{
              background: Colors.error,
              '&:hover': {
                background: Colors.errorDark,
              }
            }}
          >
            Delete
          </PrimaryButton>
        </Box>
      </Box>
    </Modal>
  );
};

const modalStyle = {
  color: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: Colors.bg2,
  border: '2px solid #000',
  borderRadius: 8,
  boxShadow: 24,
  p: 4,
};

export default DeleteAccountModal;
