import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { Typography } from '@mui/material';
import { usePostHog } from 'posthog-js/react';

const PlayerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
`;

const Button = styled.button`
  background-color: transparent;
  color: black;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const Slider = styled.input`
  width: 100%;
  height: 3px;
  cursor: pointer;
`;

interface AudioScrubberProps {
  audioBuffer: AudioBuffer;
  audioContext: AudioContext;
}

const AudioScrubber: React.FC<AudioScrubberProps> = ({ audioBuffer, audioContext }) => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [playbackTime, setPlaybackTime] = useState<number>(0);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const sourceRef = useRef<AudioBufferSourceNode | null>(null);
  const gainNodeRef = useRef<GainNode | null>(audioContext.createGain());
  const startTimeRef = useRef<number | null>(null);
  const animationRef = useRef<number | null>(null);
  const posthog = usePostHog();

  useEffect(() => {
    if (gainNodeRef.current) {
      gainNodeRef.current.connect(audioContext.destination);
    }
  }, [audioContext]);

  const updatePlaybackTime = () => {
    if (audioContext && sourceRef.current && !audioContext.state.includes('suspended') && startTimeRef.current !== null) {
      const currentTime = audioContext.currentTime - startTimeRef.current;
      setPlaybackTime(currentTime);
      animationRef.current = requestAnimationFrame(updatePlaybackTime);
    }
  };

  const play = (time: number) => {
    posthog?.capture('[PENCILED] audio_played', { time });
    if (sourceRef.current) {
      sourceRef.current.disconnect();
      sourceRef.current.stop();
      cancelAnimationFrame(animationRef.current!);
    }

    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(gainNodeRef.current!);
    source.start(0, time);

    sourceRef.current = source;
    startTimeRef.current = audioContext.currentTime - time;

    source.onended = () => {
      setPlaybackTime(audioContext.currentTime - startTimeRef.current!);
      cancelAnimationFrame(animationRef.current!);
      setIsPlaying(false);
    };

    setIsPlaying(true);
    updatePlaybackTime();
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      pause();
    } else {
      play(playbackTime);
    }
  };

  const pause = () => {
    if (sourceRef.current) {
      sourceRef.current.stop();
      sourceRef.current.disconnect();
    }
    setIsPlaying(false);
  };

  const toggleMute = () => {
    if (gainNodeRef.current) {
      gainNodeRef.current.gain.value = isMuted ? 1 : 0;
      setIsMuted(!isMuted);
    }
  };

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newTime = parseFloat(event.target.value);
    setPlaybackTime(newTime);
  };

  const handleSliderCommit = () => {
    play(playbackTime);
  };

  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  return (
    <PlayerContainer>
      <Button style={{color :'#FFF'}} onClick={togglePlayPause}>{isPlaying ? <PauseIcon /> : <PlayArrowIcon/>}</Button>
      <Typography variant='body1' style={{display: 'flex', fontSize: '0.9rem'}}>
        {formatTime(playbackTime)} <div>/</div> {formatTime(audioBuffer.duration)}
      </Typography>
      <Slider
        type="range"
        min="0"
        max={audioBuffer.duration}
        value={playbackTime}
        step="0.01"
        onChange={handleSliderChange}
        onMouseUp={handleSliderCommit}
        onTouchEnd={handleSliderCommit}
      />
      
      <Button onClick={toggleMute} style={{color: '#FFF'}}>{isMuted ? <VolumeOffIcon /> : <VolumeUpIcon/>}</Button>
    </PlayerContainer>
  );
};

export default AudioScrubber;
