import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Agent, FrontendUser, FrontendScheduledCall, ScheduledCallStatus } from "../../types";
import { Typography, Tooltip, IconButton } from "@mui/material";
import { TransparentButton } from "../../subcomponents/CustomButton";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { Colors } from "../../Colors";
import { useSnackbar } from "../../providers/SnackbarProvider";
import { DateTime } from "luxon";
import CloseIcon from "@mui/icons-material/Close";
import { deleteScheduledCallThunk, runScheduledCallNow } from "../../slices/ScheduledCallSlice";
import LoadingWithMessage from "../../subcomponents/LoadingWithMessage";
import { convertKebabCaseToOfficial, convertSnakeCaseToOfficial } from "../../utils/utils";

interface ConversationQueueRowProps {
  agent: Agent;
  conversation: FrontendScheduledCall;
}

const ConversationQueueRow: React.FC<ConversationQueueRowProps> = ({ agent, conversation }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const callsRunning = useSelector((state: RootState) => state.scheduledCalls.callsRunning);
  const callsDeleted = useSelector((state: RootState) => state.scheduledCalls.callsDeleted);

  const runCallNowLoading = callsRunning[conversation.scheduledCallId] || false;
  const deletionLoading = callsDeleted[conversation.scheduledCallId] || false;
  const callIsInFuture = DateTime.fromISO(conversation?.scheduledDate) > DateTime.now();
  
  const [tooltipTitle, setTooltipTitle] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const dispatch = useAppDispatch();

  const { showMessage } = useSnackbar();

  useEffect(() => {
    const getTooltipTitle = () => {
      if (agent?.isActive === false) {
        return "Agent is not active.";
      }
      if (conversation.schedulingStatus && conversation.error) {
        return conversation.error;
      }
      return "";
    };

    setTooltipTitle(getTooltipTitle());
  }, [agent, conversation]);

  useEffect(() => {
    const isButtonDisabled = conversation.schedulingStatus !== ScheduledCallStatus.scheduled || agent?.isActive === false;
    setIsButtonDisabled(isButtonDisabled);
  }, [agent, conversation]);

  const handleRunCallNow = () => {
    try {
      if (!user?.token) {
        throw new Error("User token expired.");
      }
      dispatch(runScheduledCallNow({ conversation, token: user.token }));
    } catch (error) {
      console.error(error);
      showMessage("Error running call now", "error");
    }
  };

  const handleDeleteScheduledCall = async () => {
    try {
      if (!user?.token) {
        throw new Error("User token expired.");
      }
      dispatch(deleteScheduledCallThunk({ scheduledCallId: conversation?.scheduledCallId, token: user.token }));
      showMessage("Scheduled call deleted", "success");
    } catch (error) {
      console.error(error);
      showMessage("Error deleting scheduled call", "error");
    }
  };

  const scheduledDate: DateTime = DateTime.fromISO(conversation.scheduledDate);
  
  const formattedDate: string = scheduledDate.toFormat("MM/dd");
  const formattedTime: string = scheduledDate.toFormat("hh:mm a");

  return (
    <Box key={conversation?.scheduledCallId} sx={{ marginBottom: "20px" }}>
      <Box sx={{ display: "flex", alignItems: "center", padding: { xs: "0", sm: "0 10px" }, gap: "8%" }}>
        <Typography variant="body2" sx={{ color: Colors.grey3, fontSize: { xs: "0.75rem", sm: "0.9rem" } }}>
          <strong>{formattedDate}</strong> <br /> {formattedTime}
        </Typography>

        <div>
          { conversation.patientFirstName && conversation.patientLastName && (
            <Typography variant="h6" sx={{ fontSize: { xs: "1.1rem", sm: "1.4rem" } }}>
              {`${conversation.patientFirstName} ${conversation.patientLastName}`}
            </Typography>
          )}
          { conversation.caregiverName && (
            <Typography variant="h6" sx={{ fontSize: { xs: "1.1rem", sm: "1.4rem" } }}>
              {conversation.caregiverName}
            </Typography>
          )}
          <Typography variant="body1" sx={{ color: Colors.grey3, fontSize: { xs: "0.8rem", sm: "0.9rem" } }}>
            status: {convertKebabCaseToOfficial(conversation?.schedulingStatus) || ""}
            { conversation?.schedulingStatus === ScheduledCallStatus.schedulingError && conversation?.error && ` - ${conversation.error}` }
            { conversation?.schedulingStatus === ScheduledCallStatus.callingError && conversation?.error && ` - ${conversation.error}` }
            { conversation?.schedulingStatus === ScheduledCallStatus.queuingError && conversation?.error && ` - ${conversation.error}` }
          </Typography>
        </div>

        <div style={{ marginLeft: "auto" }}>
          {runCallNowLoading ? (
            <LoadingWithMessage message="Running call now..." size={15} customStyles={{fontSize: "0.9rem", padding: 0, whiteSpace: "nowrap"}} />
          ) : (
            callIsInFuture && (
              <Tooltip title={tooltipTitle}>
                <TransparentButton
                  onClick={handleRunCallNow}
                  disabled={isButtonDisabled}
                  sx={{ marginRight: { xs: "1px", md: "10px" }, padding: { xs: "5px 10px", md: "10px 20px" }, fontSize: { xs: "0.9rem", sm: "1rem" } }}
                >
                  Run now
                </TransparentButton>
              </Tooltip>
            )
          )}
          {deletionLoading ? (
            <LoadingWithMessage message="Deleting call..." size={15} customStyles={{ fontSize: "0.9rem", padding: 0 }} />
          ) : (
            !runCallNowLoading && (
              <Tooltip title={"Delete call"} placement="top">
                <IconButton
                  onClick={handleDeleteScheduledCall}
                  sx={{
                    padddingRigth: "30px",
                    color: "#FFF",
                  }}
                >
                  <CloseIcon
                    sx={{
                      fontSize: { xs: "1.5rem", sm: "1.8rem" },
                    }}
                  />
                </IconButton>
              </Tooltip>
            )
          )}
        </div>
      </Box>
    </Box>
  );
};

export default ConversationQueueRow;
