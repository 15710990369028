import React, { useState } from "react";
import { Colors } from "../Colors";
import { Box, IconButton, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { FooterBox, HeaderBox } from "../subcomponents/SidebarComponents";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Sidebar } from "../styles/GeneralStyles";

interface NumberTextFieldProps {
  minValue: number;
  maxValue: number;
  defaultValue?: number;
  onChange: (value: number) => void;
}

export const NumberTextField: React.FC<NumberTextFieldProps> = ({ minValue, maxValue, defaultValue = 0, onChange }) => {
  const [value, setValue] = useState<number | string>(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleBlur = () => {
    const numericValue = parseFloat(value as string);

    if (isNaN(numericValue)) {
      // If the value is not a number, reset it to the default or minValue
      setValue(defaultValue);
      onChange(defaultValue);
    } else if (numericValue < minValue) {
      setValue(minValue);
      onChange(minValue);
    } else if (numericValue > maxValue) {
      setValue(maxValue);
      onChange(maxValue);
    } else {
      setValue(numericValue);
      onChange(numericValue);
    }
  };

  return (
    <TextField
      type="number"
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      variant="outlined"
      sx={{
        width: "80px",
        background: Colors.textfield,
        borderRadius: "10px",
        "& .MuiInputBase-input": {
          color: "#FFFFFF",
        },
        "& .MuiInputLabel-root": {
          color: Colors.info,
        },
        "& .MuiSvgIcon-root": {
          color: Colors.info,
        },
      }}
    />
  );
};

type PriorityModalProps<T> = {
  title: string;
  sortedItems: T[];
  open: boolean;
  onSuccess: (newGroups: T[]) => void;
  onClose: () => void;
  getItemId: (item: T) => string;
  getItemName: (item: T) => string;
};

const PriorityModal = <T,>({ title, sortedItems, open, onSuccess, onClose, getItemId, getItemName }: PriorityModalProps<T>) => {
  const [items, setItems] = React.useState<T[]>(sortedItems);

  function moveElement(array: T[], fromIndex: number, toIndex: number): T[] {
    const newArray = [...array]; // Create a copy of the array
    const [element] = newArray.splice(fromIndex, 1); // Remove the element from the fromIndex
    newArray.splice(toIndex, 0, element); // Insert the element at the toIndex
    return newArray;
  }

  const moveRowUp = (index: number) => {
    if (index === 0) return;
    setItems(moveElement(items, index, index - 1));
  };

  const moveRowDown = (index: number) => {
    if (index === items.length - 1) return;
    setItems(moveElement(items, index, index + 1));
  };

  const handlePriorityChange = (fromIndex: number, toIndex: number) => {
    if (toIndex < 0 || toIndex >= items.length) return;
    setItems(moveElement(items, fromIndex, toIndex));
  };

  const columnTypes = ["Priority", "Name"];

  return (
    <Sidebar className={open ? "open" : ""}>
      <Box
        sx={{
          overflowY: "auto",
          height: "92%",
          paddingBottom: "40px",
          paddingRight: "20px",
          background: "transparent",
          borderRadius: "10px",
        }}
      >
        <HeaderBox title={title} onClose={onClose} />

        <Table
          sx={{
            width: "100%",
            marginTop: "30px",
            background: Colors.bg2,
            borderRadius: "15px",
            "& .MuiTableCell-root": {
              color: "#FFF",
              borderColor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <TableHead>
            <TableRow>
              {columnTypes.map((type, index) => (
                <TableCell key={index} sx={{ color: "#FFF", fontWeight: "bold", borderRadius: "8px 8px 0 0" }}>
                  {type}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((item, index) => (
              <TableRow key={getItemId(item)}>
                <TableCell>
                  <NumberTextField
                    key={index}
                    minValue={1}
                    maxValue={items.length}
                    defaultValue={index + 1}
                    onChange={(value) => handlePriorityChange(index, value - 1)}
                  />
                  <IconButton
                    onClick={() => moveRowUp(index)}
                    disabled={index === 0}
                    sx={{
                      color: index === 0 ? "#555" : "#FFF",
                    }}
                  >
                    <KeyboardArrowUpIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => moveRowDown(index)}
                    disabled={index === items.length - 1}
                    sx={{
                      color: index === items.length - 1 ? "#555" : "#FFF",
                    }}
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </TableCell>
                <TableCell>{getItemName(item)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <FooterBox
        onClick={() => onSuccess(items)}
        disabled={
          sortedItems.length === items.length &&
          sortedItems.every((item, index) => item === items[index])
        }
        buttonText="Set Priority"
        loading={false}
      />
    </Sidebar>
  );
};

export default PriorityModal;
