import { useEffect, createContext, useContext, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState, AppDispatch, useAppDispatch } from "../store";
import { setUser, clearUser, setCheckingAuth, signOut, setToken, setOrganization } from "../slices/AuthSlice";
import { CognitoUserSession, CognitoUser, CognitoRefreshToken } from "amazon-cognito-identity-js";
import { userPool } from "../AuthUtils/CognitoConfig";
import { AuthState, FrontendUser, Organization } from "../types";
import SessionExpiredModal from "./SessionExpiredModal";
import { sessionExpired } from "../slices/SessionSlice";
import Loader from "../subcomponents/Loader";
import { api } from "../utils/utils";
import { useNavigate } from "react-router-dom";

interface AuthContextType {
  auth: AuthState;
  dispatch: AppDispatch;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const useAuth = () => {
  return useContext(AuthContext);
};

const LAST_ACTIVE_KEY = "lastActive";
const IDLE_TIMEOUT = 30 * 60 * 1000 * 1000000; // 30 minutes // TODO (Hizami): I multiplied this by 1000000 so it doesn't slow down dev time
const REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes

let LAST_USER_ID = "";
let LAST_REFRESH = 0;

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const authState = useSelector((state: RootState) => state.auth);
  const dispatch = useAppDispatch();
  const isSessionExpired: boolean = useSelector((state: RootState) => state.session.sessionExpired);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      sessionStorage.removeItem("sessionStartTime");
      dispatch(sessionExpired(true));
      dispatch(signOut());
      navigate("/login");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  useEffect(() => {
    // Refresh the Cognito token every 30 minutes
    const intervalId = setInterval(refreshCognitoToken, REFRESH_INTERVAL);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();

    if (cognitoUser) {
      refreshCognitoSession(cognitoUser);
    } else {
      dispatch(clearUser());
    }

    dispatch(setCheckingAuth(false));

    const handleStorageChange = async (event: StorageEvent) => {
      if (event.key === "cognitoUserChange") {
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
          refreshCognitoSession(cognitoUser);
        } else {
          dispatch(clearUser());
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dispatch]);

  const user = useSelector((state: RootState) => state.auth.user);
  useEffect(() => {
    let idleTimeout: NodeJS.Timeout;

    const resetIdleTimeout = () => {
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }
      idleTimeout = setTimeout(checkIdleState, 60 * 1000); // Check idle state every minute
    };

    const handleUserActivity = () => {
      updateLastActive();
      if (user) {
        resetIdleTimeout();
      }
    };

    const checkIdleState = async () => {
      const lastActive = getLastActive();
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        if (user && user.userId == cognitoUser.getUsername()) {
          if (cognitoUser && Date.now() - lastActive > IDLE_TIMEOUT) {
            // Idle timeout reached
            handleLogout();
          } else {
            // User is logged in and active
            resetIdleTimeout();
          }
        } else {
          // Mismatch between local storage and redux, refresh session
          refreshCognitoSession(cognitoUser);
        }
      } else {
        if (user) {
          // Current session is expired
          handleLogout();
        } else {
          // User is logged out
          resetIdleTimeout();
        }
      }
    };

    // Initialize last active timestamp and reset timeout
    checkIdleState();
    updateLastActive();

    const events = ["mousemove", "keydown", "scroll", "touchstart"];

    events.forEach((event) => {
      window.addEventListener(event, handleUserActivity);
    });

    // Cleanup
    return () => {
      if (idleTimeout) {
        clearTimeout(idleTimeout);
      }
      events.forEach((event) => {
        window.removeEventListener(event, handleUserActivity);
      });
    };
  }, [user]);

  const sendGetSessionRequest = async (cognitoUser: CognitoUser) => {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      cognitoUser.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
        if (session) {
          resolve(session);
        } else {
          reject(err);
        }
      });
    });
  };

  const sendRefreshRequest = async (cognitoUser: CognitoUser, refreshToken: CognitoRefreshToken) => {
    return new Promise<CognitoUserSession>((resolve, reject) => {
      cognitoUser.refreshSession(refreshToken, async (err: Error | null, session: CognitoUserSession | null) => {
        if (session) {
          resolve(session);
        } else {
          reject(err);
        }
      });
    });
  };

  const refreshCognitoSession = async (cognitoUser: CognitoUser) => {
    // Don't refresh the session if the user is the same and the last refresh was less than a minute ago
    if (cognitoUser.getUsername() === LAST_USER_ID && Date.now() - LAST_REFRESH < 1000 * 60) {
      return;
    }
    LAST_USER_ID = cognitoUser.getUsername();
    LAST_REFRESH = Date.now();
    try {
      dispatch(setCheckingAuth(true));
      const session = await sendGetSessionRequest(cognitoUser);
      if (session) {
        const refreshToken = session.getRefreshToken();
        if (refreshToken) {
          const refreshedSession = await sendRefreshRequest(cognitoUser, refreshToken);
          const { user, organization } = await mapCognitoSessionToUser(refreshedSession);
          dispatch(setUser(user));
          dispatch(setOrganization(organization));
        } else {
          dispatch(signOut());
        }
      } else {
        dispatch(signOut());
      }
    } catch (error) {
      console.error("Error refreshing session", error);
      dispatch(signOut());
    } finally {
      dispatch(setCheckingAuth(false));
    }
  };

  const refreshCognitoToken = async () => {
    const cognitoUser = userPool.getCurrentUser();
    if (cognitoUser) {
      const session = await sendGetSessionRequest(cognitoUser);
      if (session) {
        const refreshToken = session.getRefreshToken();
        if (refreshToken) {
          const refreshedSession = await sendRefreshRequest(cognitoUser, refreshToken);
          dispatch(setToken(refreshedSession.getIdToken().getJwtToken()));
        }
      }
    }
  };

  // Always return a JSX element
  if (authState.checkingAuth) {
    return <Loader message="" size={40} />;
  }

  return (
    <AuthContext.Provider value={{ auth: authState, dispatch }}>
      {children}
      <SessionExpiredModal />
    </AuthContext.Provider>
  );
};

const mapCognitoSessionToUser = async (session: CognitoUserSession): Promise<{ user: FrontendUser; organization: Organization }> => {
  const token = session.getIdToken().getJwtToken();
  const response = await api.get("/users/session", token);
  const user: FrontendUser = { ...response.data.user, token } as FrontendUser;
  const organization = response.data.organization;

  return { user, organization };
};

const updateLastActive = () => {
  localStorage.setItem(LAST_ACTIVE_KEY, Date.now().toString());
};

const getLastActive = () => {
  const lastActive = localStorage.getItem(LAST_ACTIVE_KEY);
  return lastActive ? parseInt(lastActive, 10) : Date.now();
};
