import React, { useState, useCallback } from "react";
import { Box, Typography, FormControl, OutlinedInput, Tooltip, IconButton, Button, Modal } from "@mui/material";
import { PrimaryButton } from "../subcomponents/CustomButton";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DateTime } from "luxon";
import { useSnackbar } from "../providers/SnackbarProvider";
import { FrontendUser } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { usePostHog } from "posthog-js/react";
import { useParams } from "react-router-dom";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { ModalBox } from "../styles/GeneralStyles";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { TransparentButton } from "../subcomponents/CustomButton";
import { Colors } from "../Colors";
import CloseIcon from '@mui/icons-material/Close';
import { HeaderBox } from "../subcomponents/SidebarComponents";

interface UploadCSVModalProps {
  onClose: () => void;
  onUpload: (file: File) => void;
};

const UploadCSVModal: React.FC<UploadCSVModalProps> = ({ onClose, onUpload }) => {
  const { agentId } = useParams<{ agentId: string }>();
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const agent = useSelector((state: RootState) => state.agents.agents.find((agent) => agent.agentId === agentId));
  const { showMessage } = useSnackbar();
  const [localErrorMsg, setLocalErrorMsg] = useState<string>("");
  const posthog = usePostHog();
  const [schedulingCallLoading, setSchedulingCallLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  // Handler for drag and drop
  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    posthog?.capture('[PENCILED] dropped_csv_file', { userId: user?.userId });
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      onUpload(file);
    }
  }, [onUpload]);

  // Handler for file input change
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    posthog?.capture('[PENCILED] click_upload_csv_then_dragged_file', { userId: user?.userId });
    const files: FileList | null = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      onUpload(file);
    }
  }, [onUpload]);

  // Prevent default behavior (Prevent file from being opened)
  const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  return (
    <Box 
      sx={{
        overflowY: 'auto',
        height: '100%',
        paddingBottom: '20px',   
        paddingRight: '20px',  
      }}    
    >

      <HeaderBox
        title={'Upload CSV'}
        onClose={onClose}
      />

      <Box mb={2} marginTop="60px">
        <Typography variant="body1" fontWeight={'bold'} fontSize={'1.1rem'}>
            Import patients from a csv file
        </Typography>
        <a href="https://penciled.com/blogs/how-to-upload-csv" style={{color: Colors.primary, fontSize: '0.85rem'}}>
          How do I upload a CSV?
        </a>
      </Box>

      <label htmlFor="file-input">
        <input
          style={{ display: 'none' }}
          id="file-input"
          type="file"
          accept=".csv"
          onChange={handleChange}
        />
        <Box
          sx={{
            border: '1px dashed grey',
            padding: 5,
            borderRadius: 2,
            cursor: 'pointer',
            ':hover': {
              borderColor: 'primary.main',
              backgroundColor: 'primary.lighter'
            }
          }}
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <IconButton component="center" style={{display: "flex", justifyContent: "center", color: "grey"}}>
            <UploadFileIcon sx={{ fontSize: 60 }} />
          </IconButton>
          <Typography variant="body1" style={{textAlign: "center"}}>Click to upload or drag files here</Typography>
        </Box>
  
      </label>
    </Box>
  );
};

export default UploadCSVModal;
