import React, { useEffect, useRef, useState } from 'react';
import { Box, Modal, Typography, FormControl, OutlinedInput, Button, CircularProgress, FormControlLabel, Switch } from '@mui/material';
import { PrimaryButton, TransparentButton } from '../subcomponents/CustomButton'; // Update the import path as needed
import { FrontendUser, Webhook } from '../types';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { DateTime } from 'luxon';

interface AddWebhookModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (webhookUrl: string, name: string, recordingEnabled: boolean) => void;
}

const AddWebhookModal: React.FC<AddWebhookModalProps> = ({ open, onClose, onSubmit }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const errorMsg: string | undefined = useSelector((state: RootState) => state.webhooks.errorMsg);
  const loading: boolean = useSelector((state: RootState) => state.webhooks.loading);
  const [webhookUrl, setWebhookUrl] = useState('');
  const [name, setName] = useState(user?.firstName ? `${user.firstName}'s Webhook ${DateTime.now().toFormat("M/d/yy")}` : '');
  const [recordingEnabled, setRecordingEnabled] = useState(false);
  const [localErrorMsg, setLocalErrorMsg] = useState<string | null>(null);
  const wasLoading = useRef(false);

  const handleSubmit = () => {
    if (!webhookUrl || !name) {
      setLocalErrorMsg("Webhook URL and name are required");
      return;
    }

    onSubmit(webhookUrl, name, recordingEnabled);
  };

  // when loading and switching to not loading, close the modal
  useEffect(() => {
    if (wasLoading.current && !loading && !errorMsg) {
      setWebhookUrl('');
      setName('');
      setRecordingEnabled(false);
      onClose();
    }
    wasLoading.current = loading;
  }, [loading, onClose]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ overflowY: "auto", height: "100%" }}>
        <Box
          sx={{
            backgroundColor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxWidth: 500,
            margin: '100px auto',
          }}
        >
          <Typography variant="h4" fontWeight="bold" marginBottom="30px" sx={{ fontSize: { xs: "1.6rem", sm: "2rem" } }}>
            Add Webhook
          </Typography>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>Webhook URL</Typography>
            <OutlinedInput
              placeholder="https://api.yourbackend.com/webhook"
              style={{ borderRadius: "10px", fontSize: "14px" }}
              onChange={(e) => setWebhookUrl(e.target.value)}
              value={webhookUrl}
              required
            />
          </FormControl>

          <FormControl fullWidth sx={{ mt: 3 }}>
            <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>Webhook Name</Typography>
            <OutlinedInput
              placeholder="My Webhook"
              style={{ borderRadius: "10px", fontSize: "14px" }}
              onChange={(e) => setName(e.target.value)}
              value={name}
              required
            />
          </FormControl>

          <FormControlLabel
            control={
              <Switch
                checked={recordingEnabled}
                onChange={(e) => setRecordingEnabled(e.target.checked)}
                color="primary"
              />
            }
            label="Enable Payload Recordings"
            sx={{ mt: 3 }}
          />

          { (localErrorMsg || errorMsg) && <Typography color="error">{localErrorMsg || errorMsg}</Typography> }

          <Box sx={{ mt: 3, display: "flex", justifyContent: "right" }}>
            <PrimaryButton onClick={handleSubmit} variant="contained" color="primary" fullWidth={false}>
              {loading ? <CircularProgress size={24} color="inherit" /> : "Add Webhook"}
            </PrimaryButton>

            <TransparentButton onClick={onClose} variant="outlined" color="primary" fullWidth={false} sx={{ marginLeft: "10px" }}>
              Cancel
            </TransparentButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddWebhookModal;
