import React, { useEffect, useState } from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';
import SignoutHeader from '../subcomponents/SignoutHeader';
import APIKeys from './APIKeys';
import Webhooks from './Webhooks';
import { Colors } from '../Colors';

const Developers: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <Box sx={{ marginTop: { xs: '75px', sm: 0 }, color: "#FFF" }}>
      <SignoutHeader />
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '0px',  marginTop: { xs: "75px", lg: "20px" } }}>
        <Typography variant='h5' sx={{ color: "#FFF", fontSize: { xs: '1.3rem', sm: '1.8rem' } }}>
          Developers
        </Typography>
      </Box>
      <Tabs 
        value={selectedTab} 
        onChange={handleTabChange} 
        aria-label="Developer tabs" 
        sx={{ marginBottom: "15px", '& .MuiTabs-flexContainer': { justifyContent: 'flex-start' } }}
        TabIndicatorProps={{sx: { display: 'none' }}}
      >
        <Tab 
          label="API Keys" 
          sx={{ 
            textTransform: "none", 
            minWidth: 0, 
            padding: 0,
            marginRight: "30px", 
            position: 'relative',
            '&:hover': { 
              backgroundColor: 'transparent' 
            },
            color: selectedTab === 0 ? "#FFF" : Colors.bgGrey,
            '&.Mui-selected': {
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '5px',
                left: 0,
                right: 0,
                borderBottom: '2px solid',
                borderColor: 'currentColor',
              },
            },
          }}
        />
        <Tab 
          label="Webhooks" 
          sx={{ 
            textTransform: "none", 
            minWidth: 0, 
            padding: 0, 
            position: 'relative',
            '&:hover': { 
              backgroundColor: 'transparent' 
            },
            color: selectedTab === 0 ? "#FFF" : Colors.bgGrey,
            '&.Mui-selected': {
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: '5px',
                left: 0,
                right: 0,
                borderBottom: '2px solid',
                borderColor: 'currentColor',
              },
            },
          }}
        />
      </Tabs>
      <Box sx={{ borderBottom: '0.5px solid grey', marginBottom: '15px', marginTop: '-20px' }} />
      <Box sx={{ padding: '10px 0' }}>
        {selectedTab === 0 && <APIKeys />}
        {selectedTab === 1 && <Webhooks />}
      </Box>
    </Box>
  );
};

export default Developers;
