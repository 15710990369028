import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import SignoutHeader from "../subcomponents/SignoutHeader";
import IntegrationCardWithToken from "../subcomponents/IntegrationCardWithToken";
import CalendlyIntegrationCard from "../components/cards/CalendlyIntegrationCard";
import { EHRLogos } from "../utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import styled from "styled-components";
import Loader from "../subcomponents/Loader";
import { EHR, Organization } from "../types";
import { ReactComponent as CalendlyIntegrationLogo } from "../assets/calendly-logo-vector.svg";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Integrations: React.FC = () => {

  return (
    <Box>
      <SignoutHeader />
      <Typography variant="h5" sx={{ margin: { xs: "80px 0 10px 0", lg: "20px 0" }, fontSize: { xs: "1.3rem", sm: "1.8rem" }, color: '#FFF' }}>
        Integrations
      </Typography>
      <Container>
        <IntegrationCardWithToken
          ehr={EHR.healthie}
        />
        <CalendlyIntegrationCard
          Icon={CalendlyIntegrationLogo}
          name="Calendly"
          subtitle="Schedule meetings with clients"
        />
      </Container>
    </Box>
  );
};

export default Integrations;
