import React, { FocusEventHandler, useCallback, useEffect, useState } from "react";
import { Modal, Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, IconButton, Icon, TextField } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const get_patients_config = {
  start: 0,
  limit: 10, // arbitrary large number
  total: "",
  userId: "",
  patientName: "",
  patientNameGiven: "",
  patientNameFamily: "",
  birthDate: "0000-00-00",
  physicianName: "",
  payerName: "",
  payerType: "",
  patientStatus: "A",
  caseStatus: "",
  searchType: 1,
  changeSearchType: 0,
  identificationNumber: "",
  expiredType: "",
  filter: "",
  caseDischargeDateFrom: "",
  caseDischargeDateTo: "",
};

const Debug: React.FC = () => {
  const [patients, setPatients] = useState<any[]>([]);

  async function getPatients() {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/webpt/patients`);
    console.log(response.data);
    setPatients(response.data.data);
  }

  useEffect(() => {
    //getPatients();
  }, []);

  return (
    <div>
      {/*patients.map((patient, index) => (
        <div key={index}>{JSON.stringify(patient)}</div>
      ))*/}
      <Button onClick={getPatients}>Get Patients</Button>
    </div>
  );
};

export default Debug;
