import React from "react";
import { Box } from "@mui/material";
import OnboardingSteps from "../forms/OnboardingSteps";
import SignoutHeader from "../subcomponents/SignoutHeader";

const GetStarted: React.FC = () => {
  return (
    <React.Fragment>
      <SignoutHeader />
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "10vh" }}>
        <OnboardingSteps />
      </Box>
    </React.Fragment>
  );
};

export default GetStarted;
