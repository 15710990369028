import { Card, CardContent, IconButton, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

interface ChoiceCardProps {
  icon: React.JSX.Element;
  title: string;
  description: string;
  nextRoute: string;
  isSelected: boolean;
  onClick: () => void;
}

const StyledCard = styled(Card)<{ isSelected: boolean }>`
  border-radius: 10px;
  margin: 1%;
  height: 225px;
  width: 100%;
  padding: 15px 0;
  cursor: pointer;
  border: 1px solid #dddddd;
  background-color: ${({ isSelected }) => (isSelected ? "#EFF2FF" : "#FFFFFF")} !important;

  &:hover {
    // transform: translateY(-2px);
    background-color: #eff2ff;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 10px 0;
  }
`;

const CustomTitle = styled(Typography)`
  font-size: 1.3rem;
  margin: 10px;
`;

const CustomIcon = styled(IconButton)`
  margin-bottom: 10px;
`;

const ChoiceCard: React.FC<ChoiceCardProps> = ({ icon, title, description, isSelected, onClick }) => {
  // TODO (Hizami): Are we supposed to be using theme colors here?
  //const theme = useTheme();

  return (
    <StyledCard isSelected={isSelected} onClick={onClick}>
      <CardContent>
        <CustomIcon size="large" sx={{ fontSize: "1.25rem", color: "blue" }}>
          {icon}
        </CustomIcon>
        <CustomTitle variant="h6" gutterBottom>
          {title}
        </CustomTitle>
        <Typography variant="body1" color="textSecondary" margin="10px">
          {description}
        </Typography>
      </CardContent>
    </StyledCard>
  );
};

export default ChoiceCard;
