import { FunctionComponent, useEffect, useState } from "react";
import styles from "./Calendar.module.scss";
import { CalendarType, DateTimeObject, TileDisabledFunc } from "./utils/types";
import { CalendarProvider, useCalendarContext } from "./utils/hooks/useCalendarContext";
import DaysView from "./utils/DaysView";
import HoursView from "./utils/HoursView";
import { formatInputPeriod } from "./utils/helpers/dateFormatters";

export interface CalendarProps {
  dateTimes: DateTimeObject[];
  onDateTimesChange: (dateTimes: DateTimeObject[]) => void;
  onCancel?: () => void;
  onSubmit?: () => void;
  /**
   * Type of calendar that should be used.
   * Can be `'gregory`, `'hebrew'`, `'islamic'`, `'iso8601'`.
   * Setting to `"gregory"` or `"hebrew"` will change the first day of the week to Sunday.
   * Setting to `"islamic"` will change the first day of the week to Saturday.
   * Setting to `"islamic"` or `"hebrew"` will make weekends appear on Friday to Saturday.
   *
   * @example 'iso8601'
   */
  calendarType?: CalendarType;
  formatShortWeekday?: (locale: string | undefined, date: Date) => string;
  formatWeekday?: (locale: string | undefined, date: Date) => string;
  /**
   * Time mode for the times-picker. Can be `"12"` or `"24"`. Defaults to `"12"`.
   */
  timeMode?: "12" | "24";
  /**
   * Start time for the times-picker. Defaults to 6 (as in 6:00 AM).
   */
  gridStart?: number;
  /**
   * Calendar view shown. Can be `"days"` or `"times"`. Defaults to `"days"`
   */
  calendarView?: "days" | "times";
  locale?: string;
  activeStartDate?: Date;
  showNeighboringMonth?: boolean;
  shouldDisableDate?: TileDisabledFunc;
  disableWeekdaySelection?: boolean;
  disableGestureSelection?: boolean;
  disableDaysView?: boolean;
  disableTimesView?: boolean;
}

type CalendarView = "days" | "times";
const Calendar: FunctionComponent<CalendarProps> = (props) => {
  const [currentView, setCurrentView] = useState<CalendarView>(props.calendarView || (props.disableDaysView ? "times" : "days"));

  const { setSelectedDatesMap } = useCalendarContext();

  useEffect(() => {
    setCurrentView(props.calendarView || "days");
  }, [props.calendarView]);

  useEffect(() => {
    if (props.disableDaysView && props.disableTimesView) {
      console.warn("disableDaysView and disableTimesView cannot both be true. Defaulting to days view.");
      return;
    }
    setCurrentView(props.calendarView || (props.disableDaysView ? "times" : "days"));
  }, [props.calendarView, props.disableDaysView, props.disableTimesView]);

  useEffect(() => {
    setSelectedDatesMap(
      new Map(
        props.dateTimes.reduce((map, dateTime) => {
          map.set(dateTime.date, [...(map.get(dateTime.date) || []), formatInputPeriod(dateTime.time)]);
          return map;
        }, new Map())
      )
    );
  }, [props.dateTimes, setSelectedDatesMap]);
  return (
    <div className={styles.wrapper}>
      {!props.disableDaysView && !props.disableTimesView && (
        <>
          <div className={styles["view-selector"]}>
            <button
              className={[styles["view-selector-button"], currentView === "days" ? styles.active : ""].join(" ")}
              onClick={() => setCurrentView("days")}
            >
              Days
            </button>
            <button
              className={[styles["view-selector-button"], currentView === "times" ? styles.active : ""].join(" ")}
              onClick={() => setCurrentView("times")}
            >
              Times
            </button>
          </div>
          <hr className={styles.hr} />
        </>
      )}
      {currentView === "days" ? !props.disableDaysView && <DaysView {...props} /> : !props.disableTimesView && <HoursView {...props} />}
    </div>
  );
};

export default Calendar;
