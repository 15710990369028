import React, { ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Colors } from '../Colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.primary,
      dark: Colors.primaryDark,
    },
    secondary: {
      main: Colors.secondary,
      dark: Colors.secondaryDark,
    },
    info: {
      main: Colors.tertiary,
      dark: Colors.tertiaryDark,
    },
    success: {
      main: Colors.success,
    },
    error: {
      main: Colors.error,
    },
    warning: {
      main: Colors.warning,
    },
    action: {
      disabledBackground: Colors.bgGrey,
      disabled: Colors.grey3,
    },
  },
  typography: {
    fontFamily: 'Foundry-Buch',
    h1: {
      fontFamily: 'Konnect-Bold',
    },
    h2: {
      fontFamily: 'Konnect-Bold',
    },
    h3: {
      fontFamily: 'Konnect-SemiBold',
    },
    h4: {
      fontFamily: 'Konnect-SemiBold',
    },
    h5: {
      fontFamily: 'Konnect-SemiBold',
    },
    h6: {
      fontFamily: 'Konnect-SemiBold',
    },
    body1: {
      fontFamily: 'Foundry-Buch',
    },
    body2: {
      fontFamily: 'Foundry-Buch',
    },
    subtitle1: {
      fontFamily: 'Konnect-Regular',
    },
    subtitle2: {
      fontFamily: 'Konnect-Regular',
    },
    caption: {
      fontFamily: 'Foundry-Buch',
    },
    button: {
      fontFamily: 'Konnect-Medium',
    },
  },
});

interface ThemeProviderWrapperProps {
  children: ReactNode;
}

const ThemeProviderWrapper: React.FC<ThemeProviderWrapperProps> = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default ThemeProviderWrapper;
