import React, { useEffect, useState } from "react";
import { Box, Typography, Checkbox, FormControlLabel, useMediaQuery, IconButton, Modal, Button } from "@mui/material";
import styled from "styled-components";
import { DateTime } from "luxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro/models";
import { PrimaryButton, SecondaryButton } from "../subcomponents/CustomButton";
import { RootState } from "../store";
import { Agent, FrontendUser, HealthieProvider, Organization } from "../types";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { ModalBox } from "../styles/GeneralStyles";
import LoadingWithMessage from "../subcomponents/LoadingWithMessage";
import { usePostHog } from "posthog-js/react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
  {
    label: "This Week",
    getValue: () => {
      const today = DateTime.local();
      return [DateTime.now(), today.endOf("week")];
    },
  },
  {
    label: "Next Week",
    getValue: () => {
      const today = DateTime.local();
      const nextWeek = today.plus({ weeks: 1 });
      return [nextWeek.startOf("week"), nextWeek.endOf("week")];
    },
  },
  {
    label: "Next 7 Days",
    getValue: () => {
      const today = DateTime.local();
      return [today.plus({ days: 7 }), today];
    },
  },
  {
    label: "Current Month",
    getValue: () => {
      const today = DateTime.local();
      return [today.startOf("month"), today.endOf("month")];
    },
  },
  { label: "Reset", getValue: () => [null, null] },
];

type FilterWaitlistModalProps = {
  open: boolean;
  dateRange: [DateTime | null, DateTime | null];
  setDateRange: (dates: [DateTime | null, DateTime | null]) => void;
  providers: any[];
  setProviderFilter: (providerFilter: HealthieProvider[]) => void;
  onClose: () => void;
};

const FilterWaitlistModal: React.FC<FilterWaitlistModalProps> = ({ open, dateRange, setDateRange, providers, setProviderFilter, onClose }) => {
  const user: FrontendUser | null = useSelector((state: RootState) => state.auth.user);
  const organization: Organization | null = useSelector((state: RootState) => state.auth.organization);
  let healthieProviders = useSelector((state: RootState) => state.healthieIntegration.providers).map((provider) => ({
    id: provider.id,
    name: `${provider.firstName} ${provider.lastName}`,
  }));
  if (organization?.healthieProviderIds) {
    healthieProviders = healthieProviders.filter((provider) => organization.healthieProviderIds?.includes(provider.id));
  }
  const webptProviders = useSelector((state: RootState) => state.webptIntegration.providers);
  const allProviders = [...healthieProviders, ...webptProviders];
  const loadingProviders: boolean = useSelector((state: RootState) => state.healthieIntegration.loadingProviders);
  const [localDateRange, setLocalDateRange] = useState<[DateTime | null, DateTime | null]>([DateTime.now().plus({ weeks: 1 }), DateTime.now()]);
  const posthog = usePostHog();

  useEffect(() => {
    const today = DateTime.now().startOf("day");

    // Compare only the date part, not the time
    if ((dateRange[0] && dateRange[0].startOf("day") < today) || (localDateRange[0] && localDateRange[0].startOf("day") < today)) {
      setLocalDateRange([today, dateRange[1]]);
    }
  }, [localDateRange, dateRange]);

  useEffect(() => {
    setLocalDateRange(dateRange);
  }, [dateRange]);

  const [selectedProviders, setSelectedProviders] = useState<any[]>(providers);
  const selectAllProviders = () => {
    setSelectedProviders(allProviders);
  };
  const unselectAllProviders = () => {
    setSelectedProviders([]);
  };

  const isLargeScreen = useMediaQuery("(min-width:800px)");

  const handleDateChange = (value: [DateTime | null, DateTime | null]) => {
    const [start, end] = value;
    setLocalDateRange([start, end]); // Directly update local date range

    posthog?.capture("[PENCILED] Filtering waitlist by date range", {
      organizationId: user?.organizationId,
      orgName: organization?.name,
      startDate: start?.toISO(),
      endDate: end?.toISO(),
      numberOfDaysFiltered: start && end ? end.diff(start, "days").days : 0,
    });
  };

  return (
    <ModalBox>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <StaticDateRangePicker
          slotProps={{
            shortcuts: isLargeScreen ? { items: shortcutsItems } : undefined,
            actionBar: { actions: [] },
          }}
          calendars={1}
          onChange={handleDateChange}
          sx={{
            "@media (max-width: 768px)": {
              display: "flex",
              flexDirection: "column",
              marginLeft: "-20px",
            },
          }}
          value={localDateRange}
        />
      </LocalizationProvider>

      <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", gap: "5px" }}>
        <Typography variant="h6"> Providers </Typography>
        <Typography variant="subtitle2"> (select multiple) </Typography>
        <Button onClick={selectAllProviders}>Select All</Button>
        <Button onClick={unselectAllProviders}>Unselect All</Button>
      </div>
      <Container>
        {loadingProviders && <LoadingWithMessage message="Loading providers..." size={20} />}
        {allProviders.map((provider) => (
          <FormControlLabel
            key={provider.id}
            control={
              <Checkbox
                checked={selectedProviders?.map((provider) => provider.id).includes(provider.id) || false}
                onChange={(e) => {
                  if (e.target.checked) {
                    posthog?.capture("[PENCILED] Filtering for provider", {
                      organizationId: user?.organizationId,
                      orgName: organization?.name,
                      providerId: provider.id,
                    });
                  } else {
                    posthog?.capture("[PENCILED] Filtering out provider", {
                      organizationId: user?.organizationId,
                      orgName: organization?.name,
                      providerId: provider.id,
                    });
                  }

                  setSelectedProviders((prev) => {
                    if (e.target.checked) {
                      return [...prev, provider];
                    } else {
                      return prev.filter((p) => p.id !== provider.id);
                    }
                  });
                }}
              />
            }
            label={provider.name}
          />
        ))}
      </Container>

      <PrimaryButton
        variant="contained"
        onClick={() => {
          posthog?.capture("[PENCILED] Applying waitlist filters", {
            organizationId: user?.organizationId,
            orgName: organization?.name,
            startDate: localDateRange[0]?.toISO(),
            endDate: localDateRange[1]?.toISO(),
            numberOfDaysFiltered: localDateRange[0] && localDateRange[1] ? localDateRange[1].diff(localDateRange[0], "days").days : 0,
            providerIds: selectedProviders.map((provider: HealthieProvider) => provider.id),
          });

          setProviderFilter(selectedProviders);
          setDateRange(localDateRange);
          onClose();
        }}
      >
        Apply
      </PrimaryButton>
    </ModalBox>
  );
};

export default FilterWaitlistModal;
