import { MouseEvent, useEffect, useRef, useState } from "react";
import styles from "./DatePicker.module.scss";
import Calendar, { CalendarProps } from "../calendar/Calendar";
import calendarIcon from "../calendar/assets/calendar.svg";
import { DateTimeObject } from "../calendar/utils/types";
import { DateTime } from "luxon";
import { AvailableDate } from "../../types";
import { CalendarProvider } from "../calendar/utils/hooks/useCalendarContext";

interface DatePickerProps extends CalendarProps {
  format?: string;
  responsive?: boolean;
  disabled?: boolean;
  className?: string;
  iconAtLeft?: boolean;
  /**
   * Defaults to left
   */
  dropdownAlignment?: "left" | "right";
  placeholder?: string;
  content?: React.ReactNode;
  dropdownTop?: boolean;
  sx?: React.CSSProperties;
}

const DatePicker = (props: DatePickerProps) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const {
    dateTimes,
    onDateTimesChange: onChange,
    responsive,
    disabled,
    className,
    iconAtLeft,
    placeholder,
    dropdownAlignment = "left",
    content,
    dropdownTop,
    sx,
    ...calendarProps
  } = props;

  const dropDownRef = useRef<HTMLDivElement>(null);
  const selectRef = useRef<HTMLDivElement>(null);

  const handleClose = (e: globalThis.MouseEvent) => {
    const dropdown = dropDownRef.current;

    if (!dropdown || !dropdown.contains(e.target as Element)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClose);
    return () => window.removeEventListener("click", handleClose);
  }, []);

  const handleSelectClick = (e: MouseEvent<HTMLDivElement>) => {
    if (disabled) {
      return;
    }

    const select = selectRef.current;
    if (select && select.contains(e.target as Element)) {
      setShowDropdown(!showDropdown);
    }
  };

  const handleDateSelect = (dateTimes: DateTimeObject[]) => {
    onChange?.(dateTimes);
    if (dateTimes.length) {
      setShowDropdown(false);
    }
  };

  const shownValue = dateTimes.length ? `${dateTimes.length} days selected` : "";

  return (
    <div
      className={[styles["select-wrapper"], showDropdown ? styles.active : "", responsive && styles.responsive, className].join(" ")}
      ref={dropDownRef}
      onClick={handleSelectClick}
      style={sx}
    >
      <div className={[styles["main-content"], iconAtLeft && styles["icon-at-left"]].join(" ")} ref={selectRef}>
        {content ? (
          <div>{content}</div>
        ) : (
          <>
            {iconAtLeft && <img alt="calendar" className={styles.icon} src={calendarIcon} />}
            {shownValue ? (
              <span className={styles["main-text"]}>{shownValue}</span>
            ) : (
              <span className={styles.placeholder}>{placeholder || "Select date"}</span>
            )}
            {!iconAtLeft && <img alt="calendar" className={styles.icon} src={calendarIcon} />}
          </>
        )}
      </div>
      <div
        className={[styles.dropdown, showDropdown && styles["show-dropdown"], styles[dropdownAlignment], dropdownTop && styles["on-top"]].join(" ")}
      >
        <CalendarProvider>
          <Calendar dateTimes={dateTimes} onDateTimesChange={handleDateSelect} onCancel={() => setShowDropdown(false)} {...calendarProps} />
        </CalendarProvider>
      </div>
    </div>
  );
};

interface CustomDatePickerProps extends Omit<DatePickerProps, "dateTimes" | "onDateTimesChange"> {
  dates: AvailableDate[];
  onDatesChange: (dates: AvailableDate[]) => void;
  timezone?: string;
}

const CustomDatePicker = (props: CustomDatePickerProps) => {
  const { dates, onDatesChange, timezone, ...datePickerProps } = props;

  const timezoneObject = {
    name: Intl.DateTimeFormat().resolvedOptions().timeZone,
    offset: new Date().getTimezoneOffset(),
  };

  const dateTimes = dates.map((date) => {
    if (typeof date === "string") {
      const dateStr = DateTime.fromISO(date).toFormat("EEE MMM dd yyyy");
      return { date: dateStr, time: { start: "00:00", end: "00:00" }, timezone: timezoneObject };
    }

    const dateStr = DateTime.fromISO(date.start).toFormat("EEE MMM dd yyyy");

    return {
      date: dateStr,
      time: { start: DateTime.fromISO(date.start).toFormat("HH:mm"), end: DateTime.fromISO(date.end).toFormat("HH:mm") },
      timezone: timezoneObject,
    };
  });

  const handleDateTimesChange = (dateTimes: DateTimeObject[]) => {
    onDatesChange(
      dateTimes.map((dateTime) => {
        const { date, time } = dateTime;
        if (time.start === "00:00" && time.end === "00:00") {
          return DateTime.fromFormat(date, "EEE MMM dd yyyy", { zone: timezone }).toISO() || "";
        }

        const start = DateTime.fromFormat(`${date} ${time.start}`, "EEE MMM dd yyyy HH:mm", { zone: timezone }).toISO() || "";
        const end = DateTime.fromFormat(`${date} ${time.end}`, "EEE MMM dd yyyy HH:mm", { zone: timezone }).toISO() || "";
        return { start, end };
      })
    );
  };

  return <DatePicker dateTimes={dateTimes} onDateTimesChange={handleDateTimesChange} {...datePickerProps} />;
};

export default CustomDatePicker;
