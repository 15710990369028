// slices/sessionSlice.tsx

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SessionState {
  sessionExpired: boolean;
  timeoutId: number | null;
  error: string | null;
}

const initialState: SessionState = {
  sessionExpired: false,
  timeoutId: null,
  error: null,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    sessionExpired: (state, action: PayloadAction<boolean>) => {
      state.sessionExpired = action.payload;
    },
    setSessionError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
  extraReducers: () => {
    // other thunks
  },
});

export const { sessionExpired, setSessionError } = sessionSlice.actions;

export default sessionSlice.reducer;
